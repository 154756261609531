@import '../../../../../../../variables.scss';
@import '../../../../../../../mixins.scss';

.Profile-Nav {
  background-color: #f3f6f8;
  display: table;
  margin: 25px auto;
  padding: 25px 15px;
  border-radius: 8px;

  @include respond(phone) {
    display: flex;
    justify-content: space-between;
    width: 80%;
    padding: 20px;
    margin: auto;
    margin-top: 30px;
  }

  a {
    text-decoration: none;
    font-family: 'Open Sans';
    font-size: 20px;
    line-height: 24px;

    @include respond(phone) {
      font-size: 17px;
    }

    span {
      color: #adadad;
      padding: 15px 45px;
      border-radius: 4px;
      font-weight: 600;

      @include respond(phone) {
        padding: 0;
      }
    }

    .Selected {
      color: #253858;
      background-color: white;
    }
  }
}
