@import 'src/variables';
@import 'src/mixins';
.profile-page {
  max-width: 1000px;
  margin: auto;
  box-sizing: content-box;
  padding: 20px;
  display: flex;
  flex-direction: column;
  font-family: 'Open Sans', sans-serif;
  color: $grey-text-color;
  .preview {
    position: fixed;
    width: 100%;
    left: 0;
    background-color: rgba(0, 0, 0);
    z-index: 10;
    .get-premium-button {
      z-index: 10 !important;
      left: 50% !important;
      width: 300px;
      transform: translateX(-50%);

      @include respond(phone) {
        width: 60%;
      }
    }
    .arrow {
      top: 50%;
    }
    &.visible {
      z-index: 999;
    }
  }
  .interests {
    margin: 2rem auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 10px;
    .interest-item {
      display: block;
      text-align: center;
      text-transform: uppercase;
      font-size: 12px;
      font-weight: bold;
      color: black;
      img {
        width: 30px;
      }
    }
  }
  .general {
    display: flex;
    flex-direction: column-reverse;
    .about {
      margin-top: 2rem;
    }
    .images-holder {
      max-width: 400px;
      width: 100%;
      margin: auto;
      .like {
        background-color: #6666667c;
        font-size: 30px;
        position: absolute;
        top: 20px;
        right: 20px;
        z-index: 9;
        border: 2px solid $grey-text-color;
        border-radius: 8px;
        padding: 5px;
        cursor: pointer;
        box-sizing: border-box;
        transition: all 0.3s;
        &:hover {
          background-color: #7e172b;
          transform: scale(1.2);
          transition: all 0.3s;
        }
      }
      .get-premium-button {
        position: absolute;
        bottom: 10%;
        max-width: 80%;
        left: 10%;
        z-index: 9;
      }
      .buttons {
        .username {
          margin: 20px auto;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          gap: 10px;
          .content {
            display: flex;
            justify-content: space-between;
            width: 100%;
            .controls {
              display: flex;
              gap: 5px;
              .icon {
                cursor: pointer;
              }
            }
          }
          .ant-badge {
            &.ant-badge-status {
              .ant-badge-status-dot {
                width: 10px;
                height: 10px;
              }
            }
          }
        }
        display: grid;
        row-gap: 10px;
      }
      .radius {
        background: black;
        border-radius: 8px;
        overflow: hidden;
        &.main-canvas {
          background-color: black;
          width: 100%;
        }
      }
    }
    .tools {
      position: absolute;
      z-index: 10;
      cursor: pointer;
      color: white;
      font-size: 24px;
      font-weight: bold;
      &.lightbox {
        z-index: 12;
        padding: 5px;
        font-size: 30px;
        background-color: rgba(255, 255, 255, 0.5);
      }
      &.close {
        top: 5%;
      }
      &.right {
        right: 5%;
      }
      &.left {
        left: 5%;
      }
      &:hover {
        transform: scale(1.2);
        transition: all 0.3s;
      }
    }
  }
  .link {
    color: $main-color;
    font-weight: bold;
  }
  .fact-list {
    line-height: 2rem;
    width: 100%;
  }
}
@media only screen and (min-width: 769px) {
  .profile-page {
    padding-top: 5rem;
    padding-bottom: 5rem;
    flex-direction: row;
    gap: 40px;
    .general {
      flex-direction: row;
      gap: 40px;
      max-width: 66%;
      width: 100%;
      .about {
        width: 50%;
        margin-top: 0;
      }
      .images-holder {
        max-width: 300px;
      }
    }
    .fact-list {
      max-width: 33%;
    }
  }
}
