@import 'src/assets/css/colors';
.regular-text {
  font-family: 'Open Sans', serif;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
}
.subscription-card {
  border: 1px solid #dedede;
  border-radius: 23px;
  box-shadow: -1px 11px 19px 1px rgba(0, 0, 0, 0.09);
  padding: 40px 50px;
  text-align: center;
  position: relative;
  height: fit-content;
  max-width: 350px;
  .PackageTitle {
    font-family: 'Raleway', serif;
    font-weight: 600;
    font-size: 30px;
    line-height: 35px;
    text-transform: uppercase;
  }
  .num-messages {
    font-family: 'Open Sans', serif;
    font-weight: 700;
    font-size: 76px;
    line-height: 103px;
    text-transform: uppercase;
  }
  .num-messages-text {
    @extend .regular-text;
  }
  .month-price {
    font-family: 'Open Sans', serif;
    font-weight: 700;
    font-size: 22px;
    line-height: 24px;
    text-align: center;
    margin-bottom: 25px;
    span {
      color: $main;
      font-weight: 600;
    }
  }
  .frequency {
    @extend .regular-text;
    text-align: center;
    margin-bottom: 40px;
    span {
      font-size: 26px;
      font-weight: 700;
    }
  }
  &.featured {
    background-color: #000;
    padding-bottom: 90px;
    .icon-wrapper {
      color: white;
      margin-bottom: 20px;
      position: relative;
      font-family: 'Open Sans', serif;
      font-weight: 600;
      font-size: 20px;
      line-height: 32px;
      text-align: center;
      letter-spacing: 0.165em;
      .icon {
        left: 0;
        right: 0;
        position: absolute;
        top: -80px;
        svg {
          width: 80px;
          height: 80px;
        }
      }
    }
    .num-messages-text {
      color: #888;
    }
    .month-price {
      color: #888;
    }
    .frequency {
      color: white;
    }
  }
  .ant-divider-dashed {
    margin: 30px 0;
    border-color: #d3d3d3;
  }
  .Button {
    &.card-button {
      background-color: transparent;
      &:hover {
        background-color: darken($main, 10%) !important;
        border-color: darken($main, 10%) !important;
        color: white !important;
      }
    }
  }
}
