@import '../../variables';

.SubscriptionCard {
  border: 1px solid #dedede;
  border-radius: 23px;
  box-shadow: -1px 11px 19px 1px rgba(0, 0, 0, 0.09);
  padding: 40px 50px;
  text-align: center;
  position: relative;
  height: fit-content;

  .PackageTitle {
    font-size: 26px;
    text-transform: uppercase;
    margin-bottom: 10px;
  }

  .MessagesNumber {
    font-size: 74px;
    line-height: 80px;
    font-weight: 700;
  }

  .MessagesNumberText {
    font-size: 18px;
  }

  .PricePerMonth {
    font-size: 22px;
    margin-bottom: 15px;

    span {
      color: $main-color;
      font-weight: 600;
    }
  }

  .BilledPeriod {
    font-size: 18px;
    margin-bottom: 20px;

    span {
      font-size: 26px;
      font-weight: 700;
    }
  }

  &.Badged {
    background-color: #000;
    padding-bottom: 90px;

    .Badge {
      color: $white;
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 20px;
      position: relative;
      margin-top: 20px;

      .BadgeImage {
        position: absolute;
        top: -77px;
      }
    }

    .PackageTitle {
      color: $gold;
    }

    .MessagesNumber {
      color: $gold;
    }

    .MessagesNumberText {
      color: #888;
    }

    .PricePerMonth {
      color: #888;

      span {
        color: $gold;
      }
    }

    .BilledPeriod {
      color: $white;
    }

    .Button {
      background-color: $gold;
      color: Black;

      &:hover {
        background-color: black;
        color: $gold;
        border-color: $gold;
      }
    }
  }

  .ant-divider-dashed {
    border-color: #d3d3d3;
  }
}
