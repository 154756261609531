@import '../../../../../../../../variables.scss';
@import '../../../../../../../../mixins.scss';

.LanderContainer17 {
  .InputContainer {
    position: relative;
    margin-bottom: 12px;
    width: 100%;

    input {
      padding: 16px 20px;
      width: 100%;
      border-radius: 4px;
      height: auto;
      text-align: left;
      background: transparent;
      border: 1px solid #fff;
      font-size: 20px;
      color: #fff;

      &::placeholder {
        color: #ffffff50;
        font-size: 20px;
      }

      &:hover {
        border-color: #fff !important;
      }

      &:focus {
        border-color: #fff !important;
        box-shadow: 0 0 0 2px rgba(196, 196, 196, 0.2) !important;
      }

      @include respond(phone) {
        padding: 13px 13px;
      }
    }
  }
}
