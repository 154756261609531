.ThankYouContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .ThankYouAvatar {
    margin-bottom: 20px;
    width: 100px;
    height: 100px;
    border: 5px solid #a32b73;
    border-radius: 20px;
    box-shadow: 0px 4px 5.699999809265137px 2px #26051f40;
  }

  &.Version15-1 {
    .ThankYouAvatar {
      border: 5px solid #a32b73;
    }
  }

  &.Version15-2 {
    .ThankYouAvatar {
      border: 5px solid #1c7952;
    }

    .ThankYouLoader {
      &.is-typing-active {
        .typing__bullet {
          animation: bounceMoreV2 1200ms linear infinite both; /* Updated duration */
        }
      }
    }
  }

  &.Version15-3 {
    .ThankYouAvatar {
      border: 5px solid #3b7299;
    }

    .ThankYouLoader {
      &.is-typing-active {
        .typing__bullet {
          animation: bounceMoreV3 1200ms linear infinite both; /* Updated duration */
        }
      }
    }
  }

  &.Version15-4 {
    .ThankYouAvatar {
      border: 5px solid #d87f4d;
    }
    .ThankYouLoader {
      &.is-typing-active {
        .typing__bullet {
          animation: bounceMoreV4 1200ms linear infinite both; /* Updated duration */
        }
      }
    }
  }

  .ThankYouText {
    margin-bottom: 60px;
    color: #fff;
    font-size: 18px;
    font-weight: 600;
  }

  .ThankYouLoader {
    font-size: 20px;
    .typing__bullet {
      width: 20px;
      height: 20px;
      margin: 5px;
    }
    &.is-typing-active {
      .typing__bullet {
        background-color: #fff;
        animation: bounceMore 1200ms linear infinite both; /* Updated duration */

        &:nth-child(2) {
          animation-delay: 300ms; /* Updated delay */
        }

        &:nth-child(3) {
          animation-delay: 600ms; /* Updated delay */
        }
      }
    }
  }
}

@keyframes bounceMore {
  0%,
  80%,
  100% {
    opacity: 1;
    transform: translateY(0);
  }
  50% {
    opacity: 0.5;
    background-color: #f86bbf;
    transform: translateY(-120%);
  }
}

@keyframes bounceMoreV2 {
  0%,
  80%,
  100% {
    opacity: 1;
    transform: translateY(0);
  }
  50% {
    opacity: 0.5;
    background-color: #00b127;
    transform: translateY(-120%);
  }
}

@keyframes bounceMoreV3 {
  0%,
  80%,
  100% {
    opacity: 1;
    transform: translateY(0);
  }
  50% {
    opacity: 0.5;
    background-color: #018fde;
    transform: translateY(-120%);
  }
}

@keyframes bounceMoreV4 {
  0%,
  80%,
  100% {
    opacity: 1;
    transform: translateY(0);
  }
  50% {
    opacity: 0.5;
    background-color: #ff551f;
    transform: translateY(-120%);
  }
}
