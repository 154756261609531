@import '../../../variables';
@import '../../../mixins.scss';

.PaymentConfirm {
  background-color: #fffef9;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 50px;
  .PaymentConfirmSign {
    width: 77px;
    height: 77px;
    margin-bottom: 27px;
  }
  .PaymentConfirmClose {
    width: 32px;
    height: 32px;
    position: absolute;
    top: 93px;
  }
  .PaymentConfirmText {
    @include modalTitle;
  }
  .PaymentConfirmNote {
    @include modaSubtitle;
  }
}
.PaymetnConfirmWrapper {
  background-color: #fffef9 !important;
}
