@import '../../../../../variables';
@import '../../../../../mixins';

.Page-AdZone {
  display: flex;
  justify-content: center;
  align-items: center;

  &--Header {
    margin-top: 1rem;

    @include respond(tablet) {
      margin-top: 0.5rem;
    }

    @include respond(desktop) {
      margin-top: 1rem;
    }
  }
  &--Footer {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}

.MembersArea {
  height: 100%;
  display: flex;
  align-items: center;
  //font-size: 50px;
  flex-direction: column;
  color: #253858;
  width: 90%;
  margin: auto;

  @include respond(phone) {
    width: 90%;
  }

  .MembersHome {
    width: 80%;

    @include respond(phone) {
      width: 100%;
    }

    @include respond(tablet) {
      width: 100%;
      margin: auto;
    }
    .WelcomeHeader {
      text-align: center;
      margin: 50px 0;
      font-family: 'Raleway', serif;
      font-weight: 700;
      font-size: 30px;
      line-height: 40px;

      color: $credits-dark-text;

      @include respond(phone) {
        font-weight: bold;
        font-size: 24px;
        line-height: 120%;
      }

      @include respond(tablet) {
        font-style: normal;
        font-weight: bold;
        line-height: 35px;
      }
    }
    .Search-Parameters {
      padding: 30px 50px 50px 50px;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      background-color: white;

      @include respond(phone) {
        .profile-filter {
          max-width: 100% !important;
          width: 100%;
        }
        padding: 30px;
        margin: auto;
        gap: 0;
      }

      @include respond(tablet) {
        .profile-filter {
          max-width: 100% !important;
          width: 100%;
        }
        margin: auto;
        gap: 0;
      }

      .Filters {
        display: flex;
        flex-direction: row;
        justify-content: left;
        align-items: flex-start;
        grid-gap: 50px;
        .profile-filter {
          max-width: 30%;
          flex-grow: 1;
          .ant-slider-track {
            background-color: $main-color;
          }
          .ant-slider-handle {
            border-color: $main-color;
          }
          .ant-select-selector {
            border-radius: 8px;
            height: 46px;
            .ant-select-selection-item,
            .ant-select-selection-placeholder {
              font-size: 16px;
              line-height: 26px;
              padding: 10px 0;
            }
          }
        }
        @include respond(phone) {
          display: flex;
          flex-direction: column;
          gap: 0;
        }

        @include respond(tablet) {
          display: flex;
          flex-direction: column;
          gap: 0;
        }

        .Age-Range {
          position: relative;
          display: flex;
          height: 100px;

          .values {
            width: 100%;
            position: relative;
            margin: 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
            div {
              display: flex;
              span {
                margin: 3px;
              }
            }
          }

          .values:before {
            content: '';
            position: absolute;
          }

          .Age-Range-Header {
            font-family: 'Roboto', serif;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
          }

          .Range1,
          .Range2,
          .Minus {
            color: $main-color;
            font-family: Roboto, serif;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
          }

          .Age-Container {
            position: relative;
            width: 100%;
            margin: auto;

            .Slider-Track {
              width: 100%;
              height: 5px;
              background-color: #ececec;
              position: absolute;
              margin: auto;
              top: 0;
              bottom: 0;
              border-radius: 5px;
            }

            input[type='range'] {
              appearance: none;
              width: 100%;
              outline: none;
              position: absolute;
              top: 0;
              bottom: 0;
              background-color: transparent;
              pointer-events: none;
            }

            input[type='range']::-webkit-slider-thumb {
              -webkit-appearance: none;
              height: 1.7em;
              width: 1.7em;
              background-color: white;
              border: 6px solid $main-color;
              cursor: pointer;
              border-radius: 50%;
              pointer-events: auto;
            }
            input[type='range']:active::webkit-slider-thumb {
              background-color: white;
              border: 3px solid $main-color;
            }
          }
        }

        .age-range-filter {
          display: flex;
          gap: 5px;
        }

        .Age-Range,
        .Hair-Color,
        .Body-Type {
          display: flex;
          flex-direction: column;
          min-width: calc(100% / 3.5);
          gap: 5px;

          @include respond(phone) {
            width: 100%;
          }

          @include respond(tablet) {
            width: 100%;
          }

          select {
            text-transform: capitalize;
          }
          .To-Uppercase {
            text-transform: capitalize;
          }
        }

        .Hair-Color,
        .Body-Type {
          height: 100px;
          justify-content: center;

          @include respond(phone) {
            height: 100%;
          }

          select {
            background-color: transparent;
          }
        }
        label {
          font-family: 'Raleway', serif;
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          color: $credits-dark-text;
          display: block;
        }
        select {
          padding: 10px;
          color: #5e6c84;
          border: 1px solid #d6d6d6;
          border-radius: 5px;
          font-family: 'Open Sans', sans-serif;
          font-size: 16px;
          line-height: 24px;
          outline: none;
        }
      }

      .No-Option {
        font-family: 'Open Sans', sans-serif;
        font-size: 16px;
        line-height: 24px;
        color: $pricing-info-text;
      }

      .User-Info {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        grid-gap: 50px;

        @include respond(phone) {
          display: flex;
          flex-direction: column;
        }

        @include respond(tablet) {
          display: flex;
          flex-direction: column;
          gap: 20px;
        }

        .My-Location,
        .My-Gender,
        .Button-Wrapper {
          display: flex;
          flex-direction: column;
          min-width: calc(100% / 3.5);
          height: 100px;

          @include respond(phone) {
            width: 100%;
            max-height: 100px;
          }

          @include respond(tablet) {
            width: 100%;
            height: 50px;
          }
        }

        .Button-Wrapper {
          display: flex;
          justify-content: flex-end;

          @include respond(phone) {
            margin-top: 20px;
          }

          @include respond(tablet) {
            margin-top: 35px;
          }

          .Button {
            width: 100%;
            padding: 0;
            height: 48px;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }

        .My-Location,
        .My-Gender {
          height: 100px;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          gap: 5px;

          @include respond(phone) {
            height: 100%;
          }
        }
      }
    }

    .Location-Header,
    .Gender-Header {
      font-family: 'Raleway', serif;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: $credits-dark-text;
      margin: 0;
      padding: 0;
    }

    .Gender-Input,
    .Location-Input {
      font-family: 'Open Sans', serif;
      padding: 10px;
      font-size: 16px;
      line-height: 24px;
      color: $pricing-info-text;
      border-radius: 5px;
      border: 1px solid #d6d6d6;
    }

    .ModalDialog {
      .ModalContainer {
        height: auto;
        padding: 50px;
        width: 600px;
      }
    }

    .WhereDoYouLive-Modal {
      border-radius: 0 8px 8px 0;
      padding: 50px;
    }
  }

  .MembersHome:has(.BrowseProfiles-AdZone) {
    .WelcomeHeader {
      margin-top: 1.5rem;
      margin-bottom: -1.5rem;

      @include respond(tablet) {
        margin-bottom: 0.5rem;
      }

      @include respond(desktop) {
        margin-bottom: 1rem;
      }
    }
    
    .Pages {
      margin-top: -2rem;
    }
  }

  .BrowseProfiles-Wrapper {
    width: 100%;

    .BrowseProfiles-Header {
      text-align: center;
      margin: 50px;
      line-height: 24px;
      font-family: 'Raleway', serif;
      font-weight: 700;
      font-size: 30px;
      color: $credits-dark-text;
    }
  }

  .filter-btn {
    width: fit-content;
    padding: 10px 45px;
    word-spacing: 4px;
    margin: 50px auto 20px auto;
  }
}
