@import '../../../../../../variables';
@import '../../../../../../mixins';

.HomePageProfiles {
  background: #fff;
  background-image: url('../../../../../../assets/images/LandingPage/bg-silk.jpg');
  margin-top: 70px;
  background-size: cover;

  @include respond(phone) {
    padding: 0 30px;
    background-image: linear-gradient(0deg, rgba(240, 38, 38, 0.3) 50%, rgba(240, 38, 38, 0.3) 50%),
      url('../../../../../../assets/images/LandingPage/silk 1.png');
    margin-top: 0;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .HomePageProfiles-Container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 60px 0;

    @include respond(phone) {
      padding: 50px 0 30px 0;
    }

    .Button {
      // width: 180px;
      // height: 16px;

      @include respond(phone) {
        margin-bottom: 30px;
      }
    }

    .LandingPageButton {
      width: 250px;
      p {
        color: #fff;
        font-weight: 700;
      }
    }
  }

  .Subtitle {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color: #101010;
    @include respond(phone) {
      color: #eb3a2b;
    }
  }

  .MainTitle {
    font-family: Raleway;
    font-weight: 700;
    font-size: 40px;
    line-height: 54px;
    text-align: center;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: $text-red;
    margin-bottom: 50px;

    @include respond(phone) {
      font-size: 34px;
      line-height: 40px;
      text-align: center;
      color: white;
      margin-bottom: 55px;
    }
  }

  .Profiles-Container {
    position: relative;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 30px;
    max-width: 1200px;
    width: 100%;
    margin-bottom: 35px;

    @include respond(phone) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 30px;
    }

    @include respond(tablet) {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 30px;
      max-width: 90%;
    }

    .Profile-Container {
      position: relative;
      border: 2px solid $white;
      border-radius: 8px;
      height: 195px;
      overflow: hidden;

      .ProfileInfo-Container {
        overflow: hidden;
        box-sizing: border-box;
        border-radius: 8px;
        height: 100%;

        &:hover {
          cursor: pointer;
        }

        img {
          object-fit: cover;
          height: 100%;
          width: 100%;
        }

        .ProfileInfo {
          position: absolute;
          z-index: 2;
          color: $white;
          bottom: 16px;
          left: 16px;

          div:first-of-type {
            font-family: 'Gothic A1', sans-serif;
            font-size: 14px;
            font-weight: bold;
          }

          div:last-of-type {
            margin-top: 5px;
            font-size: 14px;
            line-height: 18px;
            color: $small-text-color;
            font-weight: 700;
          }
        }

        .Overlay {
          position: absolute;
          z-index: 1;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 40%;
          border-radius: 8px;
          background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 0.6727065826330532) 0%,
            rgba(255, 255, 255, 0) 100%
          );
        }
      }
    }
    .UsernameTextBox-Container {
      position: absolute;
      top: -40px;
      left: 0;
      width: 100%;
      display: flex;
      align-items: baseline;
      justify-content: center;

      .UsernameTextBox {
        position: absolute;
        background: #ffffffd1;
        padding: 5px 15px;
        border-radius: 20px;
        z-index: 99;
        border: 1px solid $main-color;
      }
    }
  }
}
