@import '../../../../../../../../variables.scss';
@import '../../../../../../../../mixins.scss';

.InputContainer {
  position: relative;
  margin-bottom: 12px;
  width: 100%;

  .ant-tag {
    margin-top: 4px;
    width: 100%;
    text-align: center;
    background-color: $text-red;
    color: $white;
    border-radius: 5px;
  }

  .ant-select {
    width: 100%;

    .ant-select-selector {
      padding: 5px 10px;
      width: 100%;
      border-radius: 9px;
      height: auto;
      text-align: left;
    }
  }

  input {
    padding: 13px;
    width: 100%;
    border-radius: 9px;
    height: auto;
    text-align: left;

    &:hover {
      border-color: #a9a9a9 !important;
    }

    &:focus {
      border-color: #a9a9a9 !important;
      box-shadow: 0 0 0 2px rgba(196, 196, 196, 0.2) !important;
    }

    @include respond(phone) {
      padding: 8px 10px;
    }
  }
}
