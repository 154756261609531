@import '../../variables';
@import '../../mixins.scss';

.Button {
  font-size: 16px;
  line-height: 20px;
  color: $white;
  background: $button-color;
  text-transform: uppercase;
  border-radius: 8px;
  padding: 20px 35px;
  cursor: pointer;
  font-family: 'Raleway', sans-serif;
  font-weight: bold;
  font-style: normal;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  .button-icon {
    margin-right: 1rem;
  }

  @include respond(phone) {
    font-size: 12px;
  }

  p {
    padding: 0;
    margin: 0;
  }

  .iconSrc {
    margin-right: 14px;

    @include respond(phone) {
      margin-right: 6px;
    }
  }
}
