@import '../../../../mixins.scss';
@import '../../../../variables.scss';

.SignUpAff8 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: url('../../../../assets/images/FunnelPage/lander8bg.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  @include respond(phone) {
    form {
      flex: 0 0 100%;
    }
  }

  .StepsModalContainer {
    background-color: #0a0202eb;
    color: #fff;
    width: 636px;
    position: relative;

    @include respond(phone) {
      width: 90%;
      margin: auto;
    }

    .LoaderContainer {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #000000b0;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .StepsModalHeader {
      background-color: #1e1c1c;
      padding: 10px;
      text-align: center;
      font-size: 26px;

      .MainHeader {
        span {
          color: red;
        }
      }
      .QuestionHeader {
        display: flex;
        justify-content: space-around;
        width: 70%;
        margin: auto;
        font-size: 20px;

        .QuestionNumber {
          color: #535353;
          width: 30px;
          height: 30px;

          &.Active {
            background-color: #d41411;
            color: #fff;
            border-radius: 100px;
          }
        }
      }
    }

    .StepsModalBody {
      padding: 40px;
    }

    .InputInfo {
      color: #dc9493;
    }

    .StepsModalImage {
      text-align: center;

      .AnimatedImage {
        width: 270px;
      }
    }

    .InputField {
      color: #000;

      .Error-Container {
        color: #fff;
        text-align: center;
        margin-top: 5px;
        border-radius: 6px;
        padding: 2px;
        font-size: 12px;

        &.Active {
          background-color: #dc9493;
        }
      }
    }

    .StepsModalText {
      // text-align: center;
      margin-bottom: 20px;

      .WarningText {
        color: #d41411;
        font-size: 30px;
        font-weight: 700;
        text-align: center;
      }

      .StandardText {
        color: #fff;
        font-size: 18px;

        &.Centered {
          text-align: center;
        }
      }

      .SecondaryText {
        color: #dc9493;
        font-size: 24px;
        text-align: center;
      }
    }

    .ButtonsContainer {
      display: flex;
      justify-content: space-around;
      margin-top: 10px;

      @include respond(phone) {
        flex-direction: column;
      }

      .GoogleButton {
        background-color: #507fe5 !important;
        color: #fff !important;
        width: 43%;
        margin: auto;
        border-radius: 8px !important;
        font-size: 16px !important;
        height: 50px;

        @include respond(phone) {
          font-size: 16px;
          width: 100%;
          margin-bottom: 10px;
        }

        div {
          background-color: #507fe5 !important;
          height: 40px;

          svg {
            width: 25px !important;
            height: 25px !important;
          }
        }
      }

      .Button {
        height: 50px;
        font-size: 20px;
        width: 43%;
        min-width: fit-content;
        margin: auto;
        padding: 20px;

        @include respond(phone) {
          font-size: 16px;
          width: 100%;
          margin-bottom: 10px;
        }

        &.DisabledButton {
          pointer-events: none;
          background: #a25665;
        }

        &.Second {
          background-color: #393939;
        }
      }
    }
  }
}
