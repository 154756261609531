@import '../../../../../../../variables.scss';
@import '../../../../../../../mixins.scss';

* {
  box-sizing: border-box;
}

.PicturesWrapper {
  display: flex;
  width: 100%;
  margin: 59px auto;
  justify-content: center;
  margin-bottom: 280px;
  @include respond(phone) {
    flex-direction: column;
    margin: 50px auto 78px auto;
  }
  .ProfilePhoto {
    margin: 0 30px 0 120px;
    width: calc(100% / 3 * 2);
    @include respond(phone) {
      margin: 0 14px 0 17px;
      width: auto;
    }
  }
  .ProfilePhotoText {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #253858;
    margin-bottom: 33px;
    font-family: 'Open Sans';
    margin-top: 0;
  }
  .Profiles-Loader {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 100%;
  }
  .DragAndDropWrapper {
    background-color: #f3f6f8;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 8px;
    padding-top: 80px;
  }
  .drag-drop-zone {
    padding: 100px 0;
  }
  .ProfileImg {
    margin-top: 80px;
  }
  .AdditionalPhotosWrapper {
    margin-right: 120px;
    width: calc(100% / 3);
    display: flex;
    flex-direction: column;
    @include respond(phone) {
      width: auto;
      margin: 0 14px 0 17px;
    }
    .Tip {
      width: 100%;

      .image-box {
        width: 100%;
        height: auto;
        border-radius: 8px 8px 0px 0px;
        overflow: hidden;

        @include respond(phone) {
          margin-top: 40px;
        }

        img {
          width: 100%;
        }
      }
    }
    .TipText {
      border: 2px dotted #d6d6d6;
      border-top: none;
      border-radius: 0 0 8px 8px;
      margin: 0;
      padding: 20px;
      color: #253858;
      font-family: 'Open Sans', sans-serif;
      line-height: 24px;
      font-weight: 400;
      font-size: 16px;
    }
    .AdditionalPhotosText {
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      color: #253858;
      margin-bottom: 26px;
      margin-top: 45px;
      font-family: 'Open Sans';

      @include respond(phone) {
        margin-top: 34px;
      }
    }
    .AdditionalPhotosContainer {
      padding: 75px 70px 32px;
      border: 2px dashed #d6d6d6;
      box-sizing: border-box;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      @include respond(phone) {
        padding: 75px 60px 32px;
      }
      .NoPhotoText {
        font-size: 16px;
        line-height: 24px;
        color: #253858;
        margin-bottom: 49px;
        margin-top: 0;
      }
    }
    .GalleryPhotoContainerOuter {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .GalleryPhotoContainer {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      row-gap: 5px;
      column-gap: 5px;
      padding-top: 20px;
      .GalleryPhoto {
        width: 115px;
        height: 130px;
        border-radius: 8px;
        @include respond(phone) {
          width: 110px;
          height: 120px;
        }
      }
    }
  }
  .LoaderComponentWrapper {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
}

.tip-text {
  margin-bottom: 45px;
}
.additional-photos {
  padding: 0;
  background-color: white;
}

.additional-text {
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  margin: 0;
  margin-bottom: 49px;
}

.change-photo {
  padding: 50px 0;
}

.btn-change-photo {
  padding: 16px 20px 16px 20px;
}

.ImageContainer {
  height: 400px;

  .ant-image {
    height: 100%;

    .ant-image-img {
      height: 100%;
    }
  }
}
