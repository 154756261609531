@import '../../variables.scss';

.Unsubscribe {
  padding: 10px;

  .Loading {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background: #0000006e;
    top: 0;
    left: 0;
    z-index: 999;
    border-radius: 8px;
  }

  .ResetPassword-Header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    h3 {
      color: $pass-reset-header-text;
      font-size: 40px;
      font-family: 'Playfair Display';
      font-weight: 600;
      margin-bottom: 0;
    }
    p {
      font-family: 'Gothic A1';
      font-size: 16px;
      color: $pass-reset-description-text;
      margin-bottom: 25px;
    }
  }
  .ResetPassword-Form {
    .Input {
      font-family: 'Gothic A1';
      font-size: 16px;
      padding: 25px;

      &::placeholder {
        color: $placeholder-text;
        font-size: 16px;
      }
    }
    .Button {
      margin-top: 20px;
    }
  }
}
