@import '../../../../../../../variables.scss';
@import '../../../../../../../mixins';

.ProfileInfoContainer {
  flex: 1;
  background-color: white;
  overflow: hidden;
  width: 100%;

  &.MobileClosed {
    @media screen and (max-width: 500px) {
      display: none;
    }
  }

  @include respond(phone) {
    overflow: auto;
  }

  @include respond(tablet) {
    overflow: auto;
  }

  @media screen and (max-width: 1024px) {
    width: 170px;
    height: calc(100vh - 80px);
  }

  @media screen and (max-width: 500px) {
    // display: none;
  }

  @media screen and (min-width: 500px) and (max-width: 900px) {
    width: 170px;
    height: calc(100vh - 50px);
  }

  .ProfileHeader {
    display: flex;
    align-items: center;
    background-color: #f8f6f5;
    padding: 15px 20px;

    .BackArrowContainer {
      margin-right: 10px;
    }

    .UserPhotoContainer {
      .ImageComponent {
        width: 40px;
        height: 40px;
        margin-right: 15px;
        border-radius: 100%;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    .UserNameContainer {
      flex: 2;

      .Username {
        color: #253858;
        font-size: 18px;
        font-weight: 500;
      }

      .UserStatusPreview {
        color: #5e6c84;
        font-size: 13px;
        position: relative;

        .OnlineStatusContainer {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          flex-direction: row;

          .OnlineStatus {
            display: block;
            width: 6px;
            height: 6px;
            border-radius: 100%;
            background-color: #6ac85b;
            margin-right: 10px;
          }
        }

        .OfflineStatusContainer {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          flex-direction: row;

          .OfflineStatus {
            display: block;
            width: 6px;
            height: 6px;
            border-radius: 100%;
            background-color: #5e6c84;
            margin-right: 10px;
          }
        }
      }
    }
  }

  .ProfileInfo {
    background-color: #fff;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    padding: 20px;
    height: calc(100% - 40px);

    @include respond(phone) {
      height: auto;
    }

    @include respond(tablet) {
      height: auto;
    }

    .box-div {
      max-height: 300px;
      overflow: hidden;
    }

    .ProfileImageContainer-Amber {
      position: relative;
      .Amber-Img {
        img {
          width: 100%;
          // height: 100%;
        }
      }

      .UserInfo {
        position: absolute;
        bottom: 20px;
        left: 25px;
        color: #fff;
        z-index: 2;
        font-size: 18px;
        font-weight: 700;

        @media screen and (max-width: 1750px) {
          font-size: 20px;
          left: 10px;
          bottom: 15px;
        }

        @media screen and (max-width: 1620px) {
          font-size: 17px;
          left: 10px;
          bottom: 10px;
        }
        @media screen and (max-width: 1515px) {
          font-size: 15px;
          left: 7px;
          bottom: 7px;
        }
      }

      .GradientBackground {
        position: absolute;
        height: 50%;
        width: 100%;
        bottom: 0;
        background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 0.6727065826330532) 0%,
          rgba(255, 255, 255, 0) 100%
        );
        z-index: 1;
      }
    }

    .ProfileImageContainer {
      position: relative;

      // max-height: 280px;
      // min-height: 280px;
      // width: 100%;

      &:hover {
        cursor: pointer;
      }

      height: 250px;

      @media screen and (min-width: 1700px) {
        height: 350px;
      }

      @media screen and (min-width: 1601px) and (max-width: 1699px) {
        height: 300px;
      }
      @media screen and (min-width: 1501px) and (max-width: 1600px) {
        height: 290px;
      }

      @media screen and (min-width: 1451px) and (max-width: 1500px) {
        height: 270px;
      }

      @media screen and (min-width: 1350px) and (max-width: 1450px) {
        height: 250px;
      }

      @media screen and (min-width: 1150px) and (max-width: 1349px) {
        height: 210px;
      }
      @media screen and (min-width: 1025px) and (max-width: 1149px) {
        height: 170px;
      }

      @include respond(phone) {
        width: auto;
        max-width: 332px;
        height: 377px;
        margin: auto;
      }

      @include respond(tablet) {
        width: 400px;
        height: 450px;
        margin: auto;
      }

      @media screen and (min-width: 901px) and (max-width: 1024px) {
        width: 100%;
        height: auto;
      }

      @media screen and (min-width: 500px) and (max-width: 900px) {
        width: 100%;
        height: auto;
      }

      .UserInfo {
        position: absolute;
        bottom: 20px;
        left: 25px;
        color: #fff;
        z-index: 2;
        font-size: 18px;
        font-weight: 700;

        @media screen and (max-width: 1750px) {
          font-size: 20px;
          left: 10px;
          bottom: 15px;
        }

        @media screen and (max-width: 1620px) {
          font-size: 17px;
          left: 10px;
          bottom: 10px;
        }
        @media screen and (max-width: 1515px) {
          font-size: 15px;
          left: 7px;
          bottom: 7px;
        }

        @media screen and (min-width: 901px) and (max-width: 1024px) {
          font-size: 10px;
        }

        @media screen and (min-width: 500px) and (max-width: 900px) {
          font-size: 8px;
        }
      }

      .ImageComponent {
        width: 100%;
        height: 100%;

        .eye-shape {
          height: auto;
        }

        .SkeletonContainer {
          min-height: 100%;
        }
      }
      .profile-img {
        width: 100%;
        height: 100%;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .ViewProfileIconContainer {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #797979c7;
        right: 15px;
        top: 15px;
        border-radius: 8px;
        width: 38px;
        height: 38px;
        z-index: 2;

        @media screen and (min-width: 1025px) and (max-width: 1200px) {
          width: 38px;
          height: 38px;
          right: 5px;
          top: 5px;
        }

        @media screen and (min-width: 1200px) and (max-width: 1350px) {
          width: 38px;
          height: 38px;
          right: 5px;
          top: 5px;
        }

        @media screen and (min-width: 1350px) and (max-width: 1500px) {
          width: 38px;
          height: 38px;
          right: 15px;
          top: 15px;
        }

        @media screen and (min-width: 901px) and (max-width: 1024px) {
          width: 30px;
          height: 30px;
          right: 5px;
          top: 5px;
        }

        @media screen and (min-width: 500px) and (max-width: 900px) {
          width: 20px;
          height: 20px;
          right: 5px;
          top: 5px;
        }
        .ImageComponent {
          position: absolute;
          width: auto;

          img {
            @media screen and (min-width: 901px) and (max-width: 1024px) {
              width: 80%;
            }

            @media screen and (min-width: 500px) and (max-width: 900px) {
              width: 80%;
            }
          }
        }

        &:hover {
          cursor: pointer;
        }
      }
    }
    .GradientBackground {
      position: absolute;
      height: 50%;
      width: 100%;
      bottom: 0;
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.6727065826330532) 0%,
        rgba(255, 255, 255, 0) 100%
      );
      z-index: 1;
    }
    .ProfileDescriptionContainer {
      color: #253858;
      font-size: 16px;
      margin-top: 30px;
      height: 100%;
      overflow: auto;
      padding-bottom: 30px;
      font-family: 'Roboto';

      @media screen and (min-width: 2201px) and (max-width: 2499px) {
        height: calc(100% - 420px);
      }

      @media screen and (min-width: 1900px) and (max-width: 2200px) {
        height: calc(100% - 370px);
      }

      @media screen and (min-width: 1700px) and (max-width: 1900px) {
        height: calc(100% - 30px);
      }

      @media screen and (min-width: 1601px) and (max-width: 1699px) {
        height: calc(100% - 255px);
      }
      @media screen and (min-width: 1501px) and (max-width: 1600px) {
        height: calc(100% - 210px);
      }

      @media screen and (min-width: 1451px) and (max-width: 1500px) {
        height: calc(100% - 190px);
      }

      @media screen and (min-width: 1350px) and (max-width: 1450px) {
        height: calc(100% - 170px);
      }

      @media screen and (min-width: 1150px) and (max-width: 1349px) {
        height: calc(100% - 155px);
      }
      @media screen and (min-width: 1025px) and (max-width: 1149px) {
        height: calc(100% - 120px);
      }

      @media screen and (min-width: 901px) and (max-width: 1024px) {
        height: auto;
      }

      @media screen and (min-width: 500px) and (max-width: 900px) {
        height: auto;
      }

      @include respond(tablet) {
        height: 100%;
      }

      // @media screen and (min-width: 1300px) {
      //   padding-bottom: 80px;
      // }

      // @media screen and (max-width: 1140px) {
      //   padding-bottom: 20px;
      // }

      @media screen and (min-width: 500px) and (max-width: 900px) {
        font-size: 8px;
      }

      .DescriptionContainer {
        p {
          @media screen and (min-width: 901px) and (max-width: 1024px) {
            font-size: 12px !important;
          }

          @media screen and (min-width: 500px) and (max-width: 900px) {
            font-size: 9px !important;
          }
        }

        p > * {
          @media screen and (min-width: 901px) and (max-width: 1024px) {
            font-size: 12px !important;
          }

          @media screen and (min-width: 500px) and (max-width: 900px) {
            font-size: 9px !important;
          }
        }
      }
    }
  }
}

.ProfileImageContainer-Sidebar {
  position: relative;

  // max-height: 280px;
  // min-height: 280px;
  height: 350px;
  // width: 100%;

  &:hover {
    cursor: pointer;
  }

  @media screen and (min-width: 2201px) and (max-width: 2499px) {
    height: 400px;
  }

  @media screen and (min-width: 1900px) and (max-width: 2200px) {
    height: 350px;
  }

  @media screen and (min-width: 1700px) and (max-width: 1900px) {
    height: 280px;
  }

  @media screen and (min-width: 1601px) and (max-width: 1699px) {
    height: 235px;
  }
  @media screen and (min-width: 1501px) and (max-width: 1600px) {
    height: 190px;
  }

  @media screen and (min-width: 1451px) and (max-width: 1500px) {
    height: 170px;
  }

  @media screen and (min-width: 1350px) and (max-width: 1450px) {
    height: 150px;
  }

  @media screen and (min-width: 1150px) and (max-width: 1349px) {
    height: 135px;
  }
  @media screen and (min-width: 1025px) and (max-width: 1149px) {
    height: 100px;
  }

  @media screen and (min-width: 200px) and (max-width: 720px) {
    height: 80px;
    width: 100%;
  }

  @media screen and (min-width: 901px) and (max-width: 1024px) {
    height: auto;
  }

  @media screen and (min-width: 500px) and (max-width: 900px) {
    height: auto;
  }

  // @include respond(tablet) {
  //   width: 400px;
  //   height: 450px;
  //   margin: auto;
  // }

  .UserInfo-Sidebar {
    position: absolute;
    bottom: 10px;
    left: 15px;
    color: #fff;
    z-index: 2;
    font-size: 18px;
    font-weight: 700;

    @media screen and (max-width: 1750px) {
      font-size: 18px;
      left: 10px;
      bottom: 15px;
    }

    @media screen and (max-width: 1620px) {
      font-size: 15px;
      left: 10px;
      bottom: 10px;
    }

    @media screen and (min-width: 1451px) and (max-width: 1619px) {
      font-size: 15px;
      left: 10px;
      bottom: 5px;
    }
    @media screen and (min-width: 1025px) and (max-width: 1450px) {
      font-size: 10px;
      left: 5px;
      bottom: 0px;
    }

    @media screen and (min-width: 500px) and (max-width: 900px) {
      font-size: 8px;
      left: 7px;
      bottom: 7px;
    }
  }

  .ImageComponent {
    width: 100%;
    height: 100%;

    .eye-shape {
      height: auto;
    }

    .SkeletonContainer {
      min-height: 100%;
    }
  }
  .profile-img {
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
    }
  }
}

.ViewProfileIconContainer {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #797979c7;
  right: 15px;
  top: 15px;
  border-radius: 8px;
  width: 38px;
  height: 38px;
  z-index: 2;

  @media screen and (min-width: 1025px) and (max-width: 1200px) {
    width: 25px;
    height: 25px;
    right: 5px;
    top: 5px;
  }

  @media screen and (min-width: 1200px) and (max-width: 1350px) {
    width: 20px;
    height: 20px;
    right: 5px;
    top: 5px;
  }

  @media screen and (min-width: 1350px) and (max-width: 1500px) {
    width: 25px;
    height: 25px;
    right: 5px;
    top: 5px;
  }

  @media screen and (min-width: 901px) and (max-width: 1024px) {
    width: 20px;
    height: 20px;
    right: 5px;
    top: 5px;
  }

  @media screen and (min-width: 500px) and (max-width: 900px) {
    width: 20px;
    height: 20px;
    right: 5px;
    top: 5px;
  }

  img {
    @media screen and (min-width: 500px) and (max-width: 900px) {
      width: 80%;
    }
  }

  .ImageComponent {
    position: absolute;
    width: auto;
  }

  &:hover {
    cursor: pointer;
  }
}
