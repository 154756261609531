@import '../../../../../../variables.scss';
@import '../../../../../../mixins.scss';

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;500;600;700&family=Nunito+Sans:opsz,wght@6..12,400;6..12,500;6..12,600;6..12,700&display=swap&family=Titillium+Web:wght@400;600;700&display=swap');

.LanderContainer13 {
  display: flex;
  font-family: 'Raleway';
  justify-content: center;
  align-items: center;
  height: 100vh;
  position: relative;
  background-size: cover;

  .InputContainer .ant-input,
  .InputContainer input,
  .ButtonDark,
  .DividerText13-1,
  .Message13 {
    font-size: 18px;

    @include respond(phone) {
      font-size: 13px;
    }
  }

  .PasswordHelpText {
    @include respond(phone) {
      font-size: 13px;
    }
  }

  .ButtonDark {
    @include respond(phone) {
      padding: 20px 30px !important;
    }
  }

  .ProgressPoints {
    height: 5px;
    width: 613px;
    border-radius: 22px;
    margin-bottom: 75px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 30px;
    width: 100%;

    @include respond(phone) {
      margin-top: 5%;
      margin-bottom: 55px;
      gap: 15px;
    }

    .ProgressPoint {
      position: relative;

      svg {
        width: 45px;
        height: 45px;

        @include respond(phone) {
          width: 35px;
          height: 35px;
        }
      }

      span {
        position: absolute;
        top: 47%;
        right: 50%;
        transform: translate(50%, -50%);
        font-size: 20px;
        font-weight: 700;

        @include respond(phone) {
          font-size: 16px;
        }
      }
    }
  }

  .ButtonsContianer {
    flex-direction: column;
  }

  &.Version13-1 {
    font-family: 'Noto Sans', sans-serif;
    background-image: url('../../../../../../assets/images/Lander13/black/bg.jpeg');

    .BackgroundOverlay,
    .LoadingOverlay {
      background: rgba(39, 33, 33, 0.79);
    }

    .LanderContentInner {
      border: 1px solid #3f2626;
      background: rgba(84, 16, 29, 0.6);
      box-shadow: 0px 0px 28px 12px rgba(22, 20, 20, 0.2);
    }

    .LanderContentInnerLeft {
      background-image: url('../../../../../../assets/images/Lander13/black/side-girl.png');

      @include respond(phone) {
        background-image: url('../../../../../../assets/images/Lander13/black/side-girl-xs.png');
      }
    }

    .Message13 {
      background-color: #9f0220;
    }

    .VerticalOption13 .BackgroundOverlay13 {
      background: rgba(49, 10, 11, 0.8);
    }

    .PasswordHelpText {
      color: #c87b7b;
    }

    .AutogeneratePasswordMsg {
      color: #fff;
    }

    .UsernameInputContainer {
      svg rect {
        fill: #5f2022;
      }
    }

    .RandomUsernameContainer {
      .RandomUsernameLabel {
        span {
          color: #616161;
        }

        .ReloadIcon svg path {
          fill: #5b5656;
        }
      }

      .RandomUsernameValue {
        color: #181415;
      }
    }

    .ProgressPoint {
      span {
        color: #9a8e8e;
      }

      svg circle {
        fill: #5b5656;
      }

      &Filled {
        span {
          color: #fff;
        }

        svg circle {
          fill: #9f0220;
        }
      }
    }

    .ButtonDark {
      background: #0c0b0b !important;
      padding: 25px 35px;

      &:hover {
        color: #0c0b0b !important;
        border-color: #0c0b0b !important;
        background: #fff !important;
      }

      &:disabled {
        background: #462d32 !important;
        border-color: #462d32 !important;
        color: #674c4c !important;

        &:hover {
          background: #462d32 !important;
          color: #674c4c !important;
        }
      }
    }
  }

  &.Version13-2 {
    font-family: 'Nunito Sans', sans-serif;
    background-image: url('../../../../../../assets/images/Lander13/red/bg.jpeg');

    .BackgroundOverlay,
    .LoadingOverlay {
      background: rgba(107, 44, 43, 0.9);
    }

    .LanderContentInner {
      background: #1a1010;
      border: none;
      box-shadow: 0px 10px 31px 17px rgba(22, 20, 20, 0.2);
    }

    .LanderContentInnerLeft {
      background-image: url('../../../../../../assets/images/Lander13/red/side-girl.png');

      @include respond(phone) {
        background-image: url('../../../../../../assets/images/Lander13/red/side-girl-xs.png');
      }
    }

    .Message13 {
      background-color: #c32f23;
    }

    .VerticalOption13 .BackgroundOverlay13 {
      background: rgba(113, 50, 45, 0.8);
    }

    .PasswordHelpText {
      color: #bc9192;
    }

    .AutogeneratePasswordMsg {
      color: #fff;
    }

    .UsernameInputContainer {
      svg rect {
        fill: #c32f23;
      }
    }

    .RandomUsernameContainer {
      .RandomUsernameLabel {
        span {
          color: #616161;
        }

        .ReloadIcon svg path {
          fill: #c32f23;
        }
      }

      .RandomUsernameValue {
        color: #c32f23;
      }
    }

    .ProgressPoint {
      span {
        color: #9a8e8e;
      }

      svg circle {
        fill: #502b2b;
      }

      &Filled {
        span {
          color: #fff;
        }

        svg circle {
          fill: #c32f23;
        }
      }
    }

    .ButtonDark {
      background: #c32f23 !important;
      padding: 25px 35px;

      &:hover {
        color: #c32f23 !important;
        border-color: #c32f23 !important;
        background: #fff !important;
      }

      &:disabled {
        background: #462d32 !important;
        border-color: #462d32 !important;
        color: #674c4c !important;

        &:hover {
          background: #462d32 !important;
          color: #674c4c !important;
        }
      }
    }
  }

  &.Version13-3 {
    font-family: 'Poppins', sans-serif;
    background-image: url('../../../../../../assets/images/Lander13/purple/bg.jpg');

    .BackgroundOverlay,
    .LoadingOverlay {
      background: linear-gradient(
        180deg,
        rgba(109, 2, 159, 0.85) 51.02%,
        rgba(221, 42, 117, 0.85) 106.9%
      );
    }

    .LanderContentInner {
      border: none;
      background: #d9a4e7;
      box-shadow: 0px 10px 31px 17px rgba(99, 31, 122, 0.62);
    }

    .LanderContentInnerLeft {
      background-image: url('../../../../../../assets/images/Lander13/purple/side-girl.png');

      @include respond(phone) {
        background-image: url('../../../../../../assets/images/Lander13/purple/side-girl-xs.png');
      }
    }

    .Message13 {
      background-color: #45035b;
    }

    .VerticalOption13 .BackgroundOverlay13 {
      background: rgba(168, 25, 218, 0.8);
    }

    .PasswordHelpText {
      color: #45035b;
    }

    .AutogeneratePasswordMsg {
      color: #45035b;
    }

    .UsernameInputContainer {
      svg rect {
        fill: #45035b;
      }
    }

    .RandomUsernameContainer {
      .RandomUsernameLabel {
        span {
          color: #989898;
        }

        .ReloadIcon svg path {
          fill: #45035b;
        }
      }

      .RandomUsernameValue {
        color: #45035b;
      }
    }

    .ProgressPoint {
      span {
        color: #9a8e8e;
      }

      svg circle {
        fill: #8242a0;
      }

      &Filled {
        span {
          color: #fff;
        }

        svg circle {
          fill: #45035b;
        }
      }
    }

    .ButtonDark {
      background: #c42add !important;
      box-shadow: 0px 0px 8px 4px rgba(231, 207, 245, 0.44);
      padding: 25px 35px;

      &:hover {
        color: #c42add !important;
        border-color: #c42add !important;
        background: #fff !important;
      }

      &:disabled {
        background: #462d32 !important;
        border-color: #462d32 !important;
        color: #674c4c !important;

        &:hover {
          background: #462d32 !important;
          color: #674c4c !important;
        }
      }
    }
  }

  &.Version13-4 {
    font-family: 'Titillium Web', sans-serif;
    background-image: url('../../../../../../assets/images/Lander13/blue/bg.jpg');

    .BackgroundOverlay,
    .LoadingOverlay {
      background: linear-gradient(
        180deg,
        rgba(0, 0, 123, 0.85) 21.35%,
        rgba(68, 49, 185, 0.85) 100%
      );
    }

    .LanderContentInner {
      border: none;
      background: #0c1844;
      box-shadow: 0px 0px 29px 19px rgba(36, 76, 113, 0.42);
    }

    .LanderContentInnerLeft {
      background-image: url('../../../../../../assets/images/Lander13/blue/side-girl.png');

      @include respond(phone) {
        background-image: url('../../../../../../assets/images/Lander13/blue/side-girl-xs.png');
      }
    }

    .Message13 {
      background-color: #c1e6ff;
      color: #181415;
      font-weight: 600;
    }

    .VerticalOption13 .BackgroundOverlay13 {
      background: rgba(0, 41, 185, 0.8);
    }

    .PasswordHelpText {
      color: #8590b8;
    }

    .AutogeneratePasswordMsg {
      color: #8590b8;
    }

    .UsernameInputContainer {
      svg rect {
        fill: #715aff;
      }
    }

    .RandomUsernameContainer {
      .RandomUsernameLabel {
        span {
          color: #989898;
        }

        .ReloadIcon svg path {
          fill: #715aff;
        }
      }

      .RandomUsernameValue {
        color: #715aff;
      }
    }

    .ProgressPoint {
      span {
        color: rgba(74, 105, 214, 0.8);
      }

      svg circle {
        fill: #3724ad;
      }

      &Filled {
        span {
          color: #fff;
        }

        svg circle {
          fill: #715aff;
        }
      }
    }

    .ButtonDark {
      background: #715aff !important;
      padding: 25px 35px;

      &:hover {
        color: #715aff !important;
        border-color: #715aff !important;
        background: #fff !important;
      }

      &:disabled {
        opacity: 0.4;
        background: #715aff !important;
        border-color: #715aff !important;
        color: #c1e6ff !important;

        &:hover {
          background: #715aff !important;
          color: #c1e6ff !important;
        }
      }
    }
  }

  @media (max-width: 1020px) and (orientation: 'landscape') {
    padding: 2.5em;
  }

  .BackgroundOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .LoadingOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      animation: rotate 2s infinite linear;

      @include respond(phone) {
        height: 40px;
      }
    }
  }

  .LanderContent {
    width: 880px;

    @include respond(phone) {
      height: 100%;
      width: 100%;
    }

    .LanderContentInner {
      display: flex;
      padding: 0;

      @include respond(phone) {
        flex-direction: column-reverse;
        height: 85%;
      }

      .LanderContentInnerRight {
        padding: 39px 54px 45px 32px;
        width: 100%;

        @include respond(phone) {
          padding: 30px 25px 20px 25px;
          height: 57%;
        }
      }

      .LanderContentInnerLeft {
        border-top-left-radius: 9px;
        border-bottom-left-radius: 9px;
        width: auto;
        min-width: 288px;
        background-position-x: right;
        position: relative;
        background-repeat: no-repeat;
        background-size: cover;
        height: 500px;

        @include respond(phone) {
          height: 43%;
          min-width: 100%;
          border-radius: 9px;
        }
      }
    }
  }

  @keyframes fade-in {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  .GenderContainer {
    @include respond(phone) {
      height: 100%;
    }

    .ToggleElement {
      @include respond(phone) {
        height: 75%;
      }

      @include respond(extraSmallPhone) {
        height: 65%;
      }
    }
  }

  .ToggleElement {
    opacity: 0;
    animation: fade-in 0.1s ease-in-out forwards;

    @include respond(phone) {
      justify-content: space-between;
    }
  }

  .Hidden {
    opacity: 0;
  }
}
