@import '../../../variables.scss';

.drag-and-drop-container {
  .drag-and-drop-area {
    .drag-and-drop-icon {
      font-size: 30px;
      color: rgb(248, 182, 182);
    }
  }

  .progress-item {
    margin: 5px 0;

    .progress-header {
      display: flex;
      gap: 20px;
      align-items: center;

      .progress-header-icon {
        &:hover {
          cursor: pointer;
          color: $main-color;
        }
      }
    }

    .ant-progress-inner {
      .ant-progress-bg {
        background-color: $main-color;
      }
    }
  }
}
