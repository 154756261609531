@import '../../../../../variables';
@import '../../../../../mixins';

.ContactUs {
  .ContactUs-Container {
    padding-top: 70px;
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    margin-bottom: 280px;

    @include respond(phone) {
      padding-top: 40px;
      width: 85%;
      margin-bottom: 100px;
    }

    .ContactUs-Content {
      text-align: center;
      color: $contact-us-header-text;
      padding: 10px 0;

      .ContactUs-By {
        font-family: 'Roboto';
        font-weight: 400;
        line-height: 28px;
        font-size: 16px;

        @include respond(phone) {
          width: 95%;
        }

        div {
          display: inline;
          margin-left: 4px;

          &:hover {
            text-decoration: underline;
          }
        }

        .br {
          @include respond(phone) {
            display: none;
          }
          @include respond(tablet) {
            display: none;
          }
        }
      }
    }

    .Support-Text {
      color: $main-color;
      text-decoration: none;
      font-weight: 600;
      letter-spacing: 0.01rem;
    }

    input {
      width: 450px;
      padding: 15px;
      border-radius: 8px;
      border: 1px solid $input-border;
      color: black;
      outline: none;
      font-size: 16px;
      text-align: start;
      font-family: 'Gothic A1';

      &::placeholder {
        color: $placeholder-text;
        text-transform: capitalize;
      }
    }

    .Message-Form {
      display: flex;
      flex-direction: column;
      gap: 5px;

      @include respond(phone) {
        width: 100%;
      }

      .Input {
        padding: 25px 30px;
        font-size: 16px;
        border: 1px solid $input-border;
        width: 555px;

        @include respond(phone) {
          width: 100%;
          height: 56px;
        }
      }
      .Message-Area {
        height: 202px;
        text-align: start;
        border-radius: 8px;
        border: 1px solid $input-border;
        padding: 15px;
        margin: 10px 0;
        font-family: 'Gothic A1';
        font-size: 16px;
        outline: none;

        &::placeholder {
          color: #999;
        }
      }

      .Button {
        text-align: center;
        margin: 10px 0 120px 0;

        @include respond(phone) {
          margin-bottom: 50px;
        }
      }
    }

    .Cancel-Subscription {
      font-family: 'Raleway', sans-serif;
      font-weight: 500;
      font-size: 28px;
      line-height: 44px;
      color: #393939;
      text-align: center;
      margin-bottom: 10px;

      @include respond(phone) {
        width: 100%;
      }

      .Cancel-Subscription-text {
        font-family: 'Open Sans', sans-serif;
        color: $contact-us-header-text;
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        text-align: center;
        margin-bottom: 50px;
        @include respond(phone) {
          margin-bottom: 30px;
          width: 326px;
        }
      }
    }

    .Cancel-Subscription-Form {
      display: flex;
      flex-direction: column;

      @include respond(phone) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 0;
      }

      .Input {
        padding: 25px 30px;
        font-size: 16px;
        border: 1px solid $input-border;
        width: 555px;

        @include respond(phone) {
          height: 56px;
          width: 100%;
        }
      }

      .InputField {
        @include respond(phone) {
          height: 56px;
          margin-bottom: 26px;
          width: 100%;
        }
      }

      .Button {
        text-align: center;

        @include respond(phone) {
          width: 100%;
        }
      }

      .disabled {
        opacity: 0.4;
        cursor: auto;
      }
    }
  }
  .NoteText {
    text-align: center;
    font-size: 11px;
    margin-bottom: 200px;
  }

  .CancelSubsText {
    font-style: italic;
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    margin-top: 5px;
  }

  .Message-Area-Field {
    height: auto;
  }
}

// .messagePopup {
//  padding: 30px 10px;
// }
