@import '../../../../../../mixins.scss';
@import '../../../../../../variables.scss';

//desktop view

.MembersPricing {
  // height: 100vh;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 80px 10px;
  width: 100%;

  @include respond(phone) {
    padding: 10px 5px;
  }

  .MembersPricing-Header {
    font-family: 'Raleway';
    font-weight: 700;
    font-size: 30px;
    line-height: 24px;
    margin-bottom: 120px;
    text-align: center;
    line-height: 1em;

    span {
      color: $main-color;
    }

    @include respond(phone) {
      text-align: center;
      line-height: 1.2em;
      margin-bottom: 10px;
      font-size: 22px;
      line-height: 0.8em;
    }

    @media (min-width: 537px) and (max-width: 721px) {
      margin: 20px 0 40px 0;
    }
  }
  .Pricing-Info {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    max-width: 50%;
    margin: 70px 0 90px 0;

    span {
      color: $main-color;
    }

    @include respond(phone) {
      max-width: 85%;
      margin: 20px auto 40px auto;
    }
    @include respond(tablet) {
      max-width: 85%;
      margin: auto;
      margin-bottom: 45px;
    }
  }

  .LoaderComponent {
    margin: auto;
    margin-bottom: 120px;

    @include respond(phone) {
      margin: 35px 0;
    }
  }

  .MembersPricing-Wrapper {
    // border: 1px solid red;
    width: 75%;

    @include respond(phone) {
      width: 90%;
    }

    @media (min-width: 1024px) and (max-width: 1119px) {
      width: 90%;
    }
  }

  .PricingCards-List {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    list-style-type: none;
    margin: 0;
    padding: 0;

    @include respond(phone) {
      flex-direction: column;
      gap: 70px;
    }

    @media (min-width: 537px) and (max-width: 721px) {
      width: 90%;
      margin: auto;
    }

    @include respond(tablet) {
      flex-direction: column;
      width: 80%;
      margin: auto;
      margin-bottom: 70px;
      gap: 80px;
    }

    // @media (min-width: 721px) and (max-width: 1170px) {
    //   width: 80%;
    //   margin: auto;
    //   flex-direction: column;
    //   gap: 90px;
    // }

    // @media (min-width: 1025px) and (max-width: 1150px) {
    //   flex-direction: column;
    //   width: 80%;
    //   margin: auto;
    //   margin-bottom: 70px;
    //   gap: 80px;
    // }
  }

  .Card-Info {
    margin: 35px 70px;

    @include respond(phone) {
      margin: 5px;
    }

    @media (min-width: 1024px) and (max-width: 1119px) {
      margin: 20px 30px;
    }
  }

  .CardInfo-Sidebar {
    margin: 35px 70px;

    @include respond(phone) {
      margin: 10px;
    }

    @include respond(tablet) {
      margin: 25px 30px;
    }

    // @media (min-width: 1024px) and (max-width: 1119px) {
    //   margin: 20px 30px;
    // }

    @media (min-width: 1100px) and (max-width: 1415px) {
      margin: 15px 30px;
    }

    @media (min-width: 1025px) and (max-width: 1099px) {
      margin: 15px;
    }
  }

  .Pricing-Card {
    background-color: $pricing-card-background;
    border-radius: 8px;
    // display: flex;
    // flex-direction: column;
    // justify-content: center;
    // align-items: center;

    @include respond(phone) {
      box-shadow: 3px 3px 3px 3px rgba(117, 117, 117, 0.5);
    }

    @include respond(tablet) {
      box-shadow: 3px 3px 3px 3px rgba(117, 117, 117, 0.5);
    }
    @media (min-width: 1024px) and (max-width: 1119px) {
      box-shadow: 3px 3px 3px 3px rgba(117, 117, 117, 0.5);
    }
  }

  #Move-Top {
    @include respond(phone) {
      margin-top: -50px;
    }

    @include respond(tablet) {
      margin-top: -50px;
    }
    // @media (min-width: 1024px) and (max-width: 1119px) {
    //   margin-top: -50px;
    // }

    // @media (min-width: 1025px) and (max-width: 1150px) {
    //   margin-top: -50px;
    // }
  }

  .MostPopular-Card {
    border-radius: 0 0 8px 8px;
    background-color: $pricing-card-background-popular;

    @include respond(phone) {
      box-shadow: 3px 3px 3px 3px rgba(117, 117, 117, 1);
      margin-top: -20px;
    }
    @include respond(tablet) {
      box-shadow: 3px 3px 3px 3px rgba(117, 117, 117, 1);
    }
    @media (min-width: 1024px) and (max-width: 1119px) {
      box-shadow: 3px 3px 3px 3px rgba(117, 117, 117, 1);
    }
  }

  .Most-Popular {
    background-color: $pricing-golden;
    height: 50px;
    margin-top: -50px;
    border-radius: 8px 8px 0 0;
    text-align: center;
    display: flex;
    justify-content: center;
    align-content: center;
    @include respond(phone) {
      height: 30px;
      margin-top: -30px;
    }
  }
  .MostPopular-Header {
    margin: auto;
    font-family: 'Open Sans';
    font-weight: 700;
    font-size: 16px;
    text-align: center;
    text-transform: uppercase;
    color: #000;
    @include respond(phone) {
      font-size: 12px;
    }
  }

  .Hidden {
    display: none;
    visibility: hidden;
  }

  .Name-FreeMessages {
    border-bottom: 1px solid #d6d6d6;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .Pricing-Name {
    color: $pricing-card-name;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    // line-height: 28px;
    text-transform: uppercase;
    margin-bottom: 20px;

    @include respond(phone) {
      margin-bottom: 0;
      font-size: 16px;
    }
  }

  .PricingName-MostPopular {
    color: $pricing-name-golden;
  }

  .Free-Messages {
    font-family: 'Open Sans';
    font-weight: 700;
    font-size: 15px;
    color: $pricing-card-name;
    text-transform: uppercase;
    white-space: nowrap;

    @include respond(phone) {
      margin-bottom: 2px;
      font-size: 10px;
      // white-space: normal;
    }
    @include respond(tablet) {
      white-space: normal;
    }
  }

  .Card-Price {
    font-family: 'Open Sans';
    font-weight: 700;
    font-size: 36px;
    color: $pricing-card-name;
    margin: 10px 0 0 0;
    text-align: center;
    width: 100%;

    @include respond(phone) {
      margin: 0;
      font-size: 20px;
    }
  }

  .Card-Billing {
    font-family: 'Open Sans';
    font-weight: 800;
    letter-spacing: 0.1em;
    font-size: 13px;
    color: $pricing-card-name;
    text-transform: uppercase;
    text-align: center;
    white-space: nowrap;

    @include respond(phone) {
      font-size: 10px;
      margin-bottom: 0;
      white-space: normal;
    }

    @include respond(tablet) {
      white-space: normal;
      margin-top: 5px;
    }
  }

  .FreeMessages-MostPopular,
  .CardPrice-MostPopular {
    color: $pricing-frequency-popular;
  }
  .CardBilling-MostPopular {
    color: #fff;
  }

  .Checkout-Btn {
    width: 100%;
    background-color: $button-color;
    color: #f3f3f3;
    border: none;
    padding: 10px 5px;
    border-radius: 8px;
    outline: none;
    font-family: 'Raleway', sans-serif;
    font-weight: 700;
    font-size: 16px;
    text-transform: uppercase;
    margin: 20px 0 10px 0;
    cursor: pointer;

    @include respond(phone) {
      margin: 5px 0;
      padding: 3px;
      font-size: 14px;
    }
  }

  .PremiumBenefits {
    display: flex;
    width: 75%;

    @include respond(phone) {
      flex-direction: column;
    }

    .PremiumBenefit {
      text-align: center;
      flex: 1;
      height: fit-content;
    }
  }

  .ant-divider-dashed {
    border-color: #d3d3d3;
  }
}

.No-Wrap {
  // white-space: nowrap;

  @include respond(phone) {
    white-space: normal;
  }
}
