@import '../../../../../../../../variables.scss';
@import '../../../../../../../../mixins.scss';

* {
  box-sizing: border-box;
}

.DragAndDropZoneGallery {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 8px;
  background-color: white;
  @include respond(phone) {
    padding: 62px 0 47px;
  }
  .GalleryPhotoContainerOuter {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .GalleryPhotoContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    row-gap: 5px;
    column-gap: 5px;
    min-height: 250px;
    .GalleryPhoto {
      width: 115px;
      height: 130px;
      border-radius: 8px;
      @include respond(phone) {
        width: 110px;
        height: 120px;
      }
    }

    .GalleryPhotoImg {
      width: 115px;
      height: 130px;
      border-radius: 8px;
      cursor: pointer;
      @include respond(phone) {
        width: 110px;
        height: 120px;
      }
    }
  }

  .DragAndDropText {
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #253858;
  }
  .DragAndDropTextWrapper {
    height: 100%;
    width: 100%;
    border: 2px dashed #d6d6d6;
    padding: 75px 73px 32px 68px;
  }

  .LoaderComponentWrapper {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .LoaderComponentWrapperGallery {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    min-height: 100px;
  }
  .button-wrapper-add-new {
    padding: 19px 72px 17px 72px;
    white-space: nowrap;
    white-space: nowrap;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
  }
}

.DragAndDropZoneGallery.inside-drag-area {
  opacity: 0.7;
}

.tip-text {
  margin-bottom: 45px;
}

.additional-photos {
  padding: 0;
  background-color: white;
}

.additional-text {
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  margin: 0;
  margin-bottom: 49px;
}

.change-photo {
  padding: 0px 0px 50px;
}
