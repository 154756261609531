.gift-modal {
  .ant-modal-body {
    @media only screen and (max-width: 600px) {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }

    .ant-spin-container > div {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }
}