.Options {
  display: flex;
}

.LanderContainer13 {
  .VerticalOption13 {
    background-size: cover;
    background-position: center;

    .BackgroundOverlay13 {
      border-radius: 9px;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    &:hover {
      background-color: none;
    }
  }
}
