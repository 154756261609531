@import '../../../../../../../../variables.scss';
@import '../../../../../../../../mixins.scss';

.Button {
  background: #921d26;
  font-weight: 600;
  font-size: 18px;
  line-height: 136.4%;
  letter-spacing: 0.185em;
  padding: 23px 35px;

  @include respond(phone) {
    font-size: 16px;
    padding: 18px;
  }
}
