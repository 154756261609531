@import '../../../../../../../../mixins.scss';

.LanderContainer13 {
  .AutogeneratePasswordMsg {
    text-align: center;
    font-size: 18px;
    justify-content: center;
    align-items: center;
    display: flex;
    padding: 15px 10px 20px 10px;

    @include respond(phone) {
      font-size: 13px;
    }
  }

  .PasswordHelpText {
    font-size: 15px;
    justify-content: center;
    align-items: center;
    display: flex;
    text-align: center;
    padding: 5px 0 20px 0;

    @include respond(phone) {
      font-size: 13px;
    }
  }

  .UsernameContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
  }

  .UsernameFieldContainer {
    position: relative;

    .UsernameInputContainer {
      position: relative;

      svg {
        position: absolute;
        right: 3%;
        top: 21%;
        z-index: 1;
        cursor: pointer;

        @include respond(phone) {
          width: 20px;
          height: 20px;
        }
      }
    }

    .RandomUsernameContainer {
      position: absolute;
      top: 51px;
      z-index: 1;
      box-shadow: 0px 4px 4px 0px rgba(115, 89, 89, 0.25);
      border-radius: 9px;
      padding: 18px;
      background: white;
      width: 100%;

      @include respond(phone) {
        padding: 10px;
      }

      .RandomUsernameLabel {
        position: relative;

        span {
          font-size: 14px;

          @include respond(phone) {
            font-size: 11px;
          }
        }

        .ReloadIcon {
          position: absolute;
          right: 0;
          top: 0;
          cursor: pointer;

          svg {
            @include respond(phone) {
              width: 20px;
              height: 20px;
            }
          }
        }
      }

      .RandomUsernameValue {
        font-size: 18px;
        margin-top: 5px;
        cursor: pointer;

        @include respond(phone) {
          font-size: 14px;
        }
      }
    }
  }
}
