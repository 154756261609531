@import '../../../../../../../variables.scss';
@import '../../../../../../../mixins.scss';

.HorizontalOptionDisabled {
  cursor: default !important;

  &:hover .BackgroundOverlay {
    opacity: 1 !important;
  }
}

.HorizontalOption {
  width: 507px;
  height: 116px;
  cursor: pointer;
  background: rgba(49, 12, 14, 0.7);
  border-radius: 9px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-position: center;

  @include respond(smallPhone) {
    height: 90px;
  }

  @include respond(extraSmallPhone) {
    height: 70px;
  }

  @include respond(phone) {
    width: 100% !important;
  }

  &:not(:last-child) {
    margin-bottom: 7px;
  }

  &:hover .BackgroundOverlay12 {
    opacity: 0;
  }

  .BackgroundOverlay12 {
    border-radius: 9px;
    // background: rgba(30, 30, 30, 0.7);

    &.version12-1 {
      // background: rgba(159, 2, 32, 1);
    }

    &.version12-2 {
      background: rgba(148, 7, 11, 0.8);
    }

    &.version12-3 {
      background: rgba(168, 25, 218, 0.8);
    }

    &.version12-4 {
      background: rgba(0, 41, 185, 0.8);
    }
  }

  span {
    z-index: 1;
    position: relative;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: 0.2em;
    color: #ffffff;
    text-transform: uppercase;
    text-align: center;
    text-shadow: 0px 4px 4px rgba(69, 33, 33, 0.58);

    @include respond(phone) {
      font-size: 16px;
    }

    @include respond(extraSmallPhone) {
      font-size: 14px;
    }
  }
}
