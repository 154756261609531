.GoogleLoginButton {
  background: #fff;
  border: 4px solid transparent;
  padding: 12px;
  text-align: center;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 600;

  &:hover {
    cursor: pointer;
    box-shadow: 0px 9px 14px rgba(255, 255, 255, 0.6);
  }

  .googleLoginHide {
    display: none;
  }
}
