@import '../../variables.scss';
@import '../../mixins.scss';

.VerifyAccount {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .ant-checkbox .ant-checkbox-inner {
    height: 18px !important;
    width: 18px !important;
    border-radius: 1px !important;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    height: 18px;
    width: 18px;
    border-radius: 1px;
    background-color: #9f0220 !important;
    border-color: #9f0220 !important;
    border-width: 3px;
    box-sizing: border-box;
    outline: none !important;
  }
  .ant-checkbox-checked .ant-checkbox-inner:after {
    width: 6px;
    height: 12px;
    border-width: 3px;
    left: 1px;
    top: 6px;
    position: absolute;
  }
  .ant-checkbox-checked .ant-checkbox-inner:hover {
    background-color: #9f0220;
    border-color: #9f0220;
  }
  .Loading {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background: #0000006e;
    top: 0;
    left: 0;
    z-index: 999;
  }

  .Verify-Modal-Sample {
    padding: 20px;
    width: 400px;
    border-radius: 8px;

    @include respond(phone) {
      width: auto;
    }

    .Verify-Header-Image {
      padding: 10px;
      display: flex;
      justify-content: center;
      margin-bottom: 15px;
    }

    .Verify-Header-Container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 40px;

      h2 {
        @include modalTitle;
      }

      p {
        @include modaSubtitle;
      }
    }
    .VerifyForm {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      // gap: 15px;

      .InputField {
        width: 100%;

        input {
          padding: 15px;
          border-radius: 8px;
          border: 1px solid #d8d8d8;
          color: black;
          outline: none;
          font-size: 14px;
          text-align: start;
          width: 100%;
          height: 56px;

          &::placeholder {
            color: gray;
            text-transform: capitalize;
          }
        }
      }

      .Button {
        &.SubmitButton {
          padding: 15px 0px;
          width: 100%;
          margin-top: 20px;
        }
      }

      .Checkbox-Container {
        display: flex;
        // align-items: baseline;
        margin-top: 20px;

        .Checkbox-Holder {
          margin-right: 5px;

          .Checkbox {
            &:hover {
              cursor: pointer;
            }
          }
        }

        input {
          margin: 10px 0;
          width: 20px;
        }

        p {
          margin: 0;
          margin-top: -5px;
          padding: 0 5px;
          color: $grey-text-color;
          font-family: 'Roboto';
        }

        span {
          color: $main-color;
          font-weight: 600;
          text-decoration: underline;

          &:hover {
            text-decoration: underline;
            cursor: pointer;
          }
        }
      }

      .Error-Container {
        color: red;
        font-size: 12px;
        margin-top: 10px;
      }
    }
  }
}
