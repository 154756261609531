@import '../../variables';
.SuccessMessage {
  background: #fff;
  opacity: 0;
  color: $main-color;
  border: 1px solid $main-color;
  position: fixed;
  top: 100px;
  right: 20px;
  width: 340px;
  font-size: 16px;
  text-align: center;
  padding: 20px;
  border-radius: 8px;
  transition: all 0.9s ease-in-out;
  z-index: 999;
  animation: slideOut 0.5s ease;

  &.Active {
    animation: slide 0.5s ease;
    opacity: 1;
  }
}

@keyframes slide {
  0% {
    top: -100%;
  }
  100% {
    top: 100px;
  }
}

@keyframes slideOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
