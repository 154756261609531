@import '../../variables';
@import '../../mixins.scss';

.PasswordResetConfirmationMessage {
  color: #000;
  padding-top: 25px;
  padding-bottom: 40px;

  .ImageContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;

    .CheckImage {
      position: absolute;
    }
  }

  .Title {
    @include modalTitle;
    padding-top: 20px;
    margin-bottom: 10px;
  }

  .Subtitle {
    @include modaSubtitle;
    padding-top: 20px;

    span {
      color: $main-color;
      text-decoration: underline;
      font-weight: 600;

      &:hover {
        cursor: pointer;
      }
    }
  }
}
