.ConversationHeader {
  display: flex;
  align-items: center;
  padding: 5px 20px;

  .BackArrowContainer {
    margin-right: 10px;
  }

  .UserPhotoContainer {
    .ImageComponent {
      width: 40px;
      height: 40px;
      margin-right: 15px;
      border-radius: 100%;
      overflow: hidden;

      @media screen and (min-width: 500px) and (max-width: 900px) {
        width: 30px;
        height: 30px;
      }

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .UserNameContainer {
    flex: 2;
    max-width: 70%;

    .Username {
      color: #253858;
      font-size: 18px;
      font-weight: 500;

      @media screen and (min-width: 500px) and (max-width: 900px) {
        font-size: 13px;
      }
    }

    .UserStatusPreview {
      color: #5e6c84;
      font-size: 13px;
      position: relative;

      @media screen and (min-width: 500px) and (max-width: 900px) {
        font-size: 11px;
      }

      .OnlineStatusContainer {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: row;

        .OnlineStatus {
          display: block;
          width: 6px;
          height: 6px;
          border-radius: 100%;
          background-color: #6ac85b;
          margin-right: 10px;
        }
      }

      .OfflineStatusContainer {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: row;

        .OfflineStatus {
          display: block;
          width: 6px;
          height: 6px;
          border-radius: 100%;
          background-color: #5e6c84;
          margin-right: 10px;
        }
      }
    }
  }
}
