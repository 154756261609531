@import '../../../../../../../mixins.scss';

.Conversation {
  flex: 3;
  position: relative;
  background-color: #f9f6f5;

  &.MobileClosed {
    @media screen and (max-width: 500px) {
      display: none;
    }
  }

  @media screen and (max-width: 500px) {
    position: fixed;
  }

  .LoaderConvComponent {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    flex-direction: column;
  }

  .MessageConversationLoader {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @include respond(phone) {
  }

  @media screen and (max-width: 1024px) {
    height: calc(100vh - 80px);
  }

  @media screen and (min-width: 500px) and (max-width: 900px) {
    width: 50%;
    flex: none;
    height: calc(100vh - 50px);
  }

  @media screen and (min-width: 650px) and (max-width: 900px) {
    width: 55%;
  }

  @media screen and (min-width: 750px) and (max-width: 900px) {
    width: 60%;
  }

  @media screen and (max-width: 500px) {
    height: calc(100vh - 80px);
    width: 100%;
  }

  .EmptyConversationContainer {
    text-align: center;
    margin-top: 150px;
    color: #5e6c84;
    font-size: 16px;

    .MatchGameButton {
      &:hover {
        cursor: pointer;
      }
    }

    @include respond(phone) {
      padding: 20px;
    }

    span {
      color: #9f0220;
      font-weight: 700;
    }
  }
}
