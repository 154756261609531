@import '../../../../../../../../variables.scss';
@import '../../../../../../../../mixins.scss';

.LanderContainer15 {
  .InputContainer {
    position: relative;
    margin-bottom: 12px;
    width: 100%;
    max-width: 500px;
    gap: 0;

    .ant-tag {
      width: fit-content;
      text-align: center;
      background-color: #ffffff;
      color: #000;
      border-radius: 0;
      border: none;
      margin: 0 auto;
      justify-content: center;
      display: flex;
      margin-top: 10px;
      padding: 2px 10px;
    }

    .ant-select {
      width: 100%;

      .ant-select-selector {
        padding: 5px 10px;
        width: 100%;
        border-radius: 9px;
        height: auto;
        text-align: left;
      }
    }
    .ant-input {
      padding: 9px;
      width: 100%;
      border-radius: 0;
      height: auto;
      text-align: left;
      background: #ffffff;
      border: 1px solid #ffffff;
      color: #000;
      font-size: 18px;

      &::placeholder {
        color: #623333;
      }

      &:hover {
        // border-color: #552122 !important;
      }

      // &:focus {
      //   border-color: #552122 !important;
      //   box-shadow: 0 0 0 2px rgba(196, 196, 196, 0.2) !important;
      // }

      @include respond(phone) {
        padding: 8px 10px;
      }
    }
  }
}
