@import '../../../../../../variables.scss';
@import '../../../../../../mixins.scss';

.LanderContainer15 {
  display: flex;
  font-family: 'Raleway';
  height: 100vh;
  background-color: #1e0404;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-size: cover;
  overflow: hidden;

  @media (max-width: 1020px) and (orientation: 'landscape') {
    // height: auto;
  }

  &.Version15-1 {
    background-image: url('../../../../../../assets/images/Lander15/version1/bg.png');

    @include respond(phone) {
      background-image: url('../../../../../../assets/images/Lander15/version1/bg-mobile.png');
    }

    .VerticalOption15 {
      background-position-y: 20%;
    }

    .Interests .VerticalOption15 {
      background-position-y: 30%;
    }

    .Age .VerticalOption15 {
      background-position-y: 5%;
    }

    .MessageContainer {
      &:first-child:not(:only-child) {
        .Message {
          background: transparent;
          color: rgba(192, 100, 153, 1);
        }
      }
    }
  }

  &.Version15-2 {
    background-image: url('../../../../../../assets/images/Lander15/version2/bg.png');

    @include respond(phone) {
      background-image: url('../../../../../../assets/images/Lander15/version2/bg-mobile.png');
    }

    .Message {
      // background: #D84252;
    }

    .VerticalOption15 .BackgroundOverlay {
      border-radius: 0px;
      background: #09204680;
    }

    .VerticalOption15.VerticalOptionActive {
      border: 2px solid #5422ac;
    }

    .VerticalOption15 {
      background-position-y: 20%;

      &:hover {
        span {
          background: #00b127;
        }
      }
    }

    .Interests .VerticalOption15 {
      background-position-y: 30%;
    }

    .Age .VerticalOption15 {
      background-position-y: 20%;
    }

    .Body .VerticalOption15 {
      background-position-y: 35%;
    }

    .RoundButton15 {
      background: #00b127 !important;
      border: 1px solid #00b127 !important;
      color: #fff !important;

      &:disabled {
        border: 1px solid #98e0a8 !important;
        background: #98e0a8 !important;
        color: #fff;

        &:hover,
        &:focus,
        &:active {
          color: #fff !important;
          border-color: #98e0a8 !important;
        }
      }
    }

    .ProgressBar {
      background: #fff !important;
    }

    .ProgressBar {
      background: #fff !important;

      .Percentage {
        background: #004f3c !important;

        @include respond(phone) {
          background: #004f3c !important;
        }

        span {
          color: #493c3c !important;
        }
      }
    }

    .ProtectionOptionButton {
      background-color: #17244a;
      color: white;
      border: 2px solid transparent;

      &Active {
        border-color: #5422ac;
      }
    }

    .InputContainer {
      .ant-tag {
        background-color: #d41411;
        color: #ffffff;
      }

      .ant-input,
      input {
        // background: #17244A;
        // border: 1px solid #3D4869;
        // color: #fff;

        &::placeholder {
          color: #3b4564;
        }
      }
    }

    .MessageContainer {
      &:first-child:not(:only-child) {
        .Message {
          background: transparent;
          color: #4c7f51;
        }
      }
    }
  }

  &.Version15-3 {
    background-image: url('../../../../../../assets/images/Lander15/version3/bg.png');

    @include respond(phone) {
      background-image: url('../../../../../../assets/images/Lander15/version3/bg-mobile.png');
    }

    .Message {
      // background: #D84252;
    }

    .VerticalOption15 .BackgroundOverlay {
      border-radius: 0px;
      background: #2d252880;
    }

    .VerticalOption15.VerticalOptionActive {
      border: 2px solid #b70f5b;
    }

    .VerticalOption15 {
      background-position-y: 20%;

      &:hover {
        span {
          background: #018fde;
        }
      }
    }

    .Interests .VerticalOption15 {
      background-position-y: 30%;
    }

    .Age .VerticalOption15 {
      background-position-y: 0;
    }

    .RoundButton15 {
      background: #018fde !important;
      border: 1px solid #018fde !important;
      color: #fff !important;

      &:disabled {
        border: 1px solid #99d2f2 !important;
        background: #99d2f2 !important;
        color: #fff;

        &:hover,
        &:focus,
        &:active {
          color: #fff !important;
          border-color: #99d2f2 !important;
        }
      }
    }

    .ProgressBar {
      background: #fff !important;
    }

    .ProgressBar {
      background: #fff !important;

      .Percentage {
        background: #2849bf !important;

        @include respond(phone) {
          background: #2849bf !important;
        }

        span {
          color: #7a3b53 !important;
        }
      }
    }

    .ProtectionOptionButton {
      // background-color: #35292D;
      color: white;
      border: 2px solid transparent;

      &Active {
        border-color: #b70f5b;
      }
    }

    .InputContainer {
      .ant-tag {
        // background-color: #d41411;
        // color: #ffffff;
      }

      .ant-input,
      input {
        // background: #35292D;
        // border: 1px solid #855968;
        // color: #fff;

        &::placeholder {
          color: #99a5d1;
        }
      }
    }

    .MessageContainer {
      &:first-child:not(:only-child) {
        .Message {
          background: transparent;
          color: #3c7eba;
        }
      }
    }

    .GoogleContainer {
      .RedGoogleLoginButton {
        background: #da5656;
      }
    }
  }

  &.Version15-4 {
    background-image: url('../../../../../../assets/images/Lander15/version4/bg.png');

    @include respond(phone) {
      background-image: url('../../../../../../assets/images/Lander15/version4/bg-mobile.png');
    }

    .Message {
      // background: #D84252;
    }

    .VerticalOption15 .BackgroundOverlay {
      border-radius: 0px;
      background: #3b363780;
    }

    .VerticalOption15.VerticalOptionActive {
      border: 2px solid #d84252;
    }

    .VerticalOption15 {
      background-position-y: 20%;

      &:hover {
        span {
          background: #ff551f;
        }
      }
    }

    .Interests .VerticalOption15 {
      background-position-y: 30%;
    }

    .Age .VerticalOption15 {
      background-position-y: 0;
    }

    .RoundButton15 {
      background: #ff551f !important;
      border: 1px solid #ff551f !important;
      color: #fff !important;

      &:disabled {
        border: 1px solid #ffbaa5 !important;
        background: #ffbaa5 !important;
        color: #fff;

        &:hover,
        &:focus,
        &:active {
          color: #fff !important;
          border-color: #ffbaa5 !important;
        }
      }
    }

    .ProgressBar {
      background: #fff !important;
    }

    .ProgressBar {
      background: #fff !important;

      .Percentage {
        background: #ff551f !important;

        @include respond(phone) {
          background: #ff551f !important;
        }

        span {
          color: #7a3b53 !important;
        }
      }
    }

    .ProtectionOptionButton {
      background-color: #e6e4e4;
      color: #181818;
      border: 2px solid transparent;

      &Active {
        border-color: #d84252;
      }
    }

    .InputContainer {
      .ant-input,
      input {
        // background: #E6E4E4;
        // border: 1px solid #C6C6C6;
        // color: #181818;

        &::placeholder {
          color: #c4c4c4;
        }
      }
    }

    .MessageContainer {
      &:first-child:not(:only-child) {
        .Message {
          background: transparent;
          color: #fb9349;
        }
      }
    }
  }

  .InputsContainer {
    gap: 0;

    @include respond(phone) {
      flex-direction: column;
    }

    .InputContainer {
      flex: 3;
    }
    .Button {
      flex: 1;
    }
  }
  .LoadingOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    background: rgba(0, 0, 0, 0.9);
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      animation: rotate 2s infinite linear;
      width: 115px;

      @include respond(phone) {
        height: 40px;
        width: 40px;
      }
    }
  }

  .ProgressBar {
    height: 5px;
    width: 60%;
    background: #fff;
    border-radius: 22px;
    margin-bottom: 29px;
    position: relative;

    @include respond(phone) {
      width: 90%;
      margin-left: 4%;
      margin-right: 4%;
    }

    .Percentage {
      height: 100%;
      background: #84215d;
      border-radius: 22px;

      @include respond(phone) {
        background: #84215d;
        height: 5px !important;
      }

      span {
        font-family: 'Syne', sans-serif;
        position: absolute;
        bottom: -12px;
        left: 50%;
        right: 50%;
        top: -10px;
        transform: translate(-50%, -50%);
        color: #493c3c;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;

        @include respond(phone) {
          left: 50%;
          right: auto;
          bottom: -10px;
        }
      }
    }
  }

  .LanderContent {
    z-index: 1;
    align-items: center;
    justify-content: center;
    width: 60%;
    height: 65%;

    @include respond(phone) {
      height: 80%;
      width: 90%;
    }

    .ContentContainer {
      width: 100%;
    }

    &.Version15-1 {
      background-image: url('../../../../../../assets/images/Lander15/version1/content-bg.png');
      background-size: cover;

      @include respond(phone) {
        background-image: url('../../../../../../assets/images/Lander15/version1/content-bg-mobile.png');
      }
    }
    &.Version15-2 {
      background-image: url('../../../../../../assets/images/Lander15/version2/content-bg.png');
      background-size: cover;
      @include respond(phone) {
        background-image: url('../../../../../../assets/images/Lander15/version2/content-bg-mobile.png');
      }
    }
    &.Version15-3 {
      background-image: url('../../../../../../assets/images/Lander15/version3/content-bg.png');
      background-size: cover;
      @include respond(phone) {
        background-image: url('../../../../../../assets/images/Lander15/version3/content-bg-mobile.png');
      }
    }
    &.Version15-4 {
      background-image: url('../../../../../../assets/images/Lander15/version4/content-bg.png');
      background-size: cover;
      @include respond(phone) {
        background-image: url('../../../../../../assets/images/Lander15/version4/content-bg-mobile.png');
      }
    }

    & > :not(:first-child) {
      flex: 1;
      // padding: 59px 54px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: inherit;

      @include respond(phone) {
        // flex-direction: column-reverse;
        padding: 0px 29px;
        // justify-content: flex-end;
        height: inherit;
        width: 90%;
        margin: auto;
      }

      @include respond(smallPhone) {
        // scale: 0.8;
        padding: 0;
      }

      @include respond(extraSmallPhone) {
        scale: 0.75;
        padding: 0;
      }
    }

    .LanderContentInner15 {
      box-sizing: border-box;
      margin-bottom: 50px;
      width: 60%;
      // height: 75%;

      @include respond(desktop) {
        width: 90%;
        margin: auto;
      }

      @include respond(tablet) {
        width: 80%;
        height: 60%;
      }

      @include respond(phone) {
        width: 100%;
      }
    }
  }

  .VerticalOption15 {
    max-width: 224px;

    @include respond(phone) {
      max-width: 100%;
      width: 100%;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    }
  }

  .SelectOptions {
    gap: 10px;

    @include respond(phone) {
      flex-direction: column;
    }
  }

  .Button {
    margin: auto;
    margin-top: 40px;
    max-width: 500px;
  }

  .Message {
    font-family: 'Syne', sans-serif;
    border-radius: 20px;
    background: transparent;
    font-size: 18px;
    padding: 5px 25px;

    @include respond(phone) {
      font-size: 14px;
      padding: 5px;
      margin-bottom: 3px;
    }

    &::after {
      background: none;
      border-color: transparent !important;
    }
  }

  .AvatarContainer {
    margin-right: 5px;
    z-index: 10;
  }

  .ToggleElement {
    opacity: 0;
    animation: fade-in 0.1s ease-in-out forwards;
    display: flex;
    gap: 10px;
  }

  .Hidden {
    opacity: 0;
    display: flex;
    pointer-events: none;
  }

  .MessageContainer {
    margin-bottom: 0px;
  }
  .InputField {
    display: flex;
    flex: 3 1;
    height: 50px;
    width: 100%;
  }
  input.Input {
    padding: 10px;
    width: 100%;
    border-radius: 0;
    height: auto;
    text-align: left;
    background: #ffffff;
    border: 1px solid #ffffff;
    color: #000;
    font-size: 18px;

    &::placeholder {
      color: #623333;
    }

    &:hover {
      // border-color: #552122 !important;
    }

    // &:focus {
    //   border-color: #552122 !important;
    //   box-shadow: 0 0 0 2px rgba(196, 196, 196, 0.2) !important;
    // }

    @include respond(phone) {
      padding: 8px 10px;
      margin-bottom: 12px;
    }
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
