@import '../../../../../../variables';
@import '../../../../../../mixins';
.MessagesInbox {
  height: calc(100vh - 80px);
  padding: 40px 70px;

  @media screen and (min-width: 901px) and (max-width: 1024px) {
    height: calc(100vh - 80px);
  }

  @media screen and (min-width: 500px) and (max-width: 900px) {
    height: calc(100vh - 50px);
  }

  @include respond(phone) {
    padding: 0;
    height: 100%;
  }

  @include respond(tablet) {
    padding: 0;
  }

  .MessageContainerLoader {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .MessageInboxTitle {
    font-size: 34px;
    margin-bottom: 50px;
    color: #253858;
    font-weight: 700;
  }

  .MessagesContainer {
    display: flex;
    background: #e3e3e3;
    padding: 20px;
    border-radius: 8px;
    height: calc(100vh - 160px);
    max-height: calc(100vh - 160px);
    @include respond(phone) {
      padding: 0;
      height: calc(100svh - 80px);
      max-height: calc(100svh - 80px);
      width: 100%;
      position: relative;
      bottom: 0;
    }
    @include respond(extraSmallPhone) {
      min-height: 100vh;
    }
    .messages-wrapper {
      display: flex;
      flex: 1;
      overflow: hidden;
      width: 100%;
      > div:first-child {
        width: 100%;
      }
    }
    @include respond(tablet) {
      padding: 0;
    }

    .LoaderConvComponent {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
  }

  .NotEnoughCreditsModalContainer {
    .ModalDialog {
      .ModalContainer {
        background: white;
        color: #fff;
        max-height: 90%;
        padding: 30px 43px;
        max-width: 620px;
        width: 620px;

        @include respond(phone) {
          width: 100%;
        }
      }
    }
  }
}

.NoCredits-Modal {
  @include respond(phone) {
    padding: 5px 10px !important;
    max-width: 80%;
  }
}

.emoji-mart {
  @include respond(phone) {
    width: 80% !important;
  }

  .emoji-mart-title-label {
    font-size: 18px;
  }

  .emoji-mart-preview {
    height: 50px;

    .emoji-mart-preview-data {
      left: 50px;
    }

    .emoji-mart-emoji span {
      width: 25px !important;
      height: 25px !important;
    }
  }
}
