@import '../../../variables.scss';
@import '../../../mixins.scss';

.ChangeCardWrapp {
  margin: 15px 10px 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .Loading {
    position: absolute;
    background: #ffffffbd;
    height: 100%;
    justify-content: center;
    align-items: center;
    margin-bottom: 0;

    .LoadingText {
      color: #000;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      span {
        margin-bottom: 10px;
      }
    }
  }

  .KeepOldCard,
  .AddNewCard {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    cursor: pointer;
    padding: 40px;
    border-radius: 8px;
    @include respond(phone) {
      padding: 20px;
    }

    &.Disabled {
      pointer-events: none;
    }
  }

  .KeepOldCard:hover {
    // border: solid 2px #a01c95;
    background-color: #eff2f6;
    // .KeepOldCardText,
    // .KeepOldCardNote {
    //   color: white;
    // }
  }

  .AddNewCard:hover {
    //border: solid 2px #a01c95;
    background-color: #eff2f6;
    // .AddNewCardText {
    //   color: white;
    // }
  }

  .KeepOldCardImg,
  .AddNewCardImg {
    height: 65px;
    width: 70px;
    margin-right: 50px;

    @include respond(phone) {
      width: 40px;
    }
  }

  .KeepOldCardTextWrapp {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: fit-content;
  }
  .KeepOldCardText,
  .AddNewCardText {
    color: black;
    font-size: 22px;
    cursor: pointer;

    @include respond(phone) {
      font-size: 18px;
      font-weight: 600;
    }
  }

  .AddNewCardText {
    margin-top: 10px;
  }
  .KeepOldCardNote {
    color: black;
    font-size: 18px;
    @include respond(phone) {
      font-size: 16px;
    }
  }
  .separator {
    background: $main-color;
    height: 2px;
    width: 100%;
    margin: 20px 0;
  }
  .ChangeCCWrapper {
    background-color: white;
    width: 600px;
    @include respond(phone) {
      padding: 16px;
    }
    .CloseButton {
      color: $main-color;
    }
  }
}

.ChangeCCWrapper {
  background-color: white !important;
  width: 600px;
  .CloseButton {
    color: black;
  }
}
