.image-croper-container {
  padding: 0 40px;

  .image-cropper-preview {
    display: flex;
    flex-direction: column;
    align-items: center;

    .ant-upload {
      &.ant-upload-select {
        width: 300px;
        height: 127px;
        overflow: hidden;
        padding: 10px;
        margin-right: 0;

        span {
          div {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 20px;
          }
        }
      }
    }
  }

  .profile-image-container {
    width: 70%;
    margin: 30px auto;

    img {
      width: 100%;
    }
  }
}
