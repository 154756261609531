@import '../../variables.scss';

.not-found {
  .header {
    background-color: $main-color;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    img {
      height: 45px;
      cursor: pointer;
    }
  }
  .page-not-found {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 65vh;
    h1 {
      color: $main-color;
    }
  }
}
