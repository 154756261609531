@import '../../variables';

.Button {
  font-size: 16px;
  line-height: 20px;
  color: $white;
  background: $button-color;
  text-transform: uppercase;
  border-radius: 8px;
  padding: 20px 35px;
  cursor: pointer;
  font-family: 'Raleway', sans-serif;
  font-weight: bold;
  font-style: normal;
  text-align: center;
  border: 2px solid transparent;
  width: 100%;
  &:hover {
    background: $white !important;
    color: $button-color !important;
    border-color: $button-color !important;
  }
  &:active {
    background: $active-button-color;
    color: $white;
    border-color: transparent;
  }
  &:focus {
    background: $active-button-color;
    color: $white;
    border-color: transparent;
  }
  &.Disabled {
    background: $disabled-button-color;
    color: $disabled-button-text-color;
    &:hover {
      background: $disabled-button-color !important;
      color: $disabled-button-text-color !important;
      border-color: transparent !important;
    }
  }
  &:disabled {
    background: $disabled-button-color;
    color: $disabled-button-text-color;
    &:hover {
      background: $disabled-button-color !important;
      color: $disabled-button-text-color !important;
      border-color: transparent !important;
    }
  }

  p {
    padding: 0;
    margin: 0;
  }

  &.Secondary {
    background: $white;
    color: $button-color;
    border-color: $button-color;

    &:hover {
      background: $button-color;
      color: $white;
      border-color: $white;
    }
    &:active {
      background: $active-button-color;
      color: $button-color;
      border-color: transparent;
    }
    &:focus {
      background: $active-button-color;
      color: $button-color;
      border-color: transparent;
    }
  }
}
