.credits-card-form-wrapper {
  perspective: 1000px;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  position: relative;
  height: 100%;
  border-radius: 20px;
  border: 1px solid #d7d5d5;
  background: #fff;

  .loading-overlay {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 20px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.9);
    z-index: 100;
    border-radius: 20px;
    backdrop-filter: blur(2px);

    p {
      font-weight: 400;
      font-size: 16px;
      line-height: 20.23px;
      font-family: 'Jost', sans-serif;
    }

    .ant-spin {
      .anticon {
        color: #1890ff;
      }
    }
  }

  .card {
    position: relative;
    width: 100%;
    height: 240px;
    transition: transform 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275);

    &.flipped {
      .card-front {
        transform: rotateY(180deg);
      }

      .card-back {
        transform: rotateY(0);
      }
    }

    .card-face {
      position: absolute;
      width: 100%;
      height: 100%;
      padding: 25px;
      border-radius: 15px;
      background: linear-gradient(135deg, #1890ff, #096dd9);
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
      color: white;

      .card-content {
        height: 100%;
        display: flex;
        flex-direction: column;
        gap: 15px;
      }

      &.card-front {
        transform: rotateY(0);
      }

      &.card-back {
        transform: rotateY(180deg);

        &:before {
          content: '';
          position: absolute;
          top: 40px;
          left: 0;
          width: 100%;
          height: 40px;
          background: rgba(0, 0, 0, 0.5);
        }

        .cvv-label {
          position: absolute;
          right: 25px;
          top: 100px;
          font-size: 14px;
          text-transform: uppercase;
        }

        input {
          position: absolute;
          right: 25px;
          top: 125px;
          width: 80px;
          text-align: center;
        }
      }
    }
  }
  .card-form-content {
    padding-top: 15px;
    form {
      display: flex;
      flex-direction: column;
      gap: 15px;

      .submit-button {
        display: flex;
        flex-direction: column;
        align-items: center;
        background: #49952e;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
        cursor: pointer;
        margin-top: 7px;
        border: none;
        padding: 13px;
        color: #fff;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: uppercase;

        &.disabled {
          background: #cccccc;
          cursor: not-allowed;
          opacity: 0.7;
        }

        span {
          color: #cbb4b4;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px; /* 150% */
        }
      }
    }
  }

  .back-button {
    position: absolute;
    top: -20px;
    left: 0;
    background: #fff;
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    transition: transform 0.2s ease;
    visibility: hidden;

    &:hover {
      transform: scale(1.1);
    }

    .anticon {
      font-size: 20px;
      color: #333;
    }
  }
}

.credits-card-container {
  width: 200px;
  height: auto;
  position: relative;
  z-index: 1;
  perspective: 1000px;

  &.flipped {
    width: 100%;
    max-width: 400px;
    min-width: 400px;
    z-index: 10;
    height: 429px;

    .flipper {
      .front {
        transform: rotateY(-180deg);
      }

      .back {
        transform: rotateY(0);
      }
    }

    .back-button {
      visibility: visible;
    }

    @media only screen and (max-width: 600px) {
      min-width: initial;
    }
  }

  .flipper {
    position: relative;
    width: 100%;
  }

  .front,
  .back {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transition: transform 0.6s ease-out;
  }

  .front {
    position: relative;
    height: auto;
    transform: rotateY(0);
  }

  .back {
    height: 429px;
    transform: rotateY(180deg);
  }
}

.form-row {
  display: flex;
  flex-direction: row;
  gap: 12px;
  padding: 0 15px;
  .form-group {
    padding: 0;
  }
  @media only screen and (min-width: 360px) {
    padding: 0 25px;
  }
}
.form-group {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 3px;
  width: 100%;
  padding: 0 15px;
  @media only screen and (min-width: 360px) {
    padding: 0 25px;
  }
  .form-input-icon {
    position: absolute;
    top: 50%;
    right: 30px;

    &.cvv-image {
      top: 57%;
      right: 10px;
    }
  }

  label {
    color: #474646;
    font-family: Raleway, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
    text-align: left;

    @media only screen and (max-width: 360px) {
      font-size: 14px;
    }
  }

  input {
    width: 100%;
    border-radius: 8px;
    border: 1.5px solid #d2cfcf;
    padding: 8px;
    &.valid {
      border-color: #52c41a;
      background-color: #f6ffed;
    }

    &.invalid {
      border-color: #ff4d4f;
      background-color: #fff2f0;
    }
  }
  .expiry-selects {
    display: flex;
    gap: 12px;

    select {
      flex: 1;
    }
  }

  select {
    width: 100%;
    border-radius: 8px;
    border: 1.5px solid #d2cfcf;
    padding: 8px;
    appearance: none;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 8px center;
    background-size: 1em;
    cursor: pointer;

    &:focus {
      outline: none;
      border-color: #1890ff;
    }

    &.valid {
      border-color: #52c41a;
      background-color: #f6ffed;
    }

    &.invalid {
      border-color: #ff4d4f;
      background-color: #fff2f0;
    }
  }

  .error-message {
    position: absolute;
    bottom: -20px;
    color: #ff4d4f;
    font-size: 12px;
    text-align: left;
  }
}
