@import '../../../../../../../../variables.scss';
@import '../../../../../../../../mixins.scss';

.LanderContainer18 {
  .AgeContainer {
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: center;
    height: 100%;
    background-image: url('../../../../../../../../assets/images/Lander18/animated/animated7.gif');
    background-size: cover;
    background-position: center;

    .StepContent {
      background-color: rgba(0, 0, 0, 0.40);
      width: 100%;
    }

    .InputMainContainer {
      text-align: center;

      .InputField {
        width: 80%;
        margin: auto;
        margin-bottom: 20px;

        input {
          border: 1px solid #fff;
          background-color: transparent;
          color: #fff;
          height: 70px;
          border-radius: 20px;

          ::placeholder {
            color: #fff;
          }
        }
        ::placeholder {
          color: #fff;
        }
      }
    }

    .ButtonContainer {
      width: 60%;
      margin: auto;
      margin-bottom: 40px;

      .Button {
        margin-bottom: 10px;
      }
    }

    .AgeImage {
      height: 288px;
      width: 258px;
      background-size: contain;
    }

    .AgeText {
      color: #fff;
      text-align: center;
      font-size: 24px;
      line-height: 33px;
      padding: 35px 0;
      margin-bottom: 0;
      width: 60%;
      margin: auto;
      font-weight: bold;

      @include respond(phone) {
        font-size: 19px;
      }
    }
  }
}
