@import '../../../mixins.scss';

.tip-info-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 50px;

  @include respond(phone) {
    margin-top: 20px;
    width: 100%;
  }

  .image-box {
    width: 250px;
    height: 250px;
    overflow: hidden;
    border-radius: 100%;
    border: 1px solid #f9b6b6;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 240px;
      height: 240px;
      overflow: hidden;
      border-radius: 100%;
    }
  }

  .tip-text {
    background-color: #ffdada;
    color: #9f0220;
    border: 1px solid #f9b6b6;
    padding: 15px;
    border-radius: 8px;
    margin-top: 20px;
  }
}
