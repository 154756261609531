@import 'src/assets/css/colors';

.credits-modal {
  background: white;
  text-align: center;
  padding-bottom: 0;

  @media only screen and (max-width: 600px) {
    .ant-modal-content {
      padding-top: 30px;
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  .ant-modal-body {
    background-color: white !important;

    .special-header {
      max-width: 190px;
    }

    .ModalStarterPackage {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      @media screen and (min-width: 768px) {
        flex-direction: column;
        align-items: center;
      }
      justify-content: center;
      .SelectBundleContainer {
        display: none;
      }
    }

    .ModalStarterPackage:last-of-type {
      .card-credit,
      .card-credit-v2 {
        margin-top: 10px;
      }
      .SelectBundleContainer {
        display: block;
      }
    }

    .SelectBundleContainer {
      text-align: center;
      font-size: 16px;
      font-weight: 500;
      margin: 20px 0;
      color: #44444f;
      @media only screen and (max-width: 600px) {
        font-size: 18px;
        text-transform: capitalize;
      }

      span {
        color: $main;
        font-weight: 700;
        text-decoration: underline;

        @media only screen and (max-width: 600px) {
          font-size: 22px;
        }

        &:hover {
          cursor: pointer;
        }
      }
    }
  }
  .credits-modal-body {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    justify-content: center;

    & > div {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      & > div {
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: center;
        align-items: center;
      }
    }

    @media only screen and (max-width: 600px) {
      width: 100%;
      gap: 1rem;

      & > div {
        width: 100%;
      }
    }
  }
  .purchase-modal-title {
    font-family: 'Raleway', serif;
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 132%;
    text-align: center;
    color: #393939;
    margin-bottom: 20px;
    .warning {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .buy {
      color: $main;
    }
  }
}
.modal-footer {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  width: 100%;
  border-top: 1px dashed #d3d3d3;
  padding: 20px 0;
}
