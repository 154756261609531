.normal-700-open-sans {
  font-family: 'Open Sans', serif;
  font-style: normal;
  font-weight: 700;
}

.credits-page {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;

  @media only screen and (max-width: 600px) {
    width: 100%;
  }
}
.page-header {
  font-family: 'Raleway', serif;
  font-weight: 700;
  text-align: center;
  line-height: 120%;
  color: #393939;
  margin-bottom: 24px;
}
.top-up {
  @extend .normal-700-open-sans;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #d41411;
  margin-top: 7px;
}
.balance {
  background: #f5dddd;
  padding: 20px 48px;
  text-align: center;
  border-radius: 13px;
  .amount {
    @extend .normal-700-open-sans;
    font-size: 35px;
    line-height: 142.18%;
    color: #d41411;
  }
  .note {
    @extend .normal-700-open-sans;
    font-size: 16px;
    line-height: 142.18%;
    text-align: center;
    text-transform: uppercase;
    color: #929292;
  }
}
