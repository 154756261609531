@import '../../variables';
@import '../../mixins';

.BasicFooter {
  background: #000000;

  .BasicFooter-Container {
    height: 100%;
    box-sizing: border-box;
    padding: 56px 0 43px 0;
  }

  .BasicFooter-Content {
    height: 100%;
    display: flex;
    justify-content: space-between;

    @include respond(phone) {
      align-items: center;
      text-align: center;
      padding: 0 40px;
      display: grid;
      grid-template-rows: 1fr 1fr 1fr;
    }

    @include respond(tablet) {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 0 20px;
    }

    .FooterLogo-Content {
      flex: 2;

      @include respond(phone) {
        width: 100%;
        grid-row-start: 1;
        margin-bottom: 50px;
      }

      @include respond(tablet) {
        width: 70%;
      }

      .ImageComponent {
        margin-bottom: 27px;
        height: 40px;
        @include respond(phone) {
          margin: auto;
          margin-bottom: 20px;
        }
        @include respond(tablet) {
          margin: auto;
        }
        img {
          height: 40px;
        }
      }
      p {
        font-family: 'Open Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;
        text-align: center;
        width: 75%;
        text-align: center;
        color: #b8b8b8;
        margin: auto;

        @include respond(phone) {
          line-height: 24px;
        }
      }
    }

    .phoneLogoContent {
      margin-bottom: 30px;
    }

    .Links-Column,
    .Corporation-Column {
      font-family: 'Open Sans', sans-serif;
      font-weight: 400;
      font-size: 14px;
      line-height: 28px;
      text-align: center;
      color: #b8b8b8;
      flex: 1;
    }
    .ListName {
      font-family: 'Raleway', sans-serif;
      font-weight: 700;
      font-size: 14px;
      line-height: 16px;
      text-align: center;
      letter-spacing: 0.03em;
      text-transform: uppercase;
      color: #ffffff;
      margin-bottom: 16px;
    }

    .Links-Column,
    .Corporation-Column {
      @include respond(tablet) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      // > div {
      //   font-size: 14px;
      //   font-weight: bold;
      //   text-transform: uppercase;
      //   margin-bottom: 25px;
      // }

      p {
        font-size: 14px;
        margin: 5px 0;
        color: $footer-text-color;
        cursor: pointer;

        @include respond(tablet) {
          margin: 0;
        }
      }
    }

    .Corporation-Column {
      p {
        cursor: auto;
      }
    }
  }

  .CopyrightFooter {
    height: 50px;
    background: $copyright-footer-background;
    @include respond(phone) {
      height: auto;
    }

    .CopyrightFooter-Container {
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: $copyright-footer-text;
      font-family: 'Gothic A1', sans-serif;
      font-size: 12px;
      font-weight: 600;

      @include respond(phone) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 7px;
      }

      @include respond(tablet) {
        padding: 0 20px;
      }

      .Terms-Container {
        padding: 0;

        @include respond(phone) {
          display: flex;
          flex-direction: column;
          gap: 10px;
          align-items: center;
        }

        .Text_Line_Separator {
          @include respond(phone) {
            display: none;
          }
        }
        span {
          cursor: pointer;
        }
      }
    }

    .Copyright-Text {
      font-family: 'Open Sans', sans-serif;
      font-weight: 400;
      font-size: 10px;
      line-height: 14px;
      @include respond(phone) {
        font-size: 12px;
        line-height: 16px;
      }
    }
  }
}
