@import '../../../../../../../variables.scss';
@import '../../../../../../../mixins.scss';

.Profile-Wrapper {
  background-color: white;
  margin: 20px;
  padding: 10px 120px;
  position: relative;

  @include respond(phone) {
    padding: 40px 0;
    margin: 0;
  }

  @include respond(tablet) {
    padding: 40px 30px;
  }

  .LoaderComponent {
    margin: 250px auto;
  }

  .Failure-Message {
    background-color: #e54242;
    border: none;
    position: fixed;
    color: white;
    font-family: 'Open Sans', serif;
    font-size: 16px;
    line-height: 24px;
    padding: 10px;
    width: 423px;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 80px;
    left: 50%;
    transform: translateX(-50%);
  }

  .Success-Message {
    background-color: #6ac85b;
    border: none;
    position: fixed;
    color: white;
    font-family: 'Open Sans', serif;
    font-size: 16px;
    line-height: 24px;
    padding: 10px;
    width: 423px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 80px;
    left: 50%;
    transform: translateX(-50%);
  }

  .Profile-Info {
    @include respond(tablet) {
      width: 100%;
    }
  }

  .Profile-Content {
    display: flex;
    justify-content: center;
    gap: 60px;
    margin-top: 80px;

    @include respond(phone) {
      flex-direction: column;
      width: 80%;
      margin: auto;
      gap: 0;
    }

    @include respond(tablet) {
      display: flex;
      flex-direction: column;
    }
  }
  .About-Me {
    // width: 33%;
    // width: calc(100% / 3);

    @include respond(phone) {
      width: 100%;
    }
    .AboutMe-Subheader {
      color: #253858;
      font-size: 16px;
    }
    textarea {
      // width: 360px;
      // height: 195px;
      width: 100%;
      height: 195px;
      color: #5e6c84;
      font-family: 'Open Sans', serif;
      line-height: 24px;
      padding: 0;
      outline: none;
      font-size: 16px;
      border: 1px solid #d6d6d6;
      border-radius: 8px;
      margin-bottom: 10px;
    }
  }

  .General-Information {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .ZipCode-Wrapper {
      .ZipCode-Select {
        .InputField {
          .Input {
            padding: 25px 30px;
            color: #5e6c84;
          }
        }
        .Invalid-Zip {
          color: red;
          font-size: 12px;
          margin-top: -5px;
        }
      }
    }
  }

  .Personal-Info {
    // width: 33%;
    // width: calc(100% / 3);

    @include respond(phone) {
      width: 100%;
    }

    .Button {
      margin: 120px 0 50px 0;
      width: 100%;
      @include respond(phone) {
        margin: 70px auto 50px auto;
      }
    }
  }
}

.Header {
  color: #253858;
  font-size: 20px;
  font-weight: 700;
  font-family: 'Open Sans', serif;
  margin-bottom: 30px;
}

.Subheader {
  font-family: 'Open Sans', serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #253858;
}

.Dropdown-Select {
  color: #5e6c84;
  font-family: 'Open Sans', serif;
  font-size: 16px;
  line-height: 24px;
  padding: 15px 20px;
  border-radius: 8px;
  outline: none;
  margin-bottom: 20px;
  border-color: #d6d6d6;
  background-color: transparent;
}
.Dropdown-Select:not(.Exclude) {
  width: 100%;
}

option {
  color: #5e6c84;
  font-family: 'Open Sans', serif;
  font-size: 16px;
  line-height: 24px;
}

.Tip {
  // width: 33%;
  // width: calc(100% / 3);

  .image-box {
    width: 100%;
    height: auto;
    border-radius: 8px 8px 0 0;
    overflow: hidden;

    img {
      width: 100%;
    }
  }

  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  // padding: 0 25px;

  @include respond(phone) {
    margin-top: 20px;
    width: 100%;
  }

  p {
    border: 2px dotted #d6d6d6;
    border-top: none;
    border-radius: 0 0 8px 8px;
    margin: 0;
    padding: 20px;
    color: #253858;
    font-family: 'Open Sans', sans-serif;
    line-height: 24px;
    font-weight: 400;
    font-size: 16px;
  }
}

.Toggle-Options {
  font-family: 'Open Sans', serif;
  margin-bottom: 20px;
  label {
    user-select: none;
    color: #6d6d75;
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    margin: auto;
    align-items: center;

    span {
      min-width: 63px;
    }

    input[type='checkbox'] {
      display: none;
      &:checked + .toggle {
        background-color: $main-color;
        &::before {
          left: calc(60px - 25px);
        }
      }
    }

    .toggle {
      cursor: pointer;
      display: inline-block;
      width: 63px;
      height: 30px;
      margin: 10px;
      border-radius: 15px;
      background-color: #c4c4c4;
      position: relative;
      transition: background-color 0.3s ease-in-out;
      &::before {
        content: '';
        transition: left 0.3s ease-in-out;
        position: absolute;
        width: 22px;
        height: 22px;
        border-radius: 100%;
        display: block;
        top: 50%;
        left: 4px;
        transform: translateY(-50%);
        background-color: #fff;
      }
    }
  }
}

.Birthday-Dropdowns {
  display: flex;
  width: 100%;
  justify-content: space-between;

  :nth-child(1) {
    width: 20%;
    padding: 10px;
    padding-right: 0;

    @media screen and (min-width: 1025px) and (max-width: 1480px) {
      width: 25%;
    }
  }
  :nth-child(2) {
    width: 50%;
    padding-right: 0;
    padding: 10px;

    @media screen and (min-width: 1025px) and (max-width: 1480px) {
      width: 35%;
    }
  }
  :nth-child(3) {
    width: 25%;
    padding-right: 0;
    padding: 10px;

    @media screen and (min-width: 1025px) and (max-width: 1480px) {
      width: 35%;
    }
  }
}

.Age-Select {
  padding: 15px 10px;
}

.About-Me,
.Personal-Info,
.Tip {
  min-width: calc(100% / 3);
}

.About-Me {
  @media screen and (min-width: 1025px) and (max-width: 1200px) {
    min-width: calc(100% / 2);
  }
}

.LocationSwitch,
.OnlineStatusSwitch {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  margin-top: 10px;

  label {
    margin: 0;
  }
}

.ant-switch {
  max-width: 50px;
}

.ant-switch-checked {
  background-color: $main-color !important;
  max-width: 50px;
}

.ToggleSpan {
  max-width: 200px;
  margin-left: 10px;
}
