@import '../../variables';
@import '../../mixins';

.LocationModalNew {
  .ant-modal-content {
    border-radius: 15px;

    .ant-modal-body {
      padding: 0;

      @media screen and (max-width: 768px) {
        padding: 0;
      }
    }
  }

  .ModalContent {
    display: flex;

    .MapModalContainer {
      position: relative;
      flex: 1;

      .MapImage {
        width: 263px;
        height: 535px;
        border-bottom-left-radius: 8px;
        border-top-left-radius: 8px;
        display: block;

        @include respond(phone) {
          display: none;
        }
      }

      .MapImageMobile {
        display: none;
        border-top-right-radius: 8px;
        border-top-left-radius: 8px;

        @include respond(phone) {
          display: flex;
          width: 100%;
          margin: auto;
          position: relative;
        }
      }
    }

    .DataModalContainer {
      display: flex;
      flex: 3;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;

      @media screen and (max-width: 768px) {
        padding: 10px;
      }

      .LogoModalContainer {
        .LogoImage {
          height: 30px;
        }
      }

      .TitleModalContainer {
        color: #212121;
        font-family: Raleway, sans-serif;
        font-size: 40px;
        font-weight: 700;
        line-height: 44px;
        // margin-bottom: 20px;
        // margin-top: 60px;
        text-align: center;

        @media screen and (max-width: 768px) {
          font-size: 25px;
        }
      }

      .TextModalContainer {
        color: #666;
        font-family: Open Sans, sans-serif;
        font-size: 16px;
        font-weight: 400;
        text-align: center;

        @media screen and (max-width: 768px) {
          font-size: 12px;
        }
      }

      .InputModalContainer {
        position: relative;
        width: 70%;
        text-align: center;

        .CitySingleContainer {
          @include respond(phone) {
            margin-top: 20px;
          }

          .CityHolder {
            display: flex;
            align-items: center;
            font-size: 16px;
            border-radius: 8px;
            text-align: left;
            background: #fff;
            padding-left: 40px;
            height: 56px;
            border: 1px solid #d8d8d8;
            color: #898787;
            @include respond(phone) {
              font-size: 14px;
              height: 45px;
            }
          }
        }

        .CityContainer {
          display: flex;
          justify-content: space-between;
          margin-top: 20px;
          // align-items: baseline;

          @include respond(phone) {
            flex-direction: column;
          }

          @include respond(tablet) {
            margin-top: 0px;
          }

          @media only screen and (max-width: 1130px) and (min-width: 721px) {
            flex-direction: column;
          }

          .ant-input {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            border-right: none;
            text-align: left;
            background: #fff;
            height: 56px;

            &:focus,
            &:active,
            &:hover {
              box-shadow: none;
              border-color: #d8d8d8;
            }

            @include respond(phone) {
              padding-left: 10px;
              border-radius: 8px;
              border-right: 1px solid #d8d8d8;
            }

            @media only screen and (max-width: 1130px) and (min-width: 721px) {
              border-radius: 8px;
              border-right: 1px solid #d8d8d8;
              margin-top: 0px;
            }
          }
          .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
            .ant-select-selector {
            box-shadow: none;
            border-color: #d8d8d8;
          }

          .ant-select {
            .ant-select-selector {
              display: flex;
              align-items: center;
              border-top-left-radius: 0;
              border-bottom-left-radius: 0;
              border-left: none;
              background: #fff;
              height: 56px;

              &:focus,
              &:active,
              &:hover {
                box-shadow: none;
                border-color: #d8d8d8;
              }

              @media screen and (max-width: 768px) {
                height: 30px;
              }
            }

            @include respond(phone) {
              border-radius: 8px;
              border-left: 1px solid #d8d8d8;
            }

            @media only screen and (max-width: 1130px) and (min-width: 721px) {
              border-radius: 8px;
              border-left: 1px solid #d8d8d8;
              margin-top: 0px;
            }
          }

          input {
            flex: 1;
          }

          select {
            flex: 1;
            font-size: 14px;
            background-color: #ececec;
          }
        }

        .ErrorMessage {
          position: absolute;
          color: red;
          text-align: left;
        }
      }

      .ButtonModalContainer {
        @media screen and (max-width: 768px) {
          margin-top: 25px;
        }
      }
    }
  }
}
