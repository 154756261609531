@import '../../../../../../../../variables.scss';
@import '../../../../../../../../mixins.scss';

.ChatContainer {
  margin-bottom: 23px !important;
}

.LanderContent .LanderContentInner {
  background-image: url('../../../../../../../../assets/images/Lander10/matches-bg.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position-y: bottom;
  background-position-x: 75%;
}

.MatchesText {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 144.4%;
  color: #5f2022;
  text-align: center;
  margin-bottom: 0;
}

.avatar-container {
  display: flex;
  align-items: center;
  justify-content: center;

  @include respond(phone) {
    scale: 0.6;
  }

  @include respond(extraSmallPhone) {
    scale: 0.45;
  }
}

.avatar {
  border-radius: 50%;
  position: relative;
}

.center-avatar {
  z-index: 5;
  width: 170px;
}

.left-avatar {
  position: absolute;
}

.left-avatar:nth-child(2) {
  transform: translateX(-65%);
  width: 130px;
  z-index: 4;
}

.left-avatar:nth-child(3) {
  transform: translateX(-140%);
  width: 110px;
  z-index: 3;
}

.left-avatar:nth-child(4) {
  transform: translateX(-235%);
  width: 90px;
  z-index: 2;
}

.right-avatar {
  position: absolute;
}

.right-avatar:nth-child(5) {
  transform: translateX(65%);
  width: 130px;
  z-index: 4;
}

.right-avatar:nth-child(6) {
  transform: translateX(140%);
  width: 110px;
  z-index: 3;
}

.right-avatar:nth-child(7) {
  transform: translateX(235%);
  width: 90px;
  z-index: 2;
}
