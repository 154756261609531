@import '../../../../../../../../variables.scss';
@import '../../../../../../../../mixins.scss';

.LanderContainer17 {
  &.version17-1 {
    .Button {
      background: #8a3736;

      &:hover {
        color: #fff !important;
        border-color: #c31449 !important;
        background-color: #c31449 !important;
      }

      &:disabled {
        &:hover {
          background: #8a3736 !important;
        }
      }
    }
  }

  &.version17-2 {
    .Button {
      background: #8b4888;

      &:hover {
        color: #fff !important;
        border-color: #ed20a7 !important;
        background-color: #ed20a7 !important;
      }

      &:disabled {
        &:hover {
          background: #8b4888 !important;
        }
      }
    }
  }

  &.version17-3 {
    .Button {
      background: #304f8b;

      &:hover {
        color: #fff !important;
        border-color: #115ced !important;
        background-color: #115ced !important;
      }

      &:disabled {
        &:hover {
          background: #304f8b !important;
        }
      }
    }
  }

  &.version17-4 {
    .Button {
      background: #ac713c;

      &:hover {
        color: #fff !important;
        border-color: #f85d05 !important;
        background-color: #f85d05 !important;
      }

      &:disabled {
        &:hover {
          background: #ac713c !important;
        }
      }
    }
  }

  .Button {
    font-weight: 600;
    font-size: 20px;
    line-height: 130%;
    padding: 30px 35px;
    border-radius: 4px;

    @include respond(phone) {
      font-size: 17px;
      padding: 30px;
      letter-spacing: 2px;
      white-space: pre-line;
    }

    &:disabled {
      opacity: 0.5;
      border: none;
      cursor: not-allowed;

      &:hover {
        color: #fff !important;
        border: none;
      }
    }
  }
}
