@import '../../../mixins.scss';

.payment-experiment-modal {
  .payment-experiment-modal-continue-chatting-v2-text-highlight {
    color: #d41411;
  }

  .ant-modal-content {
    @include respond(phone) {
      .ant-select-selector {
        height: auto;
      }
      .ant-select-selection-item {
        white-space: normal;
      }
    }
    font-family: 'Raleway', sans-serif;
    h2 {
      font-style: normal;
      font-weight: 700;
      font-size: 22px;
      line-height: 132%;
      color: #393939;
      text-align: center;
    }
    .payment-experiment-modal-description-wrapper {
      cursor: pointer;
      font-size: 18px;
      line-height: 132%;
      font-weight: 700;
      .payment-experiment-modal-description-wrapper-text {
        text-decoration: underline;
        color: #9f0220;
      }
    }
    button.payment-experiment-modal-button {
      width: 100%;
      max-width: 300px;
      font-size: 16px;
      line-height: 20px;
      color: #ffffff;
      background: #9f0220;
      text-transform: uppercase;
      border-radius: 8px;
      padding: 20px 35px;
      cursor: pointer;
      font-weight: bold;
      border: none;
      transition: background 0.3s ease;
      &:hover {
        background: #d41411;
      }
    }
  }
}
.loading-overlay {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 20px 0;
  background: rgba(255, 255, 255, 0.9);
  z-index: 100;
  border-radius: 20px;
  backdrop-filter: blur(2px);

  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 20.23px;
    text-align: center;
  }
}

@include respond(phone) {
  .payment-experiment-modal-select-dropdown {
    .ant-select-item-option-content {
      white-space: normal;
    }
  }
}
