@import '../../../../../../../variables.scss';
@import '../../../../../../../mixins.scss';

.HorizontalOptionDisabled {
  cursor: default !important;

  &:hover .BackgroundOverlay {
    opacity: 1 !important;
  }
}

.HorizontalOption {
  width: 507px;
  height: 116px;
  cursor: pointer;
  background: rgba(49, 12, 14, 0.7);
  border-radius: 9px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  @include respond(smallPhone) {
    height: 90px;
  }

  @include respond(extraSmallPhone) {
    height: 70px;
  }

  @include respond(phone) {
    width: 100% !important;
  }

  &:not(:last-child) {
    margin-bottom: 7px;
  }

  &:hover .BackgroundOverlay {
    opacity: 0;
  }

  .BackgroundOverlay {
    border-radius: 9px;
    background: rgba(49, 12, 14, 0.7);
  }

  span {
    z-index: 1;
    position: relative;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: 0.2em;
    color: #ffffff;
    text-transform: uppercase;
    text-align: center;
    text-shadow: 0px 4px 4px rgba(69, 33, 33, 0.58);

    @include respond(phone) {
      font-size: 16px;
    }

    @include respond(extraSmallPhone) {
      font-size: 14px;
    }
  }
}
