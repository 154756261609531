@import '../../../../mixins.scss';
@import '../../../../variables.scss';

.funnel {
  min-height: 100vh;
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url('../../../../assets/images/FunnelPage/bg.png');
  background-size: cover;
  background-position: center;
  font-family: 'Playfair Display', serif;
  .container {
    max-width: 1170px;
    margin: 0 auto;
    padding: 50px 0 68px 0;
    @media only screen and (max-width: 1200px) {
      padding: 50px 20px 68px 20px;
    }
    @media only screen and (max-width: 991px) {
      max-width: 652px;
    }

    .title {
      color: #ffffff;
      text-align: center;
      font-size: 40px;
      line-height: 53.32px;
      font-weight: 600;
      margin: 50px 0 70px 0;
      @media only screen and (max-width: 1200px) {
        font-size: 35px;
      }
      @media only screen and (max-width: 991px) {
        margin: 20px 23px 30px 23px;
        font-size: 30px;
        line-height: 40px;
      }
    }

    .content {
      display: flex;
      gap: 50px;
      margin-bottom: 70px;
      @media only screen and (max-width: 1200px) {
        gap: 30px;
      }
      @media only screen and (max-width: 991px) {
        flex-direction: column;
        margin-bottom: 30px;
      }

      .profiles {
        flex: 1;
        display: flex;
        align-items: flex-end;
        position: relative;
        .third-layer {
          position: absolute;
          width: 85.27%;
          height: 100%;
          left: 50%;
          transform: translateX(-50%);
          background-color: #ffffff;
          opacity: 0.2;
          border-radius: 10px;
          @media only screen and (max-width: 991px) {
            height: 105%;
          }
        }
        .second-layer {
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          letter-spacing: 0;
          background-color: #ffffff;
          opacity: 0.4;
          width: 93.55%;
          height: 97.5%;
          z-index: 4;
          border-radius: 10px;
          @media only screen and (max-width: 991px) {
            height: 102.5%;
          }
        }
        .images-container {
          width: 100%;
          height: 94.5%;
          border-radius: 10px;
          overflow: hidden;
          z-index: 5;
          display: flex;

          .image {
            width: 50%;
            max-height: 446px;
            position: relative;
            @media only screen and (max-width: 480px) {
              max-height: 204px;
            }
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
            .text {
              position: absolute;
              left: 0;
              bottom: 0;
              right: 0;
              padding: 20px;
              display: flex;
              justify-content: center;
              @media only screen and (max-width: 991px) {
                padding: 8px;
              }
              p {
                font-family: 'Gothic A1', sans-serif;
                background-color: rgba(0, 0, 0, 0.6);
                border-radius: 10px;
                padding: 10px 15px;
                font-weight: 600;
                font-size: 20px;
                line-height: 25px;
                color: #ffffff;
                margin: 0;
                width: fit-content;
                text-align: center;
                @media only screen and (max-width: 1200px) {
                  font-size: 16px;
                }
                @media only screen and (max-width: 991px) {
                  padding: 6px 9px;
                  font-size: 12px;
                  line-height: 15px;
                }
              }
            }
          }
        }
      }
      .form {
        background-color: #ffffff;
        border-radius: 10px;
        font-family: 'Gothic A1', sans-serif;
        height: 469px;
        @media only screen and (max-width: 991px) {
          height: auto;
        }
        .form-title {
          font-family: 'Playfair Display', serif;
          color: #9f0220;
          background: linear-gradient(0deg, #961a1a -313.33%, rgba(150, 26, 26, 0) 100%);
          text-align: center;
          padding: 40px 45px;
          margin: 0;
          font-weight: 600;
          font-size: 30px;
          line-height: 40px;
          @media only screen and (max-width: 1200px) {
            font-size: 35px;
            padding: 25px 40px;
            font-size: 27px;
          }
          @media only screen and (max-width: 991px) {
            font-size: 22px;
            line-height: 29px;
            padding: 10px;
          }
        }
        .gender {
          position: relative;
          padding: 27px 50px 50px 50px;
          @media only screen and (max-width: 1200px) {
            padding: 27px 40px 40px 40px;
          }
          @media only screen and (max-width: 991px) {
            padding: 10px 20px 20px 20px;
          }
          p {
            margin: 0;
            text-align: center;

            font-size: 20px;
            line-height: 25px;
            color: #000000;
            font-weight: 600;
            margin-bottom: 33px;
            @media only screen and (max-width: 480px) {
              padding: 0 30px;
            }
          }
          .gender-select {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 8px;

            .placeholder {
              display: flex;
              flex-direction: column;
              align-items: center;
              cursor: pointer;
              padding: 27px;
              border-radius: 10px;
              gap: 14px;
              @media only screen and (max-width: 1200px) {
                width: 142px;
              }
              @media only screen and (max-width: 480px) {
                padding: 18px;
              }
              &:active {
                transform: scale(0.99);
              }
              .placeholder-img {
                width: 100%;
              }
              span {
                font-weight: 600;
                font-size: 16px;
                line-height: 20px;
                text-align: center;
                text-transform: uppercase;
              }
            }
            .fill {
              background-color: #9f0220;
              border: 3px solid transparent;
              .placeholder-img {
                filter: invert(100%) sepia(6%) saturate(0%) hue-rotate(115deg) brightness(108%)
                  contrast(108%);
              }
              color: #fff;
            }
            .outline {
              border: 3px solid #000000;
            }
          }
        }
        .zipcode {
          position: relative;
          padding: 27px 50px 50px 50px;

          @media only screen and (max-width: 1200px) {
            padding: 27px 40px 40px 40px;
          }
          @media only screen and (max-width: 991px) {
            padding: 10px 20px 20px 30px;
          }
          h2 {
            text-align: center;
            font-size: 20px;
            font-weight: 600;
            line-height: 25px;
            margin: 0;
            margin-bottom: 8px;
            @media only screen and (max-width: 480px) {
              padding: 0 30px;
            }
          }
          p {
            text-align: center;
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            margin-bottom: 35px;
            color: #000;
            @media only screen and (max-width: 720px) {
              margin-bottom: 10px;
            }
          }
          .Input {
            height: 50px;
            &::placeholder {
              color: #000;
              font-size: 14px;
              line-height: 17px;
            }
          }
          .Button {
            height: 60px;

            &.Disabled {
              background: #a25665;
              pointer-events: none;
            }
            p {
              color: #fff;
              margin: 0;
            }
          }
        }
        .user-pass {
          position: relative;
          padding: 27px 50px 50px 50px;
          @media only screen and (max-width: 1200px) {
            padding: 27px 40px 40px 40px;
          }
          @media only screen and (max-width: 991px) {
            padding: 10px 20px 20px 30px;
          }
          h2 {
            font-weight: 600;
            font-size: 20px;
            line-height: 25px;
            text-align: center;
            color: #000000;
            margin-bottom: 33px;
            @media only screen and (max-width: 480px) {
              padding: 0 30px;
            }
          }
          .Input {
            height: 50px;
            &::placeholder {
              color: #000;
              font-size: 14px;
              line-height: 17px;
            }
          }
          .Button {
            height: 60px;
            p {
              margin: 0;
            }
          }
        }
        .birthday {
          position: relative;
          padding: 60px 50px 50px 50px;
          @media only screen and (max-width: 720px) {
            padding: 43px 20px 20px 30px;
          }
          p {
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            text-align: center;
            color: #000;
          }
          .DatePickerField {
            .react-datepicker-popper {
              z-index: 9;
            }

            input {
              height: 50px !important;
            }
            .ImageComponent {
              width: 30px;
              height: 30px;
            }
          }
          .Button {
            height: 60px;
            p {
              color: #fff;
              margin: 0;
            }
          }
          .react-date-picker {
            width: 100%;
          }

          .Example {
            left: 180px;
            @include respond(tablet) {
              left: 383px;
            }
            @include respond(phone) {
              left: 360px;
            }
            @include respond(smallPhone) {
              top: 13px;
              left: 175px;
              font-size: 10px;
            }
          }
        }
        .email {
          position: relative;
          padding: 27px 50px 50px 50px;
          @media only screen and (max-width: 1200px) {
            padding: 27px 40px 40px 40px;
          }
          @media only screen and (max-width: 991px) {
            padding: 10px 20px 20px 30px;
          }
          h2 {
            font-weight: 600;
            font-size: 20px;
            line-height: 25px;
            text-align: center;
            margin-bottom: 8px;
            @media only screen and (max-width: 480px) {
              padding: 0 30px;
            }
          }
          p {
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            text-align: center;
            max-width: 350px;
            margin-bottom: 20px;
            color: #000;
          }
          .Input {
            height: 50px;
            &::placeholder {
              color: #000;
              font-size: 14px;
              line-height: 17px;
            }
          }

          .TextContainer {
            text-align: center;
          }

          .GoogleLoginContainer {
            width: 100%;
            margin-top: 5px;
            margin-bottom: 10px;

            button {
              width: 100%;
              text-align: center;
              justify-content: center;
              border-radius: 8px !important;
              height: 60px;
            }
          }

          .Button {
            height: 60px;

            &.DisabledButton {
              pointer-events: none;
              background: #a25665;
            }
            p {
              color: #fff;
              margin: 0;
            }
          }
        }
      }
    }
    .icons {
      display: flex;
      justify-content: space-evenly;
      gap: 30px;
      .icon-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        gap: 30px;
        .icon-box {
          width: 100%;
          height: 100%;
          max-height: 120px;
          @media only screen and (max-width: 1200px) {
            max-height: 100px;
          }
          @media only screen and (max-width: 720px) {
            max-height: 40px;
          }
          img {
            width: 100%;
            height: 100%;
          }
        }
        p {
          color: #fff;
          margin: 0;
          font-weight: 500;
          font-size: 26px;
          line-height: 35px;
          text-align: center;
          @media only screen and (max-width: 1200px) {
            font-size: 20px;
          }
          @media only screen and (max-width: 720px) {
            font-size: 16px;
            line-height: 21px;
          }
        }
      }
    }
  }
  .arrow-back {
    position: absolute;
    left: 0;
    top: 0;
    padding: 15px 30px;
    text-align: center;
    cursor: pointer;
  }
}
