@import 'src/variables';
.no-result,
.grid-container {
  width: auto;
  max-width: 1200px;
  margin: 20px auto;
  height: 100%;
  .container {
    max-width: 100%;
  }
}
.grid-container {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
}

.loader {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.title {
  text-align: center;
  margin: 50px;
  line-height: 24px;
  font-family: 'Raleway', serif;
  font-weight: 700;
  font-size: 30px;
  color: $credits-dark-text;
}

.pagination {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 40px auto 0;
}
/*@media only screen and (min-width: 1025px) {
  .grid-container {
    grid-template-columns: repeat(auto-fill, minmax(200px, 4fr)); //repeat(4, 1fr);
  }
}
@media only screen and (max-width: 1204px) {
  .grid-container {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media only screen and (max-width: 768px) {
  .grid-container {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media only screen and (max-width: 450px) {
  .grid-container {
    grid-template-columns: repeat(1, 1fr);
  }
}*/
