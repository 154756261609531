@import '../../../variables.scss';
@import '../../../mixins.scss';

.NotEnoughCreditsModalContainer {
  .CloseButton {
    width: 22px;
    height: 22px;
    z-index: 999;
  }
}
.NotEnoughCredits {
  font-family: 'Roboto';

  .ImageContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
    margin-top: 35px;
    height: auto;

    .ImageComponent {
      border-radius: 100%;
      overflow: hidden;
      width: 104px;
      height: 104px;

      img {
        width: 100%;
        height: inherit;
      }
    }
  }

  .TitleContainer {
    display: flex;
    text-align: center;
    line-height: 34px;
    font-size: 30px;
    font-weight: 700;
    font-family: 'Roboto';
    color: #514f4f;
    width: 80%;
    margin: auto;
    margin-bottom: 30px;
    margin-top: 10px;

    @include respond(phone) {
      font-size: 20px;
      line-height: 22px;
    }

    .ImageComponent {
      width: fit-content;
      display: block;
    }
  }

  .SubtitleContainer {
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    margin: auto;
    margin-bottom: 30px;
    width: 90%;
    font-family: 'Roboto';
    line-height: 24px;
    color: #6d6d75;
  }

  .ButtonContainer {
    margin-bottom: 20px;

    .SubmitButton {
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
      width: 95%;
      height: 56px;
      margin: auto;

      p {
        display: flex;
        align-items: center;
        font-family: 'Roboto';

        @include respond(phone) {
          font-size: 9px;
        }

        .CartImage {
          @include respond(phone) {
            width: 20px;
          }

          img {
            @include respond(phone) {
              width: 100%;
            }
          }
        }

        span {
          margin-left: 5px;
          margin-right: 5px;
          font-size: 22px;

          @include respond(phone) {
            font-size: 12px;
          }
        }

        .ImageComponent {
          width: fit-content;
          display: block;
          margin-right: 10px;

          @include respond(phone) {
            width: 20px;
            margin-right: 5px;
          }

          img {
            @include respond(phone) {
              width: 100%;
            }
          }
        }
      }
    }
  }

  .SelectBundleContainer {
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 20px;
    color: #44444f;

    span {
      color: $main-color;
      font-weight: 700;
      text-decoration: underline;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .UserPhoto {
    justify-content: flex-start;
  }
}
