@import '../../../../../../variables.scss';
@import '../../../../../../mixins.scss';

.LanderContainer17 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  position: relative;
  background-size: cover;

  @include respond(phone) {
    overflow: hidden;
  }

  &.version17-1 {
    font-family: 'Montserrat', sans-serif;
    background-image: url('../../../../../../assets/images/Lander17/red/bg.png');

    @include respond(tablet) {
      background-image: url('../../../../../../assets/images/Lander17/red/bg-sm.png');
    }

    @include respond(phone) {
      background-image: url('../../../../../../assets/images/Lander17/red/bg-sm.png');
    }

    .LoadingOverlay {
      background: #212121c2;
    }

    .BackgroundOverlay17 {
      background-image: url('../../../../../../assets/images/Lander17/red/overlay.png');
    }
  }

  &.version17-2 {
    font-family: 'Noto Sans', sans-serif;
    background-image: url('../../../../../../assets/images/Lander17/purple/bg.png');

    @include respond(tablet) {
      background-image: url('../../../../../../assets/images/Lander17/purple/bg-sm.png');
    }

    @include respond(phone) {
      background-image: url('../../../../../../assets/images/Lander17/purple/bg-sm.png');
    }

    .LoadingOverlay {
      background: #1b0724c2;
    }

    .BackgroundOverlay17 {
      background-image: url('../../../../../../assets/images/Lander17/purple/overlay.png');
    }
  }

  &.version17-3 {
    font-family: 'Raleway', sans-serif;
    background-image: url('../../../../../../assets/images/Lander17/blue/bg.png');

    @include respond(tablet) {
      background-image: url('../../../../../../assets/images/Lander17/blue/bg-sm.png');
    }

    @include respond(phone) {
      background-image: url('../../../../../../assets/images/Lander17/blue/bg-sm.png');
    }

    .LoadingOverlay {
      background: #07093ac2;
    }

    .BackgroundOverlay17 {
      background-image: url('../../../../../../assets/images/Lander17/blue/overlay.png');
    }
  }

  &.version17-4 {
    font-family: 'Quicksand', sans-serif;
    background-image: url('../../../../../../assets/images/Lander17/orange/bg.png');

    @include respond(tablet) {
      background-image: url('../../../../../../assets/images/Lander17/orange/bg-sm.png');
    }

    @include respond(phone) {
      background-image: url('../../../../../../assets/images/Lander17/orange/bg-sm.png');
    }

    .LoadingOverlay {
      background: #1f1103c2;
    }

    .BackgroundOverlay17 {
      background-image: url('../../../../../../assets/images/Lander17/orange/overlay.png');
    }
  }

  @media (max-width: 1020px) and (orientation: 'landscape') {
    padding: 2.5em;
  }

  .BackgroundOverlay17 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
  }

  .LoadingOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      animation: rotate 2s infinite linear;

      @include respond(phone) {
        height: 40px;
      }
    }
  }

  .LanderContent {
    z-index: 1;
    width: 783px;

    @include respond(tablet) {
      width: 80%;
    }

    @include respond(phone) {
      width: 100%;
    }

    @include respond(extraSmallPhone) {
      width: 120%;
    }

    .LanderContentInner17 {
      box-sizing: border-box;
      border: none;
      padding: 69px 84px;

      @media only screen and (max-width: 420px) and (min-width: 320px) {
        scale: 0.9;
      }

      @include respond(extraSmallPhone) {
        scale: 0.8;
      }

      @media (max-width: 1020px) and (orientation: 'landscape') {
        scale: 0.6;
      }

      @media (max-width: 700px) and (max-height: 300px) and (orientation: 'landscape') {
        scale: 0.4;
      }

      &.version17-1 {
        background: rgba(81, 13, 13, 0.94);
        box-shadow: 13px 13px 22px 8px rgba(0, 0, 0, 0.54);
      }

      &.version17-2 {
        background: rgba(90, 13, 91, 0.94);
        box-shadow: 13px 13px 22px 8px rgba(0, 0, 0, 0.54);
      }

      &.version17-3 {
        background: rgba(19, 27, 91, 0.94);
        box-shadow: 13px 13px 22px 8px rgba(38, 20, 20, 0.18);
      }

      &.version17-4 {
        background: rgba(126, 69, 17, 0.94);
        box-shadow: 13px 13px 22px 8px rgba(36, 76, 113, 0.18);
      }

      @include respond(phone) {
        margin: auto 3%;
        padding: 60px 20px;
      }
    }
  }
}
