@import "src/mixins";

.modal-body {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  padding: 4rem 1rem;
  @include respond(phone) {
    padding: 39px 20px!important;
  }
  .avatar-icon {
    margin: auto;
  }
  h3{
    font-size: 1.8rem;
    width: 100%;
    color: #121212;
    font-weight: 500;
  }
  p{
    font-size: 1rem;
    line-height: 1.1rem;
  }
}
