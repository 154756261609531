@import '../../../../../../variables.scss';
@import '../../../../../../mixins.scss';

.LanderContainer14 {
  display: flex;
  font-family: 'Raleway';
  height: 100vh;
  background-color: #1e0404;

  @media (max-width: 1020px) and (orientation: 'landscape') {
    height: auto;
  }

  .SelectOptions {
    justify-content: start;
  }

  &.Version14-1 {
    .VerticalOption {
      background-position-y: 20%;
    }

    .Interests .VerticalOption {
      background-position-y: 30%;
    }

    .Age .VerticalOption {
      background-position-y: 5%;
    }
  }
  &.Version14-2 {
    background-color: #0c0f18;

    .LanderContent > :first-child {
      background-image: url('../../../../../../assets/images/Lander14/blue/bg.png');

      @include respond(tablet) {
        background-image: url('../../../../../../assets/images/Lander14/blue/bg-sm.png');
      }

      @include respond(phone) {
        background-image: url('../../../../../../assets/images/Lander14/blue/bg-sm.png');
      }

      span {
        color: #7899bf;
      }
    }

    .Message {
      background: #5422ac;

      &::after {
        background-image: url('../../../../../../assets/images/Lander14/blue/arrow.png');
      }
    }

    .VerticalOption .BackgroundOverlay {
      border-radius: 9px;
      background: #09204680;
    }

    .VerticalOption.VerticalOptionActive {
      border: 2px solid #5422ac;
    }

    .VerticalOption {
      background-position-y: 20%;
    }

    .Interests .VerticalOption {
      background-position-y: 30%;
    }

    .Age .VerticalOption {
      background-position-y: 20%;
    }

    .Body .VerticalOption {
      background-position-y: 35%;
    }

    .RoundButton {
      background: #1e2ca8;

      &:hover {
        background: #ffffff !important;
        color: #1e2ca8 !important;
        border-color: #1e2ca8 !important;
        box-shadow: 0px 1px 14px rgba(30, 44, 168, 0.6);
      }

      &:disabled {
        border: 1px solid #1e2ca8;
        color: #1e2ca8;
        opacity: 0.5;

        &:hover,
        &:focus,
        &:active {
          border: 1px solid #1e2ca8;
          color: #1e2ca8;
          opacity: 0.5;
        }
      }
    }

    .ProgressBar {
      background: #1d3d5b30 !important;
    }

    .ProgressBar {
      background: #1d3d5b30 !important;

      .Percentage {
        background: #486c91 !important;

        @include respond(phone) {
          background: #1e2ca8 !important;
        }

        span {
          color: #60829b !important;
        }
      }
    }

    .ProtectionOptionButton {
      background-color: #17244a;
      color: white;
      border: 2px solid transparent;

      &Active {
        border-color: #5422ac;
      }
    }

    .InputContainer {
      .ant-tag {
        background-color: #d41411;
        color: #ffffff;
      }

      .ant-input,
      input {
        background: #17244a;
        border: 1px solid #3d4869;
        color: #fff;

        &::placeholder {
          color: #3b4564;
        }

        &:hover {
          border-color: #3d4869 !important;
        }

        &:focus {
          border-color: #3d4869 !important;
          box-shadow: 0 0 0 2px #3d486920 !important;
        }
      }
    }
  }

  &.Version14-3 {
    background-color: #191617;

    .LanderContent > :first-child {
      background-image: url('../../../../../../assets/images/Lander14/pink/bg.png');

      @include respond(tablet) {
        background-image: url('../../../../../../assets/images/Lander14/pink/bg-sm.png');
      }

      @include respond(phone) {
        background-image: url('../../../../../../assets/images/Lander14/pink/bg-sm.png');
      }

      span {
        color: #d91d77;
      }
    }

    .Message {
      background: #b70f5b;

      &::after {
        background-image: url('../../../../../../assets/images/Lander14/pink/arrow.png');
      }
    }

    .VerticalOption .BackgroundOverlay {
      border-radius: 9px;
      background: #2d252880;
    }

    .VerticalOption.VerticalOptionActive {
      border: 2px solid #b70f5b;
    }

    .VerticalOption {
      background-position-y: 20%;
    }

    .Interests .VerticalOption {
      background-position-y: 30%;
    }

    .Age .VerticalOption {
      background-position-y: 0;
    }

    .RoundButton {
      background: #522238;

      &:hover {
        background: #ffffff !important;
        color: #522238 !important;
        border-color: #522238 !important;
        box-shadow: 0px 1px 14px rgba(82, 34, 56, 0.6);
      }

      &:disabled {
        border: 1px solid #522238;
        color: #522238;
        opacity: 0.5;

        &:hover,
        &:focus,
        &:active {
          border: 1px solid #522238;
          color: #522238;
          opacity: 0.5;
        }
      }
    }

    .ProgressBar {
      background: #1d3d5b30 !important;
    }

    .ProgressBar {
      background: #31272b !important;

      .Percentage {
        background: #d91d77 !important;

        @include respond(phone) {
          background: #d91d77 !important;
        }

        span {
          color: #7a3b53 !important;
        }
      }
    }

    .ProtectionOptionButton {
      background-color: #35292d;
      color: white;
      border: 2px solid transparent;

      &Active {
        border-color: #b70f5b;
      }
    }

    .InputContainer {
      .ant-tag {
        background-color: #d41411;
        color: #ffffff;
      }

      .ant-input,
      input {
        background: #35292d;
        border: 1px solid #855968;
        color: #fff;

        &::placeholder {
          color: #62414c;
        }

        &:hover {
          border-color: #855968 !important;
        }

        &:focus {
          border-color: #855968 !important;
          box-shadow: 0 0 0 2px #85596820 !important;
        }
      }
    }
  }

  &.Version14-4 {
    background-color: #fff;

    .LanderContent > :first-child {
      background-image: url('../../../../../../assets/images/Lander14/light/bg.png');

      @include respond(tablet) {
        background-image: url('../../../../../../assets/images/Lander14/light/bg-sm.png');
      }

      @include respond(phone) {
        background-image: url('../../../../../../assets/images/Lander14/light/bg-sm.png');
      }

      span {
        color: #fff;
      }
    }

    .Message {
      background: #d84252;

      &::after {
        background-image: url('../../../../../../assets/images/Lander14/light/arrow.png');
      }
    }

    .VerticalOption .BackgroundOverlay {
      border-radius: 9px;
      background: #3b363780;
    }

    .VerticalOption.VerticalOptionActive {
      border: 2px solid #d84252;
    }

    .VerticalOption {
      background-position-y: 20%;
    }

    .Interests .VerticalOption {
      background-position-y: 30%;
    }

    .Age .VerticalOption {
      background-position-y: 0;
    }

    .RoundButton {
      background: #ead8db;
      color: #d84252;

      &:hover {
        background: #ffffff !important;
        color: #d84252 !important;
        border-color: #d84252 !important;
        box-shadow: 0px 1px 14px rgba(82, 34, 56, 0.6);
      }

      &:disabled {
        border: 1px solid #ead8db;
        color: #ead8db;
        opacity: 0.5;

        &:hover,
        &:focus,
        &:active {
          border: 1px solid #ead8db;
          color: #ead8db;
          opacity: 0.5;
        }
      }
    }

    .ProgressBar {
      background: #ead8db !important;
    }

    .ProgressBar {
      background: #ead8db !important;

      .Percentage {
        background: #d84252 !important;

        @include respond(phone) {
          background: #d84252 !important;
        }

        span {
          color: #7a3b53 !important;
        }
      }
    }

    .ProtectionOptionButton {
      background-color: #e6e4e4;
      color: #181818;
      border: 2px solid transparent;

      &Active {
        border-color: #d84252;
      }
    }

    .InputContainer {
      .ant-input,
      input {
        background: #e6e4e4;
        border: 1px solid #c6c6c6;
        color: #181818;

        &::placeholder {
          color: #c4c4c4;
        }

        &:hover {
          border-color: #c6c6c6 !important;
        }

        &:focus {
          border-color: #c6c6c6 !important;
          box-shadow: 0 0 0 2px #c6c6c620 !important;
        }
      }
    }
  }

  .LoadingOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    background: rgba(0, 0, 0, 0.9);
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      animation: rotate 2s infinite linear;
      width: 115px;

      @include respond(phone) {
        height: 40px;
        width: 40px;
      }
    }
  }

  .LanderContent {
    z-index: 1;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    width: 100%;

    & > :first-child {
      width: 30%;
      background-image: url('../../../../../../assets/images/Lander14/red/bg.png');
      background-size: cover;
      background-repeat: no-repeat;
      background-position: right;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;
      padding: 50px 0;

      @include respond(tablet) {
        position: absolute;
        background-size: cover;
        height: 100vh;
        width: 100vw;
      }

      @include respond(phone) {
        position: absolute;
        background-size: cover;
        height: 100vh;
        width: 100vw;
      }

      @include respond(tablet) {
        background-image: url('../../../../../../assets/images/Lander14/red/bg-sm.png');
      }

      @include respond(phone) {
        background-image: url('../../../../../../assets/images/Lander14/red/bg-sm.png');
      }

      @include respond(extraSmallPhone) {
        padding: 0;
        padding-bottom: 20px;
      }

      img {
        height: 35px;
        margin-right: 28%;

        @include respond(tablet) {
          margin-right: 0;
        }

        @include respond(phone) {
          visibility: hidden;
        }
      }

      span {
        font-family: 'Syne', sans-serif;
        font-size: 20px;
        color: #c55757;

        @include respond(phone) {
          font-size: 16px;
        }

        @include respond(extraSmallPhone) {
          font-size: 13px;
        }

        @include respond(smallPhone) {
          font-size: 13px;
        }
      }
    }

    & > :not(:first-child) {
      flex: 1;
      padding: 59px 54px;
      padding-left: 10%;
      display: flex;
      flex-direction: column;
      justify-content: center;

      @include respond(phone) {
        flex-direction: column-reverse;
        padding: 0px 29px;
        justify-content: flex-end;
        height: 100vh;
      }

      @include respond(smallPhone) {
        scale: 0.8;
        padding: 0;
      }

      @include respond(extraSmallPhone) {
        scale: 0.75;
        padding: 0;
      }
    }

    .ProgressBar {
      height: 5px;
      max-width: 553px;
      background: rgba(222, 190, 190, 0.4);
      border-radius: 22px;
      margin-bottom: 29px;
      position: relative;

      @include respond(phone) {
        width: 90%;
        margin-left: 4%;
        margin-right: 4%;
      }

      .Percentage {
        height: 100%;
        background: #b05658;
        border-radius: 22px;

        @include respond(phone) {
          background: #9f0220;
          height: 5px !important;
        }

        span {
          font-family: 'Syne', sans-serif;
          position: absolute;
          bottom: -12px;
          left: auto;
          right: -29%;
          transform: translate(-50%, -50%);
          color: #9c616d;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 16px;

          @include respond(phone) {
            left: 50%;
            right: auto;
            bottom: 10px;
          }
        }
      }
    }

    .LanderContentInner14 {
      box-sizing: border-box;
      margin-bottom: 50px;
      width: 60%;
      height: 75%;

      @include respond(desktop) {
        width: 90%;
      }

      @include respond(tablet) {
        width: 80%;
        height: 60%;
      }

      @include respond(phone) {
        width: 100%;
      }
    }
  }

  .VerticalOption {
    max-width: 224px;

    @include respond(phone) {
      max-width: 100%;
      width: 100%;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    }
  }

  .SelectOptions {
    gap: 10px;

    @include respond(phone) {
      flex-direction: column;
    }
  }

  .Button {
    margin-top: 40px;
    max-width: 500px;
  }

  .Message {
    font-family: 'Syne', sans-serif;
    border-radius: 20px;
    background: #9f0220;
    max-width: 480px;
    font-size: 18px;
    padding: 14px 25px;

    @include respond(phone) {
      font-size: 16px;
      border-radius: 30px;
      padding: 14px 20px;
    }

    &::after {
      border-right-color: transparent !important;
      background-image: url('../../../../../../assets/images/Lander14/red/arrow.png');
      background-repeat: no-repeat;
      right: 99% !important;
      border-width: 15px !important;
      margin-top: -15px !important;
    }
  }

  .AvatarContainer {
    margin-right: 5px;
    z-index: 10;
  }

  .ToggleElement {
    opacity: 0;
    animation: fade-in 0.1s ease-in-out forwards;
    display: flex;
    gap: 10px;
  }

  .Hidden {
    opacity: 0;
    display: flex;
  }

  .MessageContainer {
    margin-bottom: 20px;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
