@import '../../../../../../../../variables.scss';
@import '../../../../../../../../mixins.scss';

.CountdownButton {
  font-family: 'Open Sans' !important;
  background: #d41411 !important;
  font-weight: 700 !important;
  font-size: 18px !important;
  line-height: 25px !important;
  padding: 23px 35px !important;
  letter-spacing: 0;

  &:hover {
    color: #d41411 !important;
    border-color: #d41411 !important;
  }

  @include respond(phone) {
    font-size: 15px !important;
    padding: 18px !important;
  }
}
