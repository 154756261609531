@import '../../../../variables';
@import '../../../../mixins.scss';

.EmailVerificationInstruction {
  height: 100%;
  overflow: auto;

  .EmailVerification-HeaderContainer {
    position: relative;
    height: 450px;

    @include respond(phone) {
      height: 490px;
    }

    .BasicHeader {
      padding-top: 40px;
    }

    .EmailVerification-HeaderImage {
      position: absolute;
      height: 100%;
      width: 100%;
      z-index: -1;

      img {
        object-fit: cover;
        height: 100%;
        width: 100%;
        @include respond(phone) {
          object-position: -475px;
        }
      }
      &::before {
        height: 100%;
        width: 100%;
        position: absolute;
        z-index: 0;
        background-color: red;
        display: block;
        content: '';
        background: linear-gradient(180deg, #000000 0%, #c41826 100%);
        mix-blend-mode: multiply;
        opacity: 0.9;
      }
    }

    .Header-Text {
      height: 500px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      @include respond(phone) {
        height: 100%;
        width: 80%;
        margin: auto;
      }

      .EmailVerification-Header {
        font-family: 'Raleway', sans-serif;
        color: $white;
        padding-top: 50px;
        font-weight: 700;
        font-size: 54px;
        line-height: 68px;
        text-align: center;
        margin-bottom: 20px;

        @include respond(phone) {
          font-size: 34px;
          line-height: 40px;
          padding-top: 90px;
          margin-bottom: 15px;
          text-align: center;
          width: 100%;
        }
      }

      .EmailVerification-Description {
        max-width: 896px;
        color: $white;
        font-family: 'Open Sans', sans-serif;
        font-weight: 400;
        font-size: 18px;
        line-height: 30px;
        text-align: center;

        @include respond(phone) {
          max-width: 100%;
          font-size: 18px;
          margin: 0;
          padding: 0;
        }

        span {
          font-weight: 600;
        }
      }

      .CheckYourEmailButton-Container {
        margin: 30px 0;

        .CheckYourEmailButton {
          font-size: 16px;
          line-height: 20px;
          color: #fff;
          background: #9f0220;
          text-transform: uppercase;
          border-radius: 8px;
          padding: 20px 35px;
          cursor: pointer;
          font-family: 'Raleway', sans-serif;
          font-weight: 700;
          font-style: normal;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        @include respond(phone) {
          margin: 5px;
        }

        p {
          word-spacing: 3px;
        }
      }
    }
  }

  .EmailVerification-Container {
    .HowToVerify {
      .HowToVerify-Container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 50px;

        .ImageContainer {
          flex: 1;
          position: relative;
          height: 360px;
          width: 582px;
          overflow: hidden;

          .EmailVerification-ImageDevice {
            position: absolute;
            left: 53px;
            top: 0;
          }

          .EmailVerification-Image {
            position: absolute;
            left: 0px;
            top: 0px;
            border-top-left-radius: 10px;
            overflow: hidden;

            img {
              width: 100%;
            }
          }
        }

        .InfoContainer {
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: center;

          .InfoTitle {
            width: 80%;
            margin-left: auto;
            font-family: 'Raleway;', sans-serif;
            font-size: 24px;
            line-height: 44px;
            font-weight: 700;
            margin-bottom: 20px;
          }

          .InfoDescription {
            width: 80%;
            margin-left: auto;
            color: #717171;
            font-family: 'Roboto', sans-serif;
            margin-bottom: 20px;
            font-weight: 400;
            font-size: 16px;
          }
        }

        .ImageContainer {
          flex: 1;
          position: relative;
          height: 360px;
          width: 582px;
          overflow: hidden;

          .EmailVerification-ImageDevice {
            position: absolute;
            left: 53px;
            top: 0;
          }

          .EmailVerification-Image {
            position: absolute;
            left: 0px;
            top: 0px;
            border-top-left-radius: 30px;
            overflow: hidden;

            img {
              width: 100%;
            }
          }
        }
      }
    }

    .HaventReceivedEmail {
      background-color: #f9f9f9;

      .HaventReceivedEmail-Container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 40px;

        .InfoContainer {
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: center;

          .InfoTitle {
            width: 80%;
            font-family: 'Raleway;', sans-serif;
            font-size: 24px;
            line-height: 44px;
            font-weight: 700;
            margin-bottom: 20px;
          }

          .InfoDescription {
            width: 80%;
            color: #717171;
            font-family: 'Roboto', sans-serif;
            margin-bottom: 20px;
            font-weight: 400;
            font-size: 16px;
          }
        }
      }

      .ImageContainer {
        flex: 1;
        position: relative;
        height: 360px;
        width: 582px;
        overflow: hidden;

        .EmailVerification-ImageDevice {
          position: absolute;
          left: 53px;
          top: 0;
        }

        .EmailVerification-Image {
          position: absolute;
          left: 0px;
          top: 0px;
          border-top-left-radius: 30px;
          overflow: hidden;

          img {
            width: 100%;
          }
        }
      }
    }

    .StillHaventReceivedEmail-Container {
      background-color: #fffef9;
      button.HelpVerifyButton {
        max-width: 500px;
        margin-bottom: 30px;
      }
      .StillHaventReceivedEmail {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 70px 0;
        .ChatToggleButton {
          max-width: 500px;
          margin-bottom: 30px;
        }

        @include respond(phone) {
          width: 90%;
          padding: 50px 0;
        }
        .StillHaventReceivedEmailTitle {
          font-family: 'Raleway;', sans-serif;
          font-size: 24px;
          line-height: 44px;
          font-weight: 700;
          margin-bottom: 20px;
        }

        .StillHaventReceivedEmailDescription {
          width: 60%;
          text-align: center;
          color: #717171;
          font-family: 'Roboto', sans-serif;
          margin-bottom: 30px;
          font-weight: 400;
          font-size: 16px;

          @include respond(phone) {
            width: 85%;
            font-weight: 400;
            text-align: center;
            font-family: 'Roboto';
            line-height: 150%;
          }

          .Support-Text {
            color: $main-color;
            cursor: pointer;
            font-weight: 800;
            text-decoration: none;
          }
        }
        .StillHaventReceivedEmailSMS {
          display: none; // TODO: temporary hidden
          text-align: center;
          color: #717171;
          font-family: 'Roboto', sans-serif;
          font-weight: 400;
          font-size: 16px;
          margin-bottom: 30px;
          .SendEmailAgainButton {
            width: auto;
          }
          .SendEmailAgainForm {
            justify-content: center;
          }
        }
        .SendEmailAgainLoaderContainer {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .CircularProgressbar .CircularProgressbar-text {
            font-family: 'Roboto', sans-serif;
          }
          .SendEmailAgainLoader {
            width: 65px;
            height: 65px;
            margin-bottom: 10px;
          }
          .SendEmailAgainLoaderText {
            font-family: 'Roboto', sans-serif;
            color: #717171;
            width: 100%;
            max-width: 480px;
            text-align: center;
            span {
              font-weight: 800;
            }
          }
        }
      }
    }

    .SendEmailAgain {
      .SendEmailAgainForm {
        display: flex;
        align-items: flex-start;
        width: 100%;
        margin: auto;

        .SendEmailAgainInput {
          padding: 16px 32px 16px 32px;
          border: 1px solid $basic-input-border;
          box-sizing: border-box;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px;
          outline: none;
          width: 350px;
          height: 56px;

          @include respond(phone) {
            &::placeholder {
              font-size: 16px;
              line-height: 150%;
              color: $placeholder-text;
            }
          }
        }

        .SendEmailAgainButton {
          box-sizing: border-box;
          border-radius: 0;
          border-top-right-radius: 8px;
          border-bottom-right-radius: 8px;
          outline: none;
          background-color: #9f0220;
          color: #fff;
          text-transform: uppercase;
          line-height: 1;
          height: 56px;

          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }

  @media (max-width: 767px) {
    .EmailVerification-HeaderContainer {
      .Text {
        text-align: center;

        > div {
          font-size: 34px;
        }

        .EmailVerification-Description {
          font-size: 12px;
        }
      }

      .CheckYourEmailButton-Container {
        .EmailVerification-Description {
          margin-bottom: 30px;
        }
        .Button {
          font-size: 12px;
          padding: 10px 35px;
        }
      }
    }

    .EmailVerification-Container {
      .HowToVerify {
        background-color: #fffef9;
        .HowToVerify-Container {
          flex-direction: column;
          padding: 30px;

          .ImageContainer {
            flex: none;
            margin-bottom: 30px;
            width: 100%;

            .EmailVerification-ImageDevice {
              left: 0;
            }
          }

          .InfoContainer {
            flex: none;

            .InfoTitle {
              width: 100%;
              text-align: center;
            }

            .InfoDescription {
              width: 100%;
              text-align: center;
              font-family: 'Roboto';
              line-height: 150%;
              font-weight: 400;
              font-size: 16px;
            }
          }
        }
      }

      .HaventReceivedEmail {
        .HaventReceivedEmail-Container {
          flex-direction: column-reverse;
          padding: 30px;

          .ImageContainer {
            flex: none;
            margin-bottom: 30px;
            width: 100%;

            .EmailVerification-ImageDevice {
              left: 0;
            }
          }

          .InfoContainer {
            flex: none;

            .InfoTitle {
              width: 100%;
              text-align: center;
            }

            .InfoDescription {
              width: 100%;
              text-align: center;
              font-family: 'Roboto';
              line-height: 150%;
            }
          }
        }
      }

      .SendEmailAgain {
        padding: 30px;

        @include respond(phone) {
          padding: 0;
        }

        .SendEmailAgainForm {
          height: 56px;
          .InputField {
            .SendEmailAgainInput {
              width: 290px;
              @include respond(phone) {
                width: 235px;
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 374px) {
    .EmailVerification-HeaderContainer {
      height: 485px;
      .Header-Text {
        text-align: center;
        > div {
          font-size: 34px;
        }

        .EmailVerification-Description {
          font-size: 12px;
        }
      }

      .CheckYourEmailButton-Container {
        .Button {
          font-size: 12px;
          padding: 10px 35px;
        }
      }
    }

    .EmailVerification-Container {
      .HowToVerify {
        .HowToVerify-Container {
          flex-direction: column;
          padding: 30px;

          .ImageContainer {
            flex: none;
            margin-bottom: 30px;
            width: 100%;

            .EmailVerification-ImageDevice {
              left: 0;
            }
          }

          .InfoContainer {
            flex: none;

            .InfoTitle {
              width: 100%;
              text-align: center;
            }

            .InfoDescription {
              width: 100%;
              text-align: center;
            }
          }
        }
      }

      .HaventReceivedEmail {
        .HaventReceivedEmail-Container {
          flex-direction: column-reverse;
          padding: 30px;

          .ImageContainer {
            flex: none;
            margin-bottom: 30px;
            width: 100%;

            .EmailVerification-ImageDevice {
              left: 0;
            }
          }

          .InfoContainer {
            flex: none;

            .InfoTitle {
              width: 100%;
              text-align: center;
            }

            .InfoDescription {
              width: 100%;
              text-align: center;
            }
          }
        }
      }

      .SendEmailAgain {
        padding: 30px;

        .SendEmailAgainForm {
          .InputField {
            .SendEmailAgainInput {
              width: 180px;
            }
          }
          .SendEmailAgainButton {
            padding: 15px 29px 16px 29px;
          }
        }
      }
    }
  }
}
