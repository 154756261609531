.ant-image-preview-mask {
  background-color: rgba(0, 0, 0, 0.8);
}
.ImageComponent {
  width: 100%;
  height: 100%;

  .SkeletonContainer {
    width: 100%;
    height: 100%;
  }
}
