@import '../../../../../variables';
@import '../../../../../mixins';

.Pricing {
  color: $pricing-page-text;
}
.Description {
  @include respond(phone) {
    width: 272px;
    line-height: 30px;
  }
}

.Pricing-Container {
  padding-top: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  text-align: center;

  @include respond(phone) {
    max-width: 90%;
    padding-top: 20px;
  }

  @include respond(tablet) {
    max-width: 85%;
    padding-top: 50px;
  }

  .Pricing-Content {
    // display: flex;
    // flex-direction: column;
    // justify-content: center;
    margin: auto;
    padding: 0 100px;
    max-width: 60%;
    font-family: 'Roboto';

    @include respond(tablet) {
      padding: 0;
    }

    @include respond(phone) {
      padding: 0;
      max-width: 100%;
      margin: 0;
    }
  }

  .pricing-page {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    flex-wrap: wrap;
    margin: 50px 0;
  }
}

.Credits-Info {
  margin: 25px 0;
}

.Credit-Messages {
  @include respond(phone) {
    font-family: 'Open Sans';
    font-weight: 700;
    font-size: 20px;
    color: #000000;
  }
}

.Credits-InfoText {
  @include respond(phone) {
    font-family: 'Open Sans';
    font-weight: 700;
    font-size: 10px;
    line-height: 15px;
    text-align: center;
    color: #000000;
  }
}

.Pricing-Table {
  margin-bottom: 200px;
  display: flex;
  overflow: hidden;
  width: 60%;

  @include respond(phone) {
    margin-bottom: 50px;
    width: 100%;
  }

  @include respond(tablet) {
    margin-bottom: 70px;
    width: 80%;
  }
}

.PricingBundles-List {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 25px;
  width: 50%;
  margin: auto;

  @include respond(phone) {
    width: 100%;
    gap: 15px;
  }

  @include respond(tablet) {
    width: 100%;
  }
}

.Pricing-Bundle {
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  // min-width: 80%;
  // max-width: 80%;
  width: 100%;
  justify-content: center;
  margin: auto;
  overflow: hidden;

  @include respond(phone) {
    overflow: hidden;
  }
}

.Pricing-CreditName {
  background-color: $pricing-light-background;
  color: #000;
  font-family: 'Open Sans';
  font-size: 28px;
  font-weight: 700;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}

.Pricing-PricePerMsg {
  background: #000000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 7px;
}

.Saving-Price {
  font-family: 'Open Sans';
  font-weight: 700;
  font-size: 28px;
  text-align: center;
  color: $credits-light-text;

  @include respond(phone) {
    font-size: 20.8533px;
    line-height: 21px;
    text-align: center;
  }
}

.PricePerMessage {
  font-family: 'Open Sans';
  font-weight: 700;
  font-size: 16px;
  text-align: center;
  color: $credits-light-text;

  @include respond(phone) {
    font-size: 10px;
    line-height: 15px;
    padding: 0;
    margin: 0;
    margin-top: 5px;
  }
}

.Pricing-Btn {
  background-color: $credits-red-btn;
  color: $credits-light-text;
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  cursor: pointer;
}

.Para-Data {
  padding: 0;
  margin: 0;
}

.Credit-Message {
  font-family: 'Open Sans';
  font-weight: 700p;
  font-size: 28px;
  line-height: 28px;
  text-align: center;
  color: $credits-light-text;

  @include respond(phone) {
    font-size: 20.8533px;
    line-height: 21px;
    font-weight: 700p;
  }
}

.BuyNow-Btn {
  font-family: 'Open Sans';
  font-weight: 700;
  font-size: 16px;
  text-align: center;
  color: $credits-light-text;

  @include respond(phone) {
    font-weight: 700;
    font-size: 10px;
    line-height: 15px;
  }
}

.Pricing-PricePerMsg {
  width: 45%;

  @include respond(phone) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5px;
  }
}

.Pricing-CreditName,
.Pricing-Btn {
  width: 30%;
}

.Pricing-CreditName,
.Pricing-PricePerMsg,
.Pricing-Btn {
  @include respond(phone) {
    padding: 10px 5px;
  }
}
