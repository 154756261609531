@import '../../../../../../../../variables.scss';
@import '../../../../../../../../mixins.scss';

.LanderContainer17 {
  &.version17-1 {
    .WarningImage {
      background-image: url('../../../../../../../../assets/images/Lander17/red/warning.gif');
    }

    .WarningText {
      span {
        color: #fe1414;
      }
    }
  }

  &.version17-2 {
    .WarningImage {
      background-image: url('../../../../../../../../assets/images/Lander17/purple/warning.gif');
    }

    .WarningText {
      span {
        color: #ed20a7;
      }
    }
  }

  &.version17-3 {
    .WarningImage {
      width: 300px;
      background-size: cover !important;
      background-position: center !important;
      background-image: url('../../../../../../../../assets/images/Lander17/blue/warning.gif');
    }

    .WarningText {
      span {
        color: #115ced;
      }
    }
  }

  &.version17-4 {
    .WarningImage {
      width: 300px !important;
      height: 250px !important;
      background-size: cover !important;
      background-position: center !important;
      background-image: url('../../../../../../../../assets/images/Lander17/orange/warning.gif');
    }

    .WarningText {
      span {
        color: #e46934;
      }
    }
  }

  .WarningContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .WarningImage {
      height: 288px;
      width: 258px;
      background-size: contain;
    }

    .WarningText {
      color: #fff;
      text-align: center;
      font-size: 24px;
      line-height: 33px;
      padding: 35px 0;
      margin-bottom: 0;

      @include respond(phone) {
        font-size: 19px;
      }
    }
  }
}
