.App {
  height: 100%;
  position: relative;

  > div {
    height: inherit;
    // overflow: hidden;
  }

  .SuspenseLoader {
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-weight: bold;
  }

  @media screen and (orientation: landscape) {
    height: 100%;
  }

  .Container {
    padding-right: 0px;
    padding-left: 0px;
    margin-right: auto;
    margin-left: auto;

    @media (min-width: 768px) {
      // width: 750px;
    }
    @media (min-width: 992px) {
      width: 970px;
    }
    @media (min-width: 1200px) {
      width: 1170px;
    }
  }

  .ContentWrapper {
    height: inherit;
  }
  iframe {
    #document {
      display: none;
    }
  }
}
