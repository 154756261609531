@import '../../../../../../../../variables.scss';
@import '../../../../../../../../mixins.scss';

.WarningDescription {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 141.4%;
  color: #130505;

  @include respond(phone) {
    width: 100%;
    font-size: 16px;
  }

  @include respond(extraSmallPhone) {
    font-size: 14px;
  }
}

.HorizontalOption .BackgroundOverlay {
  background: rgba(95, 32, 34, 0.83) !important;
}

.HorizontalOption {
  margin-bottom: 23px !important;
}

.WarningOptions {
  display: flex;
  justify-content: space-between;
  gap: 8px;

  @include respond(phone) {
    flex-direction: column;
  }
}

.WarningButton {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 136.4%;
  letter-spacing: 0.185em;
  background: #393939;
  border-radius: 9px;
  padding: 22px 29px;
  align-items: center;
  display: flex;
  border-color: #393939;
  justify-content: center;

  @include respond(phone) {
    font-size: 16px;
    padding: 18px;
  }

  @include respond(extraSmallPhone) {
    font-size: 14px;
  }

  &:hover {
    background-color: #ffffff !important;
    color: #393939 !important;
    border-color: #393939;
  }
}

.Danger {
  background: #9f0220;
  border-color: #9f0220;

  &:hover {
    background: #ffffff;
    color: #9f0220 !important;
    border-color: #9f0220;
  }
}
