@import '../../../../../../../mixins.scss';

.ListOfConversations {
  background-color: white;
  width: 300px;

  @media screen and (max-width: 500px) {
    width: 100%;
    position: fixed;
  }

  &.MobileClosed {
    @media screen and (max-width: 500px) {
      display: none;
    }
  }

  .TabsComponent {
    height: 100%;
    .Tabs {
      // border: 1px solid black;
      height: 100%;
    }
    .Tab-Content {
      height: calc(100vh - 275px);
    }
  }
}

.ListOfConversations {
  background-color: white;

  &.MobileClosed {
    @media screen and (max-width: 500px) {
      display: none;
    }
  }

  @media screen and (min-width: 1025px) and (max-width: 1200px) {
    width: 230px;
    // border: 1px solid red;
  }
  @media screen and (min-width: 1200px) and (max-width: 1300px) {
    width: 250px;
    // border: 1px solid blue;
  }
  @media screen and (min-width: 1300px) {
    width: 300px;
    // border: 1px solid green;
  }

  @media screen and (max-width: 1024px) {
    width: 170px;
    height: calc(100vh - 80px);
  }

  @media screen and (min-width: 901px) and (max-width: 1024px) {
    width: 250px;
    height: calc(100vh - 80px);
  }

  @media screen and (min-width: 500px) and (max-width: 900px) {
    width: 140px;
    height: calc(100vh - 50px);
  }

  @media screen and (max-width: 500px) {
    width: 100%;
  }

  .TabsComponent {
    height: 100%;

    .Tabs {
      height: 100%;
      width: 100%;
    }
    @media screen and (max-width: 1024px) {
      height: calc(100vh - 80px - 75px);
    }

    @media screen and (min-width: 500px) and (max-width: 900px) {
      height: calc(100vh - 50px - 75px);
    }
    .Tab-Content {
      height: calc(100vh - 275px);

      @media screen and (max-width: 1024px) {
        height: 100%;
      }
    }
  }
}
