.ImageComponent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .imageloader {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
      max-width: 100%;
      max-height: 100%;
      height: auto;
    }

    .ImageComponent-notFound {
      font-size: 1.2rem;
      font-weight: bold;
    }
  }
}
