@import 'src/assets/css/colors';
@import "src/mixins";
.benefits-wrapper {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  text-align: center;
  flex-wrap: wrap;
}
.pricing-info {
  font-family: 'Open Sans', serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  max-width: 50%;
  margin: 70px 0 90px 0;
  span {
    color: darken($main, 10%);
  }
}
.pricing-header {
  font-family: 'Raleway', serif;
  font-weight: 700;
  font-size: 30px;
  margin-bottom: 120px;
  text-align: center;
  line-height: 1em;
  @include respond(phone) {
    margin-bottom: 40px;
  }

  span {
    color: darken($main, 10%);
  }
}
.subscriptions {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
  flex-wrap: wrap;
}
