@import '../../../../../../../../variables.scss';
@import '../../../../../../../../mixins.scss';

.LanderContainer17 {
  &.version17-1 {
    .BodyTypeImage {
      background-image: url('../../../../../../../../assets/images/Lander17/red/body-type.gif');
    }
  }

  &.version17-2 {
    .BodyTypeImage {
      background-image: url('../../../../../../../../assets/images/Lander17/purple/body-type.gif');
    }

    .BodyTypeText {
      @include respond(phone) {
        padding: 0 20px;
      }
    }
  }

  &.version17-3 {
    .BodyTypeImage {
      background-size: 120% !important;
      background-position: center !important;
      background-image: url('../../../../../../../../assets/images/Lander17/blue/body-type.gif');
    }

    .BodyTypeText {
      @include respond(phone) {
        padding: 0 20px;
      }
    }
  }

  &.version17-4 {
    .BodyTypeImage {
      background-image: url('../../../../../../../../assets/images/Lander17/orange/body-type.gif');
    }

    .BodyTypeText {
      @include respond(phone) {
        padding: 0 20px;
      }
    }
  }

  .BodyTypeContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .BodyTypeImage {
      height: 245px;
      width: 245px;
      background-size: cover;
    }

    .Button:not(:last-of-type) {
      margin-bottom: 10px;
    }

    .BodyTypeText {
      color: #fff;
      text-align: center;
      font-size: 24px;
      margin: 35px 0;

      @include respond(phone) {
        font-size: 19px;
      }

      span {
        color: #fe1414;
      }
    }
  }
}
