@import '../../../../../../../variables.scss';
@import '../../../../../../../mixins.scss';

.VerticalOption {
  width: 250px;
  height: 288px;
  cursor: pointer;
  background: rgba(49, 12, 14, 0.7);
  border-radius: 9px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  &:not(:last-child) {
    margin-right: 7px;
  }

  &:hover .BackgroundOverlay {
    opacity: 0;
  }

  .BackgroundOverlay {
    border-radius: 9px;
    background: rgba(49, 12, 14, 0.7);
  }

  span {
    z-index: 1;
    position: relative;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: 0.2em;
    color: #ffffff;
    text-transform: uppercase;
    text-align: center;
    width: 80%;
    text-shadow: 0px 4px 4px rgba(69, 33, 33, 0.58);
  }
}
