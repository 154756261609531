@import '../../../../../../../../variables.scss';
@import '../../../../../../../../mixins.scss';

.LanderContainer14 {
  .ProtectionOptions {
    margin-top: 100px;
    gap: 10px;

    @include respond(phone) {
      margin-top: 50px;
    }
  }

  .ProtectionOptionButton {
    text-transform: uppercase;
    background-color: #310c0e;
    color: white;
    border: 2px solid transparent;
    text-align: center;
    width: 240px;
    font-size: 18px;
    letter-spacing: 0.8px;
    padding: 13px 35px;
    border-radius: 58px;
    cursor: pointer;

    @include respond(phone) {
      width: 100%;
    }

    &Active {
      border-color: #9f0220;
      font-size: 18px;
    }
  }
}
