.ReceivedMessageContainer {
  .ReceivedMessage {
    display: flex;
    justify-content: flex-start;
    text-align: left;
    background: #fff;
    margin: 10px 30px;
    border: 1px solid #e9edf0;
    border-radius: 8px 8px 8px 0px;
    padding: 20px;
    max-width: 50%;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.04);
    padding-bottom: 5px;
    font-family: 'Open Sans';

    @media screen and (min-width: 500px) and (max-width: 900px) {
      padding: 5px;
    }

    .Message {
      font-size: 16px;
      color: #5e6c84;
      line-height: 24px;
      height: fit-content;

      @media screen and (min-width: 901px) and (max-width: 1024px) {
        font-size: 14px;
        line-height: 20px;
      }

      @media screen and (min-width: 500px) and (max-width: 900px) {
        font-size: 12px;
        line-height: 16px;
      }
    }
  }

  .ReceivedTime {
    display: flex;
    justify-content: flex-start;
    color: #5e6c84;
    font-size: 11px;

    @media screen and (min-width: 500px) and (max-width: 900px) {
      font-size: 8px;
    }
  }
}
