@import '../../../../../../variables.scss';
@import '../../../../../../mixins.scss';

.Lander9 {
  min-height: 100vh;
  background-position: center;
  background-size: cover;

  .imgWrapper {
    overflow: hidden;
    position: absolute;
    top: 0;
    z-index: -1;

    .home-img {
      max-width: 100%;
    }
    .image {
      display: none;
    }
    .imgWrapper {
      width: 100vw;
      height: 100vh;
    }
    @keyframes blender {
      0% {
        opacity: 0.5;
      }
      30% {
        opacity: 1;
      }
      70% {
        opacity: 1;
      }
      100% {
        opacity: 0.5;
      }
    }
    .imageActive {
      float: left;
      width: 100vw;
      height: 100vh;
      object-fit: cover;
      box-sizing: border-box;
      opacity: 0.5;
      animation: blender 5s linear 50ms;
    }
  }

  .LanderHeader {
    background-color: $main-color-dark;
    padding: 20px;
  }

  .StepsContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: calc(100vh - 85px);

    form {
      @include respond(phone) {
        width: 100%;
      }
    }

    .StepContainer {
      position: relative;
      min-width: 600px;
      background-color: #0a0202;
      color: #e7e7e7;
      text-align: center;
      padding: 30px 60px;
      border: 5px solid #e7e7e7;
      border-radius: 9px;
      box-shadow: 0px 17px 22px 7px #6d5f5f8f;

      @include respond(phone) {
        min-width: 90%;
        max-width: 90%;
        margin: auto;
      }

      .StepTitle {
        color: #dc9493;
        font-size: 23px;
        margin-bottom: 30px;
      }

      .StepSubTitle {
        font-size: 23px;
        margin-bottom: 30px;
      }

      .StepText {
        margin-bottom: 20px;
      }

      .ButtonsContianer {
        display: flex;
        justify-content: space-between;

        .Button {
          width: 40%;
          margin: auto;
        }
      }
    }

    .InputsContainer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 10px;
      width: 100%;
      margin-top: 30px;

      .InputContainer {
        position: relative;
        margin-bottom: 20px;
        width: 100%;

        .ant-tag {
          position: absolute;
          left: 0px;
          top: -25px;
          background-color: $main-color;
          border-color: $main-color;
          color: $white;
          border-radius: 5px;
        }

        .ant-select {
          width: 100%;

          .ant-select-selector {
            padding: 5px 10px;
            width: 100%;
            border-radius: 9px;
            height: auto;
            text-align: left;
          }
        }

        input {
          padding: 9px 10px;
          width: 100%;
          border-radius: 9px;
          height: auto;
          text-align: left;
          color: rgba(0, 0, 0, 0.88);
        }
      }
    }

    .CheckInfoContainer {
      text-align: left;
      margin-bottom: 30px;
      padding: 0 50px;

      @include respond(phone) {
        padding: 0;
      }

      .ant-tag {
        background-color: $main-color;
        border-color: $main-color;
        color: $white;
        border-radius: 5px;
        white-space: pre-wrap;
        padding: 10px;
        text-align: center;
        margin: auto;
      }

      .ant-checkbox-wrapper {
        &:hover {
          .ant-checkbox-inner {
            border-color: $main-color;
          }

          .ant-checkbox-checked {
            &:after {
              border-color: $main-color;
            }
          }
          .ant-checkbox-checked .ant-checkbox-inner {
            border-color: $main-color;
          }
        }

        .ant-checkbox {
          &:hover {
            border-color: $main-color;

            .ant-checkbox-inner {
              border-color: $main-color;
            }
          }
          &:focus {
            border-color: $main-color;
          }

          &.ant-checkbox-checked {
            &:hover {
              .ant-checkbox-inner {
                border-color: $main-color;
              }
            }
          }
        }

        .ant-checkbox-checked {
          .ant-checkbox-inner {
            background-color: $white;
            border-color: $white;

            &::after {
              border-color: $main-color;
            }
            &:hover {
              border-color: $main-color;
            }
            &:focus {
              border-color: $main-color;
            }
          }
        }
      }

      p {
        span {
          color: #dc9493;
        }
      }
    }
  }
}
