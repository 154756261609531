@import '../../variables';
@import '../../mixins.scss';
.ChangePassword {
  color: #000;
  padding: 20px 20px 40px 20px;

  .InputField {
    margin-bottom: 10px;

    .Input {
      height: 50px;
    }
  }

  .Loading {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background: #0000006e;
    top: 0;
    left: 0;
    z-index: 999;
    border-radius: 8px;
  }

  .Title {
    @include modalTitle;
  }

  .Subtitle {
    @include modaSubtitle;
  }
}
