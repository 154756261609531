@import 'src/assets/css/colors';
@import 'src/assets/css/fonts';

.card-credit {
  position: relative;
  height: fit-content;

  ≈ .jost-font {
  }

  .smallFont {
    font-family: 'Open Sans', serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
    margin-top: 20px;
  }

  .old-label {
    text-decoration: line-through;
    text-decoration-color: red;
    margin-bottom: 10px;
  }

  .card-wrapper {
    position: relative;
    text-align: center;
    cursor: pointer;
    font-family: 'Open Sans', sans-serif;
    border-radius: 23px;
    border: 1px solid #d8d5d5;
    color: #0a0202;
    min-width: 163px;
    width: 200px;
    margin: auto;

    .card-header {
      font-family: 'Raleway', serif;
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 15px;
      text-transform: uppercase;
      margin: 11px;
    }

    .card-amount-old {
      text-decoration: line-through;
      font-size: 40px;
      text-decoration-color: #9f0220;
    }

    .card-amount {
      font-size: 60px;
      font-weight: 600;
      line-height: 80.4px;
    }

    .card-subtext {
      padding-bottom: 10px;
      border-bottom: 1px solid #c1c1c1;
      text-transform: uppercase;
      font-weight: 400;
      font-size: 14px;
      line-height: 20.23px;
      font-family: 'Jost', sans-serif;
      margin: 0 10px 10px;
    }

    .card-price {
      color: #818181;
      font-weight: 700;
      font-size: 14px;
      line-height: 20.23px;
      font-family: 'Jost', sans-serif;
    }

    .card-discount {
      font-family: 'Jost', sans-serif;
      font-size: 20px;
      line-height: 24px;
      font-weight: 600;
      color: #2b0507;
      padding-bottom: 11px;
    }

    .card-buy-now {
      background-color: $main;
      height: 55px;
      padding: 8px 0;
      border-radius: 0 0 23px 23px;

      .buy-title {
        text-transform: uppercase;
        font-weight: 700;
        line-height: 21.13px;
        color: white;
      }

      .buy-price {
        font-family: 'Open Sans', sans-serif;
        font-weight: 700;
        font-size: 14px;
        line-height: 19.07px;
        color: #ca9c9e;
      }
    }

    .card-icon-container {
      position: absolute;
      display: flex;
      flex-direction: column;
      bottom: 0;
      gap: 10px;
      top: -20px;
      right: -25px;
      overflow: visible;
      z-index: 2;
    }
  }

  .overlay {
    background: rgba($bg-color, 0.6);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 23px;
  }

  .special-header {
    padding-left: 25px;
    font-family: 'Open Sans', serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 24px;
    text-align: center;
    color: #ffffff;
    background: #0a0202;
    border-radius: 7px;
    position: absolute;
    z-index: 1;
    margin: auto;
    width: 90%;
    left: 0;
    right: 0;
    top: -15px;

    .icon {
      height: 34px;
      width: 34px;
      position: absolute;
      left: -5px;
      top: -21%;
    }
  }

  .dark {
    background-color: #160f0f;

    .card-subtext {
      color: #a4a4a4;
    }

    .card-discount {
      color: #fff;
    }
  }

  @media only screen and (max-width: 600px) {
    .card-wrapper {
      min-width: 225px * 0.7;
    }
  }

  .buy-title-sm {
    display: none;
  }
}
