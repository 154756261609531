@import '../../../../../variables';
@import '../../../../../mixins';

.Non-Consensual-Participation {
  .Non-Consensual-Participation-Container {
    width: 60%;
    display: flex;
    flex-direction: column;
    margin: auto;
    padding: 100px 0;
    color: $pricing-page-text;

    h3 {
      font-weight: 600;
    }

    @include respond(phone) {
      width: 85%;
      padding: 50px 0;
    }

    @include respond(tablet) {
      width: 90%;
      padding: 40px 0;
    }

    // ul {
    //   // list-style-type: none;
    //   padding: 0;
    //   margin: 0;

    //   p {
    //     padding: 0;
    //     margin: 0;
    //   }
    // }
  }
}

.redirectLink {
  cursor: pointer;
  color: $main-color;

  &:hover {
    text-decoration: underline;
  }
}

.Underlined {
  text-decoration: underline;
}
