@import '../../../../../../../../variables.scss';
@import '../../../../../../../../mixins.scss';

.LanderContainer18 {

  .Button {
    font-weight: 600;
    font-size: 20px;
    line-height: 130%;
    padding: 30px 35px;
    border-radius: 50px;
    background-color: #FF0050;

    @include respond(phone) {
      font-size: 17px;
      padding: 30px;
      letter-spacing: 2px;
      white-space: pre-line;
    }

    &:hover {
      color: #101010 !important;
      border-color: #DFD4D7 !important;
      background-color: #DFD4D7;
    }

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
      background-color: #DFD4D7;

      &:hover {
        color: #101010 !important;
        background-color: #DFD4D7;
      }
    }
  }
}
