@import './colors';
@mixin username {
  color: $text-dark-blue;
  font-size: 16px;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
}
@mixin usernameConv {
  color: $text-dark-blue;
  font-size: 18px;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
}
