$white: #ffffff;
$main-color: #9f0220;
$main-color-dark: #5f2022;
$small-text-color: white;
$grey-text-color: rgba(102, 102, 102, 0.49);
$disabled-color: #c4c4c4;
$active-button-color: #71081c;
$disabled-button-color: #d8cfd0;
$disabled-button-text-color: #beabab;
$gold: #ffb91f;

//Basic Input
$basic-input-border: #d8d8d8;

//Button
$button-color: #9f0220;

//ModalDialog
$modal-background: #1b1b1b;

//Header
$header-background: #681a20;

//Footer
$footer-text-color: #b8b8b8;
$copyright-footer-background: #9f0220;
$copyright-footer-text: #ffffff;

//Home Page
$home-page-description-background: #fffef9;
$home-page-profiles-background: #1e0732;
$text-red: #d41411;

//Why Choose Flirt
$why-choose-flirt-background: #fffef9;
$why-choose-flirt-text: #b8b8b8;

//Contact Page
$contact-us-text: #bbbbbb;
$contact-us-header-text: #717171;
$input-border: #d8d8d8;
$placeholder-text: #999999;
$cancel-subscription-text: #393939;

//Pricing Page
$pricing-table-gray-background: rgba(59, 22, 88, 0.05);
$pricing-table-gray-border: #dadada;
$pricing-page-text: #717171;
//new design for credit pricing
$credits-light-grey: #f9f6f5;
$credits-red-btn: #d41411;
$credits-dark-text: #253858;
$credits-light-text: #fffef9;
//new design for pricing page
$pricing-light-background: #f0dbd4;

//Faq Page
$faq-question-red: #d41411;

//Members Pricing Page
$pricing-normal-text: #253858;
$pricing-golden-text: #ffc95f;
$pricing-golden: #efb025;
$pricing-purple-background: #29024b;
$pricing-gray-background: #d6d6d6;
$pricing-light-gray-background: #f1f2f4;
$pricing-info-text: #5e6c84;
$pricing-dark-purple-text: #29024b;
//new design
$pricing-card-background: #f9f6f5;
$pricing-card-background-popular: #000000;
$pricing-card-name: #253858;
$pricing-name-golden: #c3a405;
$pricing-frequency-popular: #fffef9;
$most-popular-red: #d41411;
$badge-green: #6ac85b;
//Authorisation Page
$terms-page-header-text: #393939;
//Password Reset
$pass-reset-header-text: #212121;
$pass-reset-description-text: #666666;

//Where do you live Popup
$where-do-you-live-header-text: #212121;
