@import '../../../../../../variables.scss';
@import '../../../../../../mixins.scss';

.Profiles-Loader {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
}
.grid-container {
  width: auto;
  max-width: 1200px;
  margin: 20px auto;
  height: 100%;
  .container {
    max-width: 100%;
  }
}
.grid-container {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
}
.Browse-Profiles-Main {
  .loader {
    display: flex;
    justify-content: center;
  }
  .NoResultText {
    font-size: 20px;
    text-align: center;
  }
  .grid-container {
  }
}

.Browse-Profiles {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 10px;
  width: 100%;
  margin: auto;
  margin-bottom: 50px;
  position: relative;

  @media (min-width: 1025px) {
    grid-gap: 10px;
  }

  @include respond(phone) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
  }

  @include respond(tablet) {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
  }

  .Profile {
    position: relative;
    padding: 0;
    border-radius: 8px;
    overflow: hidden;
    margin: auto;
    cursor: pointer;

    width: 100%;
    height: 210px;
    max-height: 210px;

    &.hidden {
      display: none;
    }

    @include respond(phone) {
      height: 190px;
    }
    @include respond(tablet) {
      height: 280px;
      max-height: 280px;
    }

    .ImageComponent {
      width: 100%;
      height: 100%;
    }

    .Loading {
      height: 100%;
    }

    &:hover {
      .ImageComponent {
        transform: scale(1.2);
      }
    }

    .Online {
      position: absolute;
      left: 10px;
      top: 10px;
      width: 10px;
      height: 10px;
      background: green;
      border-radius: 100px;
      margin-right: 5px;
      min-width: 10px;
      justify-self: flex-end;
      align-self: flex-end;
      margin-bottom: 4px;
      z-index: 1;
    }

    .Shadow {
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) -1.11%, rgba(0, 0, 0, 0.8) 98.89%);
      display: flex;
    }

    .ProfileName {
      position: absolute;
      left: 0;
      bottom: 0;
      right: 0;
      top: 50%;
      color: white;
      font-size: 14px;
      font-family: 'Gothic A1', serif;
      line-height: 18px;
      font-weight: 600;
      z-index: 1;
      margin: 0;
      padding: 10px;
      display: flex;
      align-items: flex-end;
      box-sizing: content-box;

      .ProfileInfo {
        display: flex;
        align-items: center;
        box-sizing: content-box;
        width: 100%;

        .Name-Age {
          width: 100%;
          overflow-wrap: break-word;
          font-size: 10px;
          font-family: 'Raleway', serif;

          @media (max-width: 1295px) and (min-width: 1025px) {
            font-size: 6px;
          }
          @include respond(phone) {
            font-size: 12px;
          }
          @include respond(tablet) {
            font-size: 16px;
          }
        }
      }
    }

    .ImageComponent {
      transition: transform 0.6s;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
.Pages {
  width: 100%;
  display: flex;
  gap: 10px;
  justify-content: center;
  margin: 40px 0;
  .pagination {
    .ant-pagination-prev,
    .ant-pagination-next {
      button {
        border-color: transparent;
        border-radius: 8px;
        &:hover {
          background-color: rgba(159, 2, 32, 0.6);
          color: white;
          a {
            color: white;
          }
        }
      }
    }
    .ant-pagination-item-active {
      background-color: #9f0220;
      border-color: transparent;
      height: auto;
      a {
        color: white;
        border-color: transparent;
      }
    }
    li {
      border-radius: 8px;
      border-color: transparent;
      &:hover {
        background-color: rgba(159, 2, 32, 0.6);
        color: white;
        a {
          color: white;
        }
      }
    }
  }
  @include respond(phone) {
    margin: auto;
    gap: 3px;
    margin-bottom: 25px;
  }

  button {
    font-family: 'Open Sans', serif;
    font-size: 14px;
    line-height: 17px;
    background-color: white;
    color: #5e6c84;
    border: none;
    border-radius: 8px;
    padding: 15px 20px;
    cursor: pointer;

    @include respond(phone) {
      padding: 10px;
      font-size: 12px;
    }

    @media (max-width: 300px) {
      padding: 5px;
    }

    @media (max-width: 260px) {
      padding: 5px;
    }

    @media (min-width: 400px) {
      padding: 15px 20px;
    }

    &.Page-Selected {
      background-color: $main-color;
      color: white;
    }
  }

  .PageArrow {
    background-color: transparent;
    font-size: 20px;
    font-weight: 600;
    font-family: 'Open Sans', serif;

    @include respond(phone) {
      font-size: 15px;
      padding: 2px;
    }

    &:disabled {
      color: $disabled-color;
      cursor: default;
    }
  }
}

.Loading {
  width: 100%;
  margin: auto;
  margin-bottom: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
}
