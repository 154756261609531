@import 'src/assets/css/colors';
@import 'src/assets/css/mixins';
.conversation {
  background-color: $bg-color;
  width: 100%;
  //noinspection CssInvalidPropertyValue
  height: -webkit-fill-available;
  display: flex;
  height: 100%;
  .chat {
    flex: 3 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    max-width: 100%;
    .inbox-empty {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      height: 100%;
      h2 {
        font-weight: 900;
        color: rgba(0, 0, 0, 0.2);
      }
    }
    .conv-header {
      display: flex;
      padding: 15px 5px 5px 20px;
      justify-content: flex-start;
      height: fit-content;
      .user {
        display: flex;
        margin-left: 20px;
        .status-text {
          margin-left: 10px;
        }
        .UserPhotoContainer {
          position: relative;
          display: flex;
          align-items: center;

          .ImageComponent {
            width: 36px;
            height: 36px;
            margin-right: 10px;
            border-radius: 100%;
            overflow: hidden;
            @media screen and (min-width: 500px) and (max-width: 900px) {
              width: 30px;
              height: 30px;
            }

            img {
              width: 100%;
              height: 100%;
              object-position: center;
              object-fit: cover;
            }
          }
        }

        .username {
          @include usernameConv;
        }
      }
    }
    .conv-list {
      display: flex;
      flex-direction: column;
      flex: 1;
      overflow: auto;
      @media screen and (min-width: 500px) {
        height: calc(100% - 307px);
        min-height: calc(100% - 307px);
        flex: none;
      }
      .inbox-empty {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .DayShowContainer {
        display: flex;
        flex-shrink: 0;
        justify-content: center;
        margin: 10px;
        margin-bottom: 30px;
        margin-top: 30px;

        span {
          background-color: #fff;
          padding: 5px 10px;
          border-radius: 20px;
          font-size: 12px;
          color: #5e6c84;
          box-shadow: 0 10px 35px rgba(0, 0, 0, 0.15);

          @media screen and (min-width: 500px) and (max-width: 900px) {
            font-size: 8px;
          }
        }
      }
      .bubble {
        position: relative;
        word-wrap: break-word;
        display: flex;
        flex-shrink: 0;
        flex-direction: column;
        justify-content: flex-end;
        text-align: left;
        background: #fff;
        margin: 8px 10px;
        min-width: 100px;
        border: 1px solid #e9edf0;
        max-width: 50%;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.04);
        font-family: 'Open Sans', sans-serif;
        border-radius: 8px;
        .message-body {
          padding: 5px;
        }
        img {
          max-height: 100%;
        }
        .bubble-resend {
          display: flex;
          align-items: center;
          cursor: pointer;
          position: absolute;
          right: 102%;
          color: gray;
          top: calc(50% - 1rem);
          padding: 5px;
          &:hover {
            background-color: rgba(128, 128, 128, 0.2);
            border-radius: 50px;
          }
        }
        .bubble-buy-credits {
          position: absolute;
          font-size: 0.6rem;
          top: -12px;
          cursor: pointer;
          right: -1px;
          left: -1px;
          z-index: 9;
          text-decoration: underline;
          color: white;
          background-color: #ff7272;
          padding: 2px 10px;
          border-radius: 3px 3px 0 0;
          &:hover {
            background-color: $main;
            color: white;
            transition: all 0.5s;
          }
        }
        &.me {
          align-self: flex-end;
          background-color: $main;
          color: white;
          border-bottom-right-radius: 0;
          .message-time {
            color: white;
            justify-content: flex-end;
          }
        }
        &.failed {
          color: $main;
          background-color: #ffe6e6;
          border: 1px solid #ff7272;
        }
        &.other {
          border-bottom-left-radius: 0;
          .message-time {
            justify-content: flex-start;
          }
        }
        &.system-message {
          color: rgba(0, 0, 0, 0.5);
          font-size: 10px;
          justify-content: center;
          align-self: center;
          background-color: rgba(0, 0, 0, 0.01);
        }
        .message-time {
          padding: 0 6px;
          display: flex;
          color: #5e6c84;
          font-size: 8px;
        }
      }
    }

    .messages-skeleton {
      padding: 20px;
      .skeleton-item:nth-child(even) {
        margin: 10px 0;
        display: flex;
        justify-content: flex-end;
      }
    }
    .conversation__list__messages--archivedConversation {
      position: absolute;
      inset: 0;
      background: #f8f6f5;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      justify-content: center;
      align-items: center;
      padding: 1rem;
      text-align: center;
      width: 100%;
      height: 100%;
      &--title {
        color: #8b8b8b;
        font-family: 'Open Sans', sans-serif;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 19px; /* 126.667% */
      }
      &--action {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        cursor: pointer;
        p {
          color: #921d26;
          font-family: 'Open Sans', sans-serif;
          font-size: 15px;
          font-style: normal;
          font-weight: 600;
          line-height: 18px; /* 120% */
          margin-bottom: 0;
        }
      }
      .animate-spin {
        animation: spin 1s linear infinite;

        @keyframes spin {
          from {
            transform: rotate(0deg);
          }
          to {
            transform: rotate(360deg);
          }
        }
      }
    }
  }
  .description {
    height: 100%;
    background-color: white;
    padding: 15px 20px;
    flex: 1 1;
    display: flex;
    flex-direction: column;
    .inbox-empty {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      height: 100%;
      h2 {
        font-weight: 900;
        color: rgba(0, 0, 0, 0.2);
      }
    }
    .conv-header {
      display: flex;
      justify-content: flex-start;
      height: fit-content;
      .user {
        display: flex;
        margin-left: 20px;

        .status-text {
          margin-left: 10px;
        }

        .UserPhotoContainer {
          position: relative;
          display: flex;
          align-items: center;

          .ImageComponent {
            width: 36px;
            height: 36px;
            margin-right: 10px;
            border-radius: 100%;
            overflow: hidden;
            @media screen and (min-width: 500px) and (max-width: 900px) {
              width: 30px;
              height: 30px;
            }

            img {
              width: 100%;
              height: 100%;
              object-position: center;
              object-fit: cover;
            }
          }
        }

        .username {
          @include usernameConv;
        }
      }
    }
    .desc-container {
      position: relative;
      height: 100%;
      overflow: auto;
      .desc-text {
        position: absolute;
        overflow: auto;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
      }
    }
    @media screen and (max-width: 756px) {
      height: calc(100vh - 270px);
      .ProfilePhotoContainer {
        .ImageComponent {
          img {
            object-fit: contain;
          }
        }
      }
    }
  }
}
.message-area {
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
  padding: 10px;
  background-color: white;
  margin-bottom: 10px;
  border-radius: 8px;
  bottom: 0;
  @media screen and (max-width: 320px) {
    margin-bottom: 80px;
  }
  @media screen and (min-width: 500px) {
    position: absolute;
  }
  .preview-image {
    height: 100px;
    width: 100px;
    position: relative;
    background-color: rgba(128, 128, 128, 0.8);
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 10px;
    .remove {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      color: white;
      border: 1px solid white;
      border-radius: 50px;
      font-size: 20px;
      line-height: 15px;
      font-weight: 700;
      right: 10px;
      top: 10px;
      width: 20px;
      height: 20px;
      transform: rotate(45deg);
      cursor: pointer;
    }
    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }
  .buttons-container {
    border-top: 1px solid rgba(128, 128, 128, 0.11);
    padding-top: 5px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    .send-button {
      font-size: 16px;
      border-radius: 50px;
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $main;
      border: none;
      cursor: pointer;
      color: white;
      button {
        color: $main;
      }
      &:disabled {
        background-color: rgba($main, 0.5);
      }
      @media screen and (max-width: 720px) {
        font-size: 8px;
        svg {
          width: 20px;
          height: 20px;
        }
      }
    }
    .attachment-container {
      display: flex;
      gap: 10px;
      font-size: 20px;
      color: rgba(128, 128, 128, 0.47);
      font-weight: 100;
      .attachment-icon {
        color: rgba(128, 128, 128, 0.47);
        cursor: pointer;
      }
      .emoji-icon {
        cursor: pointer;
      }
    }
  }
  textarea {
    width: 100%;
    border: none;
    resize: none;
    &:focus-visible {
      outline: none;
    }
  }
}
.ant-message {
  top: 0;
  .my-message {
    position: relative;
    padding: 0;

    .ant-message-notice-content {
      padding: 5px;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      color: $main;
      .ant-message-loading .anticon {
        color: $main;
      }
    }
  }
}
.rotate {
  -webkit-animation: spin 1s ease-in infinite;
  -moz-animation: spin 1s ease-in infinite;
  animation: spin 1s ease-in infinite;
}
@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
