@import '../../../../../../variables.scss';
@import '../../../../../../mixins.scss';

.LanderContainer12 {
  display: flex;
  font-family: 'Raleway';
  justify-content: center;
  align-items: center;
  height: 100vh;
  position: relative;
  background-size: cover;

  &.version12-1 {
    background-image: url('../../../../../../assets/images/Lander12/red/bg.jpg');
  }

  &.version12-2 {
    background-image: url('../../../../../../assets/images/Lander12/orange/bg.png');
  }

  &.version12-3 {
    background-image: url('../../../../../../assets/images/Lander12/purple/bg.png');
  }

  &.version12-4 {
    background-image: url('../../../../../../assets/images/Lander12/blue/bg.png');
  }

  @media (max-width: 1020px) and (orientation: 'landscape') {
    height: auto;
    padding: 2.5em;
  }

  .BackgroundOverlay12 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // background: linear-gradient(180deg, rgba(49, 12, 14, 0.9) 60.89%, rgba(146, 29, 38, 0.9) 124.77%);
  }

  .LoadingOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    // background: linear-gradient(180deg, rgba(49, 12, 14, 0.9) 60.89%, rgba(146, 29, 38, 0.9) 124.77%);
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      animation: rotate 2s infinite linear;

      @include respond(phone) {
        height: 40px;
      }
    }
  }

  .LanderContent {
    z-index: 1;
    width: 613px;

    @include respond(phone) {
      width: 100%;
    }

    .ProgressBar {
      height: 5px;
      width: 613px;
      background: rgba(222, 190, 190, 0.4);
      border-radius: 22px;
      margin-bottom: 29px;
      position: relative;

      @include respond(phone) {
        width: 90%;
        margin-left: 4%;
        margin-right: 4%;
      }

      .Percentage {
        height: 100%;
        // background: #9f0220;
        border-radius: 22px;

        &.version12-1 {
          background: rgba(159, 2, 32, 1);
        }

        &.version12-2 {
          background: rgba(195, 47, 35, 1);
        }

        &.version12-3 {
          background: rgba(69, 3, 91, 1);
        }

        &.version12-4 {
          background: rgba(113, 90, 255, 1);
        }

        span {
          position: absolute;
          bottom: 7px;
          left: 50%;
          transform: translate(-50%, -50%);
          color: #ffffff;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 16px;
        }
      }
    }

    .LanderContentInner12 {
      box-sizing: border-box;
      border: none;
      border-radius: 9px;
      padding: 59px 54px;

      &.version12-1 {
        background: rgba(159, 2, 32, 0.6);
        box-shadow: 13px 13px 22px 8px rgba(0, 0, 0, 0.54);
      }

      &.version12-2 {
        background: rgba(10, 8, 8, 0.7);
        box-shadow: 13px 13px 22px 8px rgba(0, 0, 0, 0.54);
      }

      &.version12-3 {
        background: rgba(233, 219, 255, 1);
        box-shadow: 13px 13px 22px 8px rgba(38, 20, 20, 0.18);
      }

      &.version12-4 {
        background: rgba(12, 24, 68, 1);
        box-shadow: 13px 13px 22px 8px rgba(36, 76, 113, 0.18);
      }

      @include respond(phone) {
        margin: auto 3%;
        padding: 40px 30px;
      }
    }
  }

  .InputsContainer12 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    width: 100%;
    margin-top: 30px;
  }

  .ButtonsContianer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .Button {
      &.version12-1 {
        background: rgba(55, 13, 12, 1);

        &:hover {
          color: rgba(55, 13, 12, 1) !important;
          border-color: rgba(55, 13, 12, 1) !important;
        }
      }

      &.version12-2 {
        background: rgba(159, 2, 32, 1);

        &:hover {
          color: rgba(159, 2, 32, 1) !important;
          border-color: rgba(159, 2, 32, 1) !important;
        }
      }

      &.version12-3 {
        background: rgba(129, 36, 164, 1);

        &:hover {
          color: rgba(129, 36, 164, 1) !important;
          border-color: rgba(129, 36, 164, 1) !important;
        }
      }

      &.version12-4 {
        background: rgba(113, 90, 255, 1);

        &:hover {
          color: rgba(113, 90, 255, 1) !important;
          border-color: rgba(113, 90, 255, 1) !important;
        }
      }
    }
  }

  @keyframes fade-in {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  .ToggleElement {
    opacity: 0;
    animation: fade-in 0.1s ease-in-out forwards;
  }

  .Hidden {
    opacity: 0;
  }
}
