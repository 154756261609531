@import '../../../variables';

.DatePickerField {
  position: relative;
  height: 70px;

  input {
    height: 40px;
    padding-left: 30px;
    border: 1px solid $basic-input-border;
    box-sizing: border-box;
    border-radius: 8px;
    outline: none;
    width: 100%;
  }

  .ImageComponent {
    position: absolute;
    right: 10px;
    top: 10px;
  }

  .Error-Container {
    color: red;
    font-size: 12px;
  }
  .DatePickerIcon {
    cursor: pointer;
  }
}
