@import '../../../../../../../../variables.scss';
@import '../../../../../../../../mixins.scss';

.LanderContainer17 {
  .ProtectionContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .Button:first-of-type {
      margin-bottom: 10px;
    }

    .ProtectionText {
      color: #fff;
      text-align: center;
      font-size: 24px;
      margin-bottom: 35px;

      @include respond(phone) {
        font-size: 19px;
      }

      span {
        color: #fe1414;
      }
    }
  }
}
