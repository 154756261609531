.gift-dropdown {
  padding: 0 12px 12px;
  display: flex;
  background-color: white;
  .label {
    margin-right: 10px;
    color: #d41411;
    font-size: 20px;
    line-height: 25px;
    font-family: 'Lobster', cursive;
    white-space: nowrap;
  }
}
.modal-body {
  padding: 59px 81px;
  font-family: Raleway, serif;
  font-size: 30px;
  line-height: 39px;
  font-weight: 600;
  text-align: center;
  color: #514f4f;
  .name {
    color: #0a0202;
    font-weight: 700;
  }
  .no-of-credits {
    color: #d41411;
  }
}
.select-different {
  background-color: transparent !important;
  border: none;
  cursor: pointer;
  margin-top: 40px;
  font-family: Open Sans, serif;
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 24px;
  letter-spacing: 0;
  text-align: center;
  color: #9f0220 !important;
  text-transform: capitalize !important;
  text-decoration: underline;
}
