@import '../../../../variables.scss';
@import '../../../../mixins.scss';

.LanderConfirmationContainer {
  display: flex;
  font-family: 'Raleway', sans-serif;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  position: relative;
  background-image: url('../../../../assets/images/confirmation.webp');
  background-repeat: no-repeat;
  background-size: cover;

  @media (max-width: 1020px) and (orientation: landscape) {
    // height: auto;
    padding: 2.5em;
  }

  .ConfirmationBackgroundOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(14, 11, 11, 0.4);
  }

  .LanderConfirmationContent {
    z-index: 1;
    width: 636px;

    @include respond(phone) {
      width: 100%;
    }

    .LanderContentInner {
      box-sizing: border-box;
      background: #f2efef;
      box-shadow: 4px 4px 9px rgba(31, 29, 29, 0.45);
      border-radius: 9px;
      padding: 0 !important;
      border: none;

      @include respond(phone) {
        margin: auto 3%;
        padding: 40px 30px;
      }

      .InnerHeader {
        background: #1f1414;
        padding: 24px 0;
        font-weight: 600;
        color: #ffffff;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        text-align: center;
        display: flex;
        flex-direction: column;
        gap: 13px;

        @include respond(phone) {
          padding: 14px 9px;
        }

        span:nth-of-type(1) {
          font-size: 38px;

          @include respond(phone) {
            font-size: 25px;
          }
        }

        span:nth-of-type(2) {
          font-size: 23px;
          color: #d41411;
          text-transform: uppercase;

          @include respond(phone) {
            font-size: 18px;
          }
        }
      }

      .InnerContainer {
        padding: 26px 81px 68px 81px;

        @include respond(phone) {
          padding: 20px;
        }
        .recaptcha {
          display: flex;
          justify-content: center;
          margin-bottom: 1rem;
        }
        .ConfirmationButton {
          font-size: 26px;
          padding: 30px;
          font-weight: 600;

          @include respond(phone) {
            font-size: 18px;
            padding: 20px;
          }
        }

        .StepSubTitle {
          color: #9f0220;
          text-align: center;
          font-size: 26px;
          font-weight: 600;
          padding: 0 50px;
          margin-bottom: 30px;

          @include respond(phone) {
            font-size: 20px;
            padding: 0;
          }
        }

        .CheckInfoContainer {
          margin-bottom: 47px;
          padding-right: 20px;

          .TermsLabel {
            line-height: normal;
          }

          p {
            display: flex;
            align-items: flex-start;

            .CheckboxLabel {
              padding-top: 2px;
              display: flex;
              width: 100%;
              font-weight: 500;
              font-family: Open Sans, serif;

              b {
                width: 25%;
                color: #9f0220;

                @include respond(phone) {
                  width: 35%;
                }
              }

              @include respond(phone) {
                flex-direction: column;
                gap: 5px;
              }
            }
          }

          .ant-tag {
            width: 100%;
            text-align: center;
          }

          .ant-checkbox-wrapper {
            margin-right: 17px;
            outline: none;
          }

          .ant-checkbox-inner {
            width: 19px;
            height: 19px;
          }

          .ant-checkbox-checked .ant-checkbox-inner {
            background-color: #9f0220;
            border-color: #9f0220;
          }

          .ant-checkbox .ant-checkbox-inner:after {
            height: 12px;
            top: 45%;
          }

          .ant-checkbox:not(.ant-checkbox-disabled):hover .ant-checkbox-inner {
            border-color: #9f0220;
          }

          .ant-checkbox-checked:after {
            border: none;
          }

          .ant-checkbox-wrapper:hover .ant-checkbox-checked:not(.ant-checkbox-disabled):after {
            border-color: #9f0220;
          }
        }
      }
    }
  }
}
