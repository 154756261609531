@import '../../../../../../../../variables.scss';
@import '../../../../../../../../mixins';
.ConversationContainer {
  height: calc(100% - 307px);
  overflow: auto;

  @include respond(phone) {
    height: calc(100% - 380px);
  }

  @include respond(tablet) {
    height: calc(100% - 330px);
  }

  @media screen and (min-width: 500px) and (max-width: 900px) {
    height: calc(100% - 200px);
  }

  .DayShowContainer {
    display: flex;
    justify-content: center;
    margin: 10px;
    margin-bottom: 30px;
    margin-top: 30px;

    span {
      background-color: #fff;
      padding: 5px 10px;
      border-radius: 20px;
      font-size: 12px;
      color: #5e6c84;
      box-shadow: 0px 10px 35px rgba(0, 0, 0, 0.15);

      @media screen and (min-width: 500px) and (max-width: 900px) {
        font-size: 8px;
      }
    }
  }
}
