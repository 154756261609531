@import '../../variables';
.SignUp {
  .Title {
    font-family: 'Raleway', sans-serif;
    font-size: 40px;
    font-weight: 700;
    padding: 20px 0;
    text-align: center;
    line-height: 44px;
    color: #212121;

    @media (max-width: 1024px) and (min-width: 768px) {
      padding: 10px 0 !important;
      font-size: 36px !important;
    }
  }

  Form {
    margin: 10px;

    @media (max-width: 1024px) and (min-width: 768px) {
      margin: 5px !important;
    }

    Input {
      color: $cancel-subscription-text;

      &::placeholder {
        color: $placeholder-text;
      }
    }
  }

  .SubmitButton {
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;

    @media (max-width: 1024px) and (min-width: 768px) {
      height: 35px !important;
      font-size: 14px !important;
    }
  }

  .Loading {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background: #0000006e;
    top: 0;
    left: 0;
    z-index: 999;
  }

  .CheckBoxes-Container {
    display: flex;
    justify-content: space-between;

    > div {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: auto;
    }

    .Checkbox-Container {
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      @media (max-width: 1024px) and (min-width: 768px) {
        margin-bottom: 5px !important;
      }

      span {
        font-size: 15px;
        color: $cancel-subscription-text;
      }
    }

    .Error-Container {
      color: red;
      font-size: 12px;
    }
  }

  .City-Container {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    height: 60px;

    @media (max-width: 1024px) and (min-width: 768px) {
      margin-top: 10px !important;
    }

    .InputField {
      width: 90%;
      margin-right: 20px;
      max-width: 50%;

      @media (max-width: 1024px) and (min-width: 768px) {
        margin-right: 10px;
        height: 50px !important;
      }
    }
    .Error-Container {
      white-space: nowrap;
      @media (max-width: 1024px) and (min-width: 768px) {
        height: 10px !important;
        margin-bottom: 5px !important;
      }
    }

    .SelectField {
      width: 90%;

      @media (max-width: 1024px) and (min-width: 768px) {
        height: 35px !important;
      }
    }

    input {
      flex: 1;
    }

    select {
      flex: 1;
    }
  }

  .HaveAccountHolder {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    color: #666666;

    @media (max-width: 1024px) and (min-width: 768px) {
      margin-top: 10px !important;
    }
    span {
      color: $main-color;
      font-weight: 600;
      margin-left: 5px;

      &:hover {
        cursor: pointer;
      }
    }
  }
  .DatePickerField .ImageComponent {
    width: 17px;
    height: 17px;
  }

  .InputField {
    height: 60px;
  }
}

.SignUp-Input {
  @media (max-width: 1024px) and (min-width: 768px) {
    height: 35px;
  }
}

#datePicker {
  @media (max-width: 1024px) and (min-width: 768px) {
    height: 35px;
    font-size: 14px;
  }
}
