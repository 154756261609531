@import '../../../../../../mixins.scss';

.MainView:has(.MatchGame) {
  .FooterWrapper {
    margin-top: 0;
  }
}
.MatchGame {
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #f3f6f8;
  padding: 50px 0 135px 0;

  @include respond(phone) {
    padding: 20px 0 70px 0;
    justify-content: center;
  }

  .MatchGameTitle {
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    font-size: 34px;
    line-height: 24px;
    text-align: center;
    color: #253858;
    margin-bottom: 30px;
    @include respond(phone) {
      font-size: 22px;
      margin-bottom: 14px;
    }
  }
  .MatchGameTitleWrapper {
    width: 540px;
    display: flex;
    margin-top: 30px;
    @include respond(phone) {
      width: 340px;
      padding-top: 40px;
      margin-bottom: 20px;
      margin-top: 0;
    }
  }
  .MatchGameSubtitle {
    margin-left: auto;
    border: none;
    margin-bottom: 30px;
    background-color: transparent;
    @include respond(phone) {
      margin-bottom: 10px;
    }
    &:hover {
      cursor: pointer;
    }
  }
  .PhotoContainer {
    width: 500px;
    height: 500px;
    border-radius: 8px;
    position: relative;
    z-index: 0;
    &.hidden {
      display: none;
    }
    @include respond(phone) {
      width: 300px;
      height: 345px;
    }
    .ImageComponent {
      width: 500px;
      height: 500px;
      @include respond(phone) {
        width: 300px;
        height: 345px;
      }
    }
  }
  .PhotoContainerBefore,
  .PhotoContainerBefore1 {
    content: '';
    position: absolute;
    width: 500px;
    height: 500px;
    background-color: white;
    z-index: -1;
    border-radius: 8px;
    transform: rotate(3.15deg);
    box-shadow: 0 5px 10px 5px rgba(0, 0, 0, 0.06);
    @include respond(phone) {
      width: 300px;
      height: 345px;
    }
  }
  .PhotoContainerBefore1 {
    transform: rotate(-3.52deg);
  }

  .userName {
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 30px;
    color: #ffffff;
    position: absolute;
    bottom: 30px;
    left: 20px;
    z-index: 3;

    @include respond(phone) {
      font-size: 18px;
      line-height: 20px;
    }
  }

  .userLocation {
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    position: absolute;
    bottom: 20px;
    left: 20px;
    z-index: 3;
    color: #ffffff;
    @include respond(phone) {
      font-size: 12px;
      line-height: 15px;
    }
  }
  .ImageContainer {
    height: 100%;
    width: 100%;
    border-radius: 8px;
  }

  .ImageComponent {
    img {
      border-radius: 8px;
    }
  }

  .ImageShadow {
    object-fit: cover;
    object-position: center;
    width: 500px;
    height: 500px;
    border-radius: 8px;
    box-shadow: -3px 4px 17px 3px rgba(0, 0, 0, 0.48);
    @include respond(phone) {
      width: 300px;
      height: 345px;
    }
  }

  .IconContainer {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 30px;
    .MatchIcon {
      height: 110px;
      width: 110px;
      margin-right: 30px;
      &:hover {
        opacity: 0.8;
        cursor: pointer;
      }
      @include respond(phone) {
        height: 70px;
        width: 70px;
        margin-right: 20px;
      }
    }
    .disabled {
      opacity: 0.5;
      &:hover {
        cursor: unset;
        opacity: 0.5;
      }
    }
  }

  .GalleryProfileContainer {
    position: absolute;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    z-index: 2;
    padding: 20px;
    @include respond(phone) {
      padding: 12px;
    }
  }

  .ViewProfileWrapper {
    padding: 12px;
    background: rgba(26, 26, 26, 0.5);
    mix-blend-mode: multiply;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;

    @include respond(phone) {
      padding: 9px;
    }
    &:hover {
      background: rgba(26, 26, 26, 0.8);
      cursor: pointer;
    }
  }
  .ViewProfileText {
    margin: 0;
    color: white;
    font-weight: 500;
    font-size: 14px;
  }
  .ViewProfileIcon,
  .GalleryIcon {
    z-index: 3;
  }

  .eyeIcon {
    margin-right: 8px;
  }

  .loadingContainer {
    width: 500px;
    height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    background: rgb(255, 255, 255);
    background: linear-gradient(180deg, rgba(255, 255, 255, 1) 40%, rgba(0, 0, 0, 0.85) 100%);
    @include respond(phone) {
      width: 300px;
      height: 345px;
    }
  }

  .loader {
    margin-bottom: 100px;
    height: 70px;
    animation: zoom-in-zoom-out 1s ease infinite;
  }

  @keyframes zoom-in-zoom-out {
    0% {
      transform: scale(1, 1);
    }
    50% {
      transform: scale(1.5, 1.5);
    }
    100% {
      transform: scale(1, 1);
    }
  }

  .NoProfile {
    font-family: 'Raleway', sans-serif;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    width: 500px;
    height: 500px;
    background: #EAEBED;
    border-radius: 8px;

    > img {
      width: 91.08px;
      height: 84.566px;
      margin-bottom: 20px;
      @include respond(phone) {
        width: 69.631px;
        height: 64.651px;
        margin-bottom: 12px;
      }
    }

    > span {
      color: #8C8C8C;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      margin-bottom: 20px;
      width: 55%;
      line-height: 24px;

      @include respond(phone) {
        width: 70%;
        font-size: 14px;
        line-height: 20px;
      }
    }

    > button {
      width: 85%;
      padding: 25px 0;
      @include respond(phone) {
        width: 100%;
        padding: 20px 0;
      }
    }

    @include respond(phone) {
      width: 300px;
      height: 345px;
    }
  }
}
.match-modal {
  .ant-modal-content {
    background: linear-gradient(180deg, #d41411 0%, #000000 139.94%);
    padding: 0;
    border-radius: 8px;
    overflow: hidden;
  }
}
