.TabsComponent {
  .nav {
    padding: 0;
    display: flex;
    flex-direction: row;
    margin: 0;
    justify-content: center;
    padding: 25px 0;
    gap: 15px;

    @media screen and (min-width: 1300px) {
      gap: 20px;
    }

    .Title-Container {
      color: #c4c4c4;
      font-family: 'Open Sans', serif;
      font-weight: 700;
    }

    .Title-Container.active + .Title-Container {
      &::before {
        display: none;
      }
    }

    .Title-Container.Previous {
      &::after {
        display: none;
      }
    }

    .Title-Container.active {
      border-bottom: none;
      color: #253858;
    }

    .Title-Container {
      outline: none;
      text-align: center;
      position: relative;
      margin: 0 -1px;
      display: flex;
      justify-content: center;
      text-transform: uppercase;
      font-size: 16px;
      cursor: pointer;

      @media screen and (min-width: 1025px) and (max-width: 1200px) {
        font-size: 13px;
      }

      @media screen and (min-width: 901px) and (max-width: 1024px) {
        font-size: 13px;
      }

      @media screen and (min-width: 500px) and (max-width: 900px) {
        font-size: 10px;
      }
    }
  }

  .Tab-Content {
    // height: calc(100vh - 180px);
    // min-height: 0;
    overflow: auto;
    height: 100%;

    .Content.Active {
      display: block;
      width: 100%;
    }

    .Content {
      display: none;
      height: 100%;
      box-sizing: border-box;
    }
  }
}
