@import '../../variables';
@import '../../mixins';

.LoginModal {
  width: 100%;
  background: #fff;
  border-radius: 8px;
  position: relative;
  margin: auto;
  padding: 35px;

  @include respond(phone) {
    width: 90%;
    padding: 0;
  }

  .SuccesMessage {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: absolute;
    width: 100%;
    height: 100%;
    background: #ffffffc7;
    top: 0;
    left: 0;
    border-radius: 8px;
    font-size: 20px;
    z-index: 9;
  }

  .Loading {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background: #0000006e;
    top: 0;
    left: 0;
    z-index: 999;
    border-radius: 8px;
  }

  .Title {
    @include modalTitle;
  }

  .Subtitle {
    @include modaSubtitle;
  }

  .LoginForm {
    .InputField {
      height: fit-content;
      margin-bottom: 20px;

      .Input {
        padding: 19px 30px;
      }
    }

    .InputField {
      .Input {
        padding: 19px 30px;

        @include respond(tablet) {
          padding: 15px 25px;
        }
      }
    }
  }

  .Checkbox-Container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;

    @include respond(tablet) {
      align-items: unset;
    }

    @include respond(phone) {
      margin-bottom: 10px;
    }

    .Checkbox-Holder {
      display: flex;
      align-items: flex-end;

      @include respond(phone) {
        align-items: center;
        margin-bottom: 10px;
      }

      @include respond(tablet) {
        display: flex;
        align-items: flex-end;
        align-items: center;
      }
      span {
        margin-left: 10px;
        color: #666666;
        font-weight: 600;

        @include respond(tablet) {
          font-size: 15px;
        }
      }
    }

    .ForgotPassword-Container {
      color: $main-color;
      font-weight: 600;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .InfoSafetyInfo {
    color: #666666;
    font-size: 12px;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 24px;
    display: flex;
    justify-content: center;
    align-items: center;

    @include respond(tablet) {
      padding: 0;
      margin: 5px 0;
    }

    .ImageComponent {
      margin-right: 10px;
      width: auto;
    }
  }

  .NotYetMemberInfo {
    color: #666666;
    font-weight: 600;
    text-align: center;

    .br {
      display: none;

      @include respond(tablet) {
        display: inline-flex;
      }
    }

    span {
      color: $main-color;

      &:hover {
        cursor: pointer;
      }
    }
  }
}
