.UsernameField {
  display: flex;
  flex-direction: column;
  gap: 17px;

  &:first-child {
    flex: 1;
  }

  &:last-child {
    flex: 3;
  }

  .InputContainer input {
    font-size: 16px;
    padding: 10px;
  }
}
