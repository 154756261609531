@import '../../../../../../../../variables.scss';
@import '../../../../../../../../mixins.scss';

* {
  box-sizing: border-box;
}

.ant-modal {
  width: 60%;
  @include respond(phone) {
    width: 90% !important;
  }

  .ant-carousel .slick-prev {
    left: 10px;
    z-index: 2;
    color: silver;
    font-size: 20px;
    height: 30px;
    top: 20px;
    top: 230px;
    @include respond(phone) {
      left: -23px;
    }
  }

  .ant-carousel .slick-next {
    right: 12px;
    z-index: 2;
    color: silver;
    font-size: 20px;
    height: 30px;
    top: 230px;
    @include respond(phone) {
      right: -17px;
    }
  }
}

.PhotoAlbum {
  .PhotoAlbumImage {
    background-color: black;
    height: 550px;
    @include respond(phone) {
      max-height: unset;
    }
    .PhotoAlbumImageImg {
      max-height: 500px;
      @include respond(phone) {
        max-height: unset;
      }
    }
  }

  .PhotoAlbumAvatarWrapp {
    width: fit-content;
    margin-right: 20px;
    margin-left: 1px;
  }
  .PhotoAlbumAvatar {
    width: 60px;
    height: 60px;
    border-radius: 50%;
  }
  .PhotoAlbumImageInfoWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include respond(phone) {
      margin-bottom: 25px;
    }
  }
  .PhotoAlbumImageInfo {
    display: flex;
    margin-top: 25px;
  }

  .PhotoAlbumImageDetails {
    display: flex;
    flex-direction: column;
  }
  .GalleryImageUsername {
    color: white; //design2
    font-size: 15px;
    margin-bottom: 2px;
  }
  .GalleryImageCreatedAt {
    color: white; //design2
    font-size: 11px;
  }
  .DeleteIcon {
    height: 20px;
    margin-top: 35px;
    margin-right: 15px;
    cursor: pointer;
  }
  .GaleryImageUsername {
    color: white;
  }
  .ant-image-preview-mask {
    background-color: rgb(0, 0, 0);
  }

  .photo-album-delete-success {
    z-index: 2000;
  }
  .photo-album-delete-fail {
    z-index: 2000;
  }
}

.DeleteModal {
  display: flex;
  justify-content: center;
  align-items: center;

  .ant-modal-content {
    width: fit-content !important;
  }
  .ant-modal-body {
    background-color: white;
  }

  .DeletePhotoText {
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #253858;
  }

  .ant-modal-title {
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: #253858;
  }
}

.ant-image-preview-mask {
  background-color: rgba(0, 0, 0, 0.8);
}
