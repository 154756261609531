@import '../../variables';
@import '../../mixins.scss';

.CreditCard {
  border-radius: 23px;
  text-align: center;
  border: 1px solid #e9e6e6;
  box-shadow: 4px 1px 11px 2px #e9e6e6;
  position: relative;

  &:hover {
    cursor: pointer;
  }

  .BadgeContainer {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;

    img {
      position: absolute;
      top: -15px;
      right: -15px;
    }
  }

  &.LimitedOffer {
    background-color: #160f0f;

    .DataHolder {
      .Price {
        color: #1cb23d;
      }

      .PercentageSave {
        color: $white;
      }
    }

    .CardFooter {
      background-color: #1cb23d;

      .ant-card-meta-description {
        color: #2c5128;
      }
    }
  }

  .DataHolder {
    padding: 10px;

    p {
      margin: 0;
      padding: 0;
    }

    .Price {
      font-weight: 700;
      font-size: 50px;
      color: #393939;
      line-height: 68px;

      &.MostPopular {
        color: #ffb91f;
      }
    }
    .Messages {
      font-weight: 700;
      font-size: 14px;
      color: #7e7e7e;
      line-height: 19px;
    }
    .MoneySave {
      font-size: 13px;
      color: #818181;
      line-height: 17px;
    }
    .PercentageSave {
      font-size: 18px;
      font-weight: 700;
      color: #0a0202;
      line-height: 24px;
    }

    .ant-divider-horizontal {
      margin: 10px 0;
      border-top: 1px solid #c1c1c1;
    }
  }

  .ant-card-body {
    padding: 0;
  }

  .CardFooter {
    background-color: $main-color;
    color: $white;
    padding: 10px;
    border-bottom-left-radius: 23px;
    border-bottom-right-radius: 23px;

    .ant-card-meta-title {
      font-weight: 700;
      font-size: 18px;
      line-height: 21px;
      margin: 0px;
      color: $white;
    }
    .ant-card-meta-description {
      font-weight: 700;
      font-size: 14px;
      line-height: 19px;
      color: #ca9c9e;
    }
  }
}
.InfoText {
  &.MostPopular {
    font-weight: 700;
    color: $main-color;
    text-align: center;
  }

  &.LimitedOffer {
    font-weight: 700;
    color: #1cb23d;
    text-align: center;
  }
}
