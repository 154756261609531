@import '../../../../../../../../variables.scss';
@import '../../../../../../../../mixins.scss';

.LanderContainer17 {
  &.version17-1 {
    .PlayImage {
      background-image: url('../../../../../../../../assets/images/Lander17/red/play.gif');

      @include respond(phone) {
        width: 60% !important;
      }

      @include respond(extraSmallPhone) {
        width: 90% !important;
      }
    }
  }

  &.version17-2 {
    .PlayImage {
      background-image: url('../../../../../../../../assets/images/Lander17/purple/play.gif');
    }
  }

  &.version17-3 {
    .PlayImage {
      background-image: url('../../../../../../../../assets/images/Lander17/blue/play.gif');
    }
  }

  &.version17-4 {
    .PlayImage {
      width: 230px !important;
      background-image: url('../../../../../../../../assets/images/Lander17/orange/play.gif');
    }
  }

  .PlayContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .PlayImage {
      height: 288px;
      width: 258px;
      background-size: cover;
    }

    .Button:first-of-type {
      margin-bottom: 10px;
    }

    .PlayText {
      color: #fff;
      text-align: center;
      font-size: 24px;
      line-height: 33px;
      margin: 35px 0;

      @include respond(phone) {
        font-size: 19px;
        white-space: pre-line;
      }

      span {
        color: #fe1414;
      }
    }
  }
}
