@import '../../../../../../../../variables.scss';
@import '../../../../../../../../mixins.scss';

.LanderContainer17 {
  &.version17-1 {
    .EmailImage {
      background-image: url('../../../../../../../../assets/images/Lander17/red/email.gif');

      @include respond(phone) {
        width: 100% !important;
      }
    }
  }

  &.version17-2 {
    .EmailImage {
      height: 250px;
      width: 230px;
      background-image: url('../../../../../../../../assets/images/Lander17/purple/email.gif');
    }
  }

  &.version17-3 {
    .EmailImage {
      height: 250px;
      width: 230px;
      background-image: url('../../../../../../../../assets/images/Lander17/blue/email.gif');
    }

    .BlueGoogleLoginButton {
      background: #e75434;

      &:hover {
        color: #fff !important;
        border-color: #ff6347 !important;
        background-color: #ff6347 !important;
      }
    }
  }

  &.version17-4 {
    .EmailImage {
      height: 250px !important;
      width: 260px !important;
      background-position: center !important;
      background-image: url('../../../../../../../../assets/images/Lander17/orange/email.gif');
    }
  }

  .EmailImage {
    height: 200px;
    width: 368px;
    background-size: cover;
    margin-bottom: 20px;
  }

  .EmailContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5px;

    .EmailText {
      color: #fff;
      text-align: center;
      font-size: 24px;
      margin-bottom: 40px;
      line-height: 33px;

      @include respond(phone) {
        font-size: 19px;
      }

      span {
        color: #fe1414;
      }
    }
  }

  .BlueGoogleLoginButton {
    background: #246cd7;
    font-weight: 400;
    font-size: 20px;
    line-height: 130%;
    padding: 17px 25px;
    border-radius: 4px;
    width: 100%;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    .anticon-google-plus {
      margin-right: 10px;
      background-image: url('../../../../../../../../assets/images/Lander17/google.png');
      background-repeat: no-repeat;
      width: 23px;
      height: 23px;

      svg {
        display: none;
      }
    }

    &:hover {
      color: #fff !important;
      border-color: #3d4869 !important;
      background-color: #3d4869 !important;
    }

    @include respond(phone) {
      font-size: 16px;
      padding: 18px;
    }
  }
}
