@import '../../variables.scss';
@import '../../mixins.scss';

.BasicHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  z-index: 1;

  .Header-Text {
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .Description {
      font-family: 'Raleway', sans-serif;
      color: $white;
      font-size: 18px;
      font-weight: 400;
    }
  }

  .SignUp-Logo-Wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 110px;

    @include respond(phone) {
      height: fit-content;
      padding-bottom: 30px;
    }

    .ButtonLogo-Wrapper {
      display: flex;
      width: 100%;
      justify-content: space-between;
      padding: 30px 0;

      @include respond(tablet) {
        padding: 30px 20px;
      }

      @include respond(phone) {
        display: none;
      }

      .Btn-VeriSignLogo {
        display: flex;
        gap: 10px;
      }

      .SignUpButton {
        width: auto;
      }
    }

    .MobileNav {
      display: none;
      margin-top: 30px;

      @include respond(phone) {
        width: 100%;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        background: transparent;
      }

      .Hamburger {
        position: fixed;
        right: 5%;
        width: 2rem;
        height: 2rem;
        display: flex;
        justify-content: space-around;
        flex-flow: column nowrap;
        z-index: 10;
        svg {
          path {
            fill: white;
          }
        }

        .Burger {
          width: 2rem;
          height: 0.25rem;
          border-radius: 10px;
          background-color: white;
          transform-origin: 1px;
          transition: all 0.3s linear;
        }
      }

      .noHamburger {
        visibility: hidden;
        @include respond(phone) {
          display: none;
        }
        .Burger {
          display: none;
          @include respond(phone) {
            display: none;
          }
        }
      }

      .CloseBtn {
        width: 18px;
        height: 18px;
        margin-right: 30px;
      }

      .ButtonLogo-Wrapper-Mobile {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        height: 100vh;
        background-color: #000000;
        align-items: center;

        .Logo-CloseBtn {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          padding-left: 0;
          padding-top: 26px;
          margin-bottom: 100px;
          @include respond(phone) {
            margin-bottom: 70px;
            width: 100%;
          }
        }

        .SignUp-VeriSign {
          display: flex;
          flex-direction: column;
          width: 100%;
          height: 100%;
          justify-content: flex-start;
          align-items: center;
          padding: 0 30px;

          .Button {
            width: 250px;

            @include respond(phone) {
              background-color: transparent;
              border: solid 2px;
              margin-bottom: 25px;
              width: 100%;
            }
          }

          .VeriSign-Logo {
            width: 150px;
          }
        }
      }
    }

    .Button {
      margin-top: 0;
      font-family: 'Raleway', sans-serif;
    }

    .SignUpButton {
      background-color: transparent;
      border: 2px solid #ffffff;
      line-height: 19px;
      padding: 19px 35px 18px 32px;
    }

    .VeriSign-Logo {
      width: 130px;
      margin-bottom: 75px;
    }
  }
}

.navbar {
  background-color: transparent;
  transition: all 0.9s ease-in-out;
  position: absolute;
  right: 0;
  left: 0;
  top: 0px;

  &.active {
    background-color: $header-background;
    position: fixed;
    animation: bar 0.5s ease;
    z-index: 101;
  }

  @keyframes bar {
    0% {
      top: -100%;
    }
    100% {
      top: 0;
    }
  }
}
