@import '../../variables.scss';
@import '../../mixins.scss';

.headerContainer {
  margin: 0;
  padding: 0;
  position: relative;

  .HeaderImage {
    position: relative;
    height: 100%;
    width: 100%;

    img {
      height: 450px;
      object-fit: cover;
      width: 100%;
      position: relative;
      z-index: -1;
      @include respond(phone) {
        height: 350px;
      }
    }

    &::before {
      height: 100%;
      width: 100%;
      position: absolute;
      z-index: 0;
      background-color: red;
      display: block;
      content: '';
      background: linear-gradient(180deg, #000000 0%, #c41826 100%);
      mix-blend-mode: multiply;
      opacity: 0.9;
    }
  }

  .Header {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @include respond(phone) {
      margin-bottom: 0;
    }

    .Header-Text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-family: 'Raleway';
      color: $white;
      font-size: 54px;
      margin: 120px 0 10px 0;
      font-weight: 700;

      @include respond(phone) {
        font-size: 34px;
        text-align: center;
        margin-top: 60px;
      }
    }

    .Description {
      font-family: 'Open Sans', sans-serif;
      color: $white;
      font-size: 18px;
      font-weight: 400;
      font-size: 18px;
      line-height: 30px;
      text-align: center;
      color: #ffffff;

      @include respond(phone) {
        width: 82%; //proveri na contact us ako se bude menjalo
      }
    }

    @include respond(phone) {
      text-align: center;
    }
  }

  .AboutUsImage {
    @include respond(phone) {
      object-position: -355px;
    }
  }

  .FAQ-Image {
    @include respond(phone) {
      object-position: -350px;
    }
  }

  .PricingImage {
    @include respond(phone) {
      object-position: -468px;
    }
  }

  .ContactUsImage {
    @include respond(phone) {
      object-position: -350px;
    }
  }
}
