@import '../../../../mixins.scss';
@import '../../../../variables.scss';

.SignUpAff4 {
  min-height: 100vh;
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url('../../../../assets/images/FunnelPage/aff4background.png');
  background-size: cover;
  background-position: center;

  .NavbarAff {
    background-color: #000000;
    width: 100%;
    height: 120px;
    padding: 0 30px;
    opacity: 0.7;

    @include respond(phone) {
      height: 80px;
      opacity: 70%;
    }
  }
  .SignUpFree {
    display: flex;
    justify-content: center;
    margin-top: 36px;
    margin-bottom: 120px;
    p {
      font-family: 'Playfair Display';
      font-style: normal;
      font-weight: 600;
      font-size: 40px;
      line-height: 53px;
      text-align: center;
      width: 470px;
      height: 159px;
      color: #ffffff;
      text-shadow: 0px 4px 14px rgba(0, 0, 0, 0.35);

      a {
        color: inherit;
      }

      @include respond(phone) {
        width: 275px;
        height: 96px;
        font-size: 24px;
        line-height: 32px;
      }
    }
    @include respond(phone) {
      height: 80px;
      opacity: 70%;
    }
    @include respond(phone) {
      margin-bottom: 0px;
    }
  }

  .Steps {
    width: 35rem;
    margin: auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .Aff4Message {
      display: flex;
      margin-bottom: 80px;

      img {
        width: 80px;
        height: 80px;
      }

      @include respond(phone) {
        img {
          width: 50px;
          height: 50px;
          margin-top: 14px;
        }
      }

      .RectMessage {
        width: 100%;
        height: 90px;
        background: #ffffff;
        border-radius: 10px;
        padding: 13px 10px 15px 20px;
        margin-left: 15px;

        &.MessageSmall {
          height: 75px;
        }

        &.MessageBig {
          height: 110px;
        }

        p {
          height: 60px;
          font-family: 'Gothic A1';
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 30px;
          color: #000000;
        }

        &::after {
          content: url('../../../../assets/images/FunnelPage/pff4Angle.svg');
          display: block;
          width: 0px;
          background-color: #ffffff;
          position: relative;
          right: 30px;
          bottom: 4rem;
        }

        @include respond(phone) {
          p {
            font-size: 14px;
            line-height: 20px;
          }
        }
        @include respond(phone) {
          padding: 10px 10px 10px 20px;
        }
      }
      @include respond(phone) {
        .MessageSmall {
          height: 60px !important;
        }
      }
      @include respond(phone) {
        .MessageBig {
          height: 130px !important;
        }
      }
    }

    @include respond(phone) {
      width: 100%;
    }

    .Aff4FormContainer {
      display: flex;
      flex-direction: column;
      align-items: center;

      .TextContainer {
        text-align: center;
        color: #fff;
      }

      .GoogleLoginContainer {
        width: 100%;
        margin-top: 5px;
        margin-bottom: 10px;

        button {
          width: 100%;
          text-align: center;
          justify-content: center;
          border-radius: 8px !important;
          height: 60px;
        }
      }

      .Button {
        border: 4px solid transparent;
        padding: 15px 50px;
        width: 100%;
        margin-bottom: 20px;

        &.ButtonMessage {
          margin-top: 19px;
        }

        &.DisabledButton {
          pointer-events: none;
          background: #a25665;
        }

        &:hover {
          background: #dd002a;
          border: 4px solid rgba(159, 2, 32, 0.6);
          box-shadow: 0px 9px 24px rgba(179, 10, 26, 0.6);
          color: $white;
        }

        &.Inverted {
          background-color: #fff;
          color: $main-color;

          &:hover {
            background: #a3a3a3;
            border: 4px solid rgba(0, 0, 0, 0.1);
            box-shadow: 0px 9px 14px rgba(191, 191, 191, 0.3);
            color: #fff;
          }
        }

        @include respond(phone) {
          padding: 10px 20px;
          margin: 0 10px 20px 10px;
          width: 100%;
        }
      }

      .InputContainers {
        width: 100%;
        position: relative;
        input {
          height: 60px;
          color: #000;

          @include respond(phone) {
            height: 40px;
          }
        }
        .InputField {
          height: 80px;
          input {
            border: 2px solid transparent;

            &:hover {
              border: 2px solid #dd002a;
              box-shadow: 0px 9px 14px rgba(221, 0, 42, 0.5);
            }
          }
        }
        // .DatePickerField {
        //   height: 80px;
        //   input {
        //     border: 2px solid transparent;

        //     &:hover {
        //       border: 2px solid #dd002a;
        //       box-shadow: 0px 9px 14px rgba(221, 0, 42, 0.5);
        //     }
        //   }
        // }
        .react-date-picker {
          width: 100%;
        }
        .Example {
          left: 392px;
          @include respond(smallPhone) {
            top: 13px;
            left: 220px;
            font-size: 10px;
          }
        }
        .Checkbox-Container {
          display: flex;
          flex-direction: column;
          margin-bottom: 10px;

          .Checkbox-Holder {
            display: flex;
            .Checkbox {
              margin-right: 10px;
            }
            p {
              font-family: 'Gothic A1';
              font-style: normal;
              font-weight: 700;
              font-size: 14px;
              line-height: 18px;
              color: #ffffff;
            }
          }
        }

        @include respond(phone) {
          .InputField {
            height: 60px;

            .Error-Container {
              font-size: 10px;
            }
          }
          .DatePickerField {
            height: 60px;

            .Error-Container {
              font-size: 10px;
            }
          }
        }

        .DatePickerIcon {
          top: -10px;
          right: 10px;
          width: auto;

          @include respond(phone) {
            top: -10px;
          }
        }
      }

      .ButtonContainers {
        display: flex;
        width: 100%;
        justify-content: space-between;

        .DropDownContainer {
          width: 48%;
          display: flex;
          flex-direction: column;

          select {
            border: 2px solid transparent;

            &:hover {
              border: 2px solid #dd002a;
              box-shadow: 0px 9px 14px rgba(221, 0, 42, 0.5);
            }
          }
        }

        .Dropdown-Select {
          background-color: #ffffff;
          margin-bottom: 0px;
        }
      }
    }
  }
}
