@import '../../../../../../variables.scss';
@import '../../../../../../mixins.scss';

.Navbar {
  background-color: $main-color;
  height: 80px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;

  @include respond(phone) {
    padding: 0 10px;
    z-index: 0;
  }

  @media screen and (min-width: 500px) and (max-width: 900px) {
    height: 50px;
  }

  &.active {
    background-color: $main-color;
  }

  .premiumButton {
    width: 10px;
    height: 10px;
    background: red;
    border-radius: 100px;
    position: absolute;
    top: 10px;
    right: 10px;

    &.active {
      background: green;
    }
    &:hover {
      cursor: pointer;
    }
  }

  @include respond(tablet) {
    padding-left: 70px;
  }

  .Hamburger {
    width: 2rem;
    height: 2rem;
    display: flex;
    justify-content: space-around;
    flex-flow: column nowrap;
    cursor: pointer;
    z-index: 1;
    font-size: 2rem;
    svg {
      path {
        fill: white;
      }
    }

    .Burger {
      width: 2rem;
      height: 0.25rem;
      border-radius: 10px;
      background-color: white;
      transform-origin: 1px;
      transition: all 0.3s linear;
    }
  }

  .NavbarLogo-Holder {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;

    @include respond(phone) {
      width: 180px;
    }

    @media screen and (min-width: 500px) and (max-width: 900px) {
      width: 100px;
    }

    img {
      cursor: pointer;
      transition: 850ms;
      width: 100%;

      @media screen and (min-width: 500px) and (max-width: 900px) {
        width: 100%;
      }
    }
    .CenterImg {
      margin-left: 250px;
      transition: 350ms ease-out;
      @include respond(phone) {
        margin-left: 0;
      }
    }
  }

  .Profile-Notification-Wrapper {
    display: flex;
    gap: 30px;
    justify-content: center;
    align-items: center;
    color: white;
    padding: 0;

    .Notifications {
      position: relative;
      cursor: pointer;
      width: 100%;
      z-index: 1;

      @include respond(phone) {
        margin-left: 10px;
        margin-right: 10px;
      }

      .Eclipse {
        font-size: 1.5rem;
        position: absolute;
        right: 30%;
        //background-color: #ea42d9;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .ProfileAvatar {
      background-color: blue;
      border-radius: 50%;

      @include respond(phone) {
        display: none;
      }
    }

    .Rectangle {
      width: 10.23px;
      height: 6.63px;

      @include respond(phone) {
        display: none;
      }
    }
  }
}

.SideBarWrapper {
  display: flex;
  flex-direction: row-reverse;
  height: 100%;
}

.SideBar.active {
  transition: 350ms;
  left: 0;
  overflow: auto;
  z-index: 102;

  .PresentationInput {
    display: flex;
    justify-content: center;

    input {
      width: 50px;
    }
  }

  @media screen and (max-width: 500px) {
    width: 75%;
    transition: 200ms;
    overflow: auto;
  }

  @media screen and (min-width: 500px) and (max-width: 720px) {
    width: 40%;
    transition: 200ms;
    overflow: auto;
  }

  @include respond(tablet) {
    overflow: auto;
  }
}
.close-sidebar {
  position: absolute;
  right: 7%;
  top: 7%;
  cursor: pointer;
  font-size: 2rem;
  font-weight: 700;
  @media screen and (max-width: 500px) {
    height: 100%;
    width: 25%;
    background: transparent;
    z-index: 100;
    position: fixed;
    display: block;
  }
}
.CloseSideBar {
  //display: none;
  @media screen and (max-width: 500px) {
    height: 100%;
    width: 25%;
    background: transparent;
    z-index: 100;
    position: fixed;
    display: block;
  }
}

.SideBar {
  color: white;
  background-color: $main-color;
  width: 250px;
  top: 0;
  left: -250px;
  transition: 850ms;
  height: 100%;
  margin: 0;
  padding: 0;
  position: fixed;
  display: flex;
  flex-direction: column;
  z-index: 102;
  overflow: auto;

  .Btn-ProfileInfo {
    position: relative;

    .CloseSideBar {
      font-size: 2rem;
      font-weight: 700;
      position: absolute;
      width: 20px;
      height: 20px;
      right: 7%;
      top: 7%;
      cursor: pointer;
    }

    .Profile-Info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: auto;
      margin-top: 80px;

      .ProfilePhotoContainer {
        width: 132px;
        height: 132px;

        img {
          margin-bottom: 20px;
          width: 100%;
          height: 100%;
          aspect-ratio: 1;
          border-radius: 50%;

          &:hover {
            cursor: pointer;
          }
        }
      }

      .UserName {
        font-family: 'Roboto';
        font-size: 18px;
        line-height: 24px;
        color: #ffffff;
      }
      p {
        padding: 0;
        margin: 0;
        color: #9e9e9e;
      }
      .CreditsLeft {
        font-family: 'Roboto';
        font-size: 14px;
        line-height: 24px;
        color: #9e9e9e;
      }
      .LowCredits {
        color: red;
        font-family: 'Roboto';
        font-size: 14px;
        line-height: 24px;
      }
    }
  }

  .Sidebar-List {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    margin-top: 30px;

    @include respond(phone) {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .Sidebar-Item {
      display: flex;
      align-items: center;
      height: 35px;
      padding: 5px 0;
      padding-left: 15%;
      gap: 15px;
      color: white;
      font-size: 16px;
      line-height: 16px;
      border-left: 5px solid transparent;
      position: relative;

      &:hover {
        border-left: 5px solid $why-choose-flirt-background;
      }

      @include respond(phone) {
        display: flex;
        padding-left: 10%;
        align-items: center;
      }

      .MobileView {
        display: none;

        @include respond(phone) {
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          display: flex;
        }
      }

      &:hover {
        border-left: 5px solid #d41411;
        cursor: pointer;
        background-color: #7e172b;
        color: white;

        &:hover .Item-Notification {
          background-color: #ea42d9;
        }
      }

      .Icon-Sidebar {
        width: 20px;
        height: 20px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
      }

      .Messages {
        background-image: url('../../../../../../assets/images/SidebarIcons/MessagesHover.svg');
        background-repeat: no-repeat;
        width: 20px;
        height: 20px;
        background-size: contain;
      }

      .Browse-Profiles {
        background-image: url('../../../../../../assets/images/SidebarIcons/BrowseProfilesHover.svg');
        background-repeat: no-repeat;
        width: 20px;
        height: 20px;
        margin: 0;
        background-size: contain;
      }

      .Match-Game {
        background-image: url('../../../../../../assets/images/SidebarIcons/MatchGameHover.svg');
        background-repeat: no-repeat;
        width: 20px;
        height: 20px;
        background-size: contain;
      }

      .My-Matches {
        background-image: url('../../../../../../assets/images/SidebarIcons/MyMatchesHover.svg');
        background-repeat: no-repeat;
        width: 20px;
        height: 30px;
        background-size: contain;
      }

      .My-Favorites {
        background-image: url('../../../../../../assets/images/SidebarIcons/MyFavoritesHover.svg');
        background-repeat: no-repeat;
        width: 20px;
        height: 25px;
        padding-right: 1px;
        background-size: contain;
      }

      .Visitors {
        background-image: url('../../../../../../assets/images/SidebarIcons/VisitorsHover.svg');
        background-repeat: no-repeat;
        width: 20px;
        height: 20px;
        padding-right: 3px;
        background-size: contain;
      }

      .Credits {
        background-image: url('../../../../../../assets/images/SidebarIcons/CreditsHover.svg');
        background-repeat: no-repeat;
        width: 20px;
        height: 20px;
        padding: 0 4px 5px 0;
        background-size: contain;
      }

      .My-Profile {
        background-image: url('../../../../../../assets/images/SidebarIcons/MyProfileHover.svg');
        background-repeat: no-repeat;
        width: 20px;
        height: 20px;
        background-size: contain;
      }
      .SignOut {
        background-image: url('../../../../../../assets/images/SidebarIcons/SignOutHover.svg');
        background-repeat: no-repeat;
        width: 20px;
        height: 20px;
        padding: 0 3px 3px 0;
        background-size: contain;
      }
      .Item-Notification {
        background-color: #ea42d9;
        color: white;
        border-radius: 50%;
        padding: 2px;
        width: 19px;
        height: 19px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 11px;
        font-weight: 700;

        &:hover {
          background-color: #8b0880;
        }
      }
      .Item-Notification-New {
        background-color: #fff;
        color: #000;
        padding: 3px 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 15px;
        font-weight: 700;
        border-radius: 4px;
        margin-left: auto;
        margin-right: 15px;
      }
      .Eclipse-none {
        display: none;
      }

      .bounce {
        animation: bounce 2s both infinite;
      }

      @keyframes bounce {
        0%,
        20%,
        50%,
        80%,
        100% {
          transform: translateY(0);
        }
        40% {
          transform: translateY(-3px);
        }
        60% {
          transform: translateY(-3px);
        }
      }

      .Sidebar-Icon {
        &:hover {
          stroke: #f3f3f3;
        }
      }
    }
  }
}

.PurchaseCreditsModalContainer {
  .ModalDialog {
    .ModalContainer {
      background: #29024b;
      color: #fff;
      max-height: 90%;
      padding: 30px;
    }
  }
}

.Eclipse-none {
  display: none;
}
.notification-holder {
  position: relative;
  .notification-number {
    right:-0.5rem;
    top:-0.5rem;
    position: absolute;
    display: flex;
    border-radius: 50px;
    padding: 3px;
    color: white;
    text-align: center;
    align-items: center;
    justify-content: center;
    aspect-ratio: 1;
    background: #ea42d9;
  }
}
