@import '../../../../mixins.scss';

.MembersAreaMain {
  // background: #f3f6f8;
  padding-top: 80px;

  @include respond(tablet) {
    padding-top: 80px;
  }

  @media screen and (min-width: 500px) and (max-width: 900px) {
    padding-top: 50px;
  }
  .MembersAreaContent {
    display: flow-root;
    width: 100%;
    position: relative;
    margin-left: auto;
    transition: 850ms;
    > div {
      &:first-child {
        height: 100%;
      }
    }

    &.SidebarOpen {
      width: calc(100% - 250px);
      transition: 350ms;
      @include respond(phone) {
        width: 100%;
      }
    }
  }
}

.MembersAreaMain-HomeOpen {
  // background-color: #e5e5e5;
  padding-top: 80px;

  .MembersAreaContent {
    display: flow-root;
    width: 100%;
    position: relative;
    margin-left: auto;
    transition: 850ms;

    &.SidebarOpen {
      width: calc(100% - 250px);
      transition: 350ms;
      @include respond(phone) {
        width: 100%;
      }
    }
  }
}
