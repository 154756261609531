@import '../../../../../../../../variables.scss';
@import '../../../../../../../../mixins.scss';

.LanderContainer17 {
  &.version17-1 {
    .PasswordImage {
      background-image: url('../../../../../../../../assets/images/Lander17/red/password.gif');
    }
  }

  &.version17-2 {
    .PasswordImage {
      height: 300px;
      width: 224px;
      background-image: url('../../../../../../../../assets/images/Lander17/purple/password.gif');
    }
  }

  &.version17-3 {
    .PasswordImage {
      height: 300px;
      width: 224px;
      background-image: url('../../../../../../../../assets/images/Lander17/blue/password.gif');
    }
  }

  &.version17-4 {
    .PasswordImage {
      height: 230px !important;
      width: 330px !important;
      background-image: url('../../../../../../../../assets/images/Lander17/orange/password.gif');
    }
  }

  .PasswordInputContainer {
    width: 100%;
    position: relative;

    .ShowPasswordIcon {
      position: absolute;
      right: 3%;
      top: 25%;

      span svg {
        width: 25px;
        height: 25px;
        fill: #fff;
      }
    }
  }

  .PasswordImage {
    height: 258px;
    width: 268px;
    background-size: cover;
  }

  .PasswordContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .PasswordText {
      color: #fff;
      text-align: center;
      font-size: 24px;
      padding: 35px 0;
      margin-bottom: 0;

      @include respond(phone) {
        font-size: 19px;
      }

      span {
        color: #fe1414;
      }
    }
  }
}
