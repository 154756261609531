@import '../../../../mixins.scss';
@import '../../../../variables.scss';

.SignUpAff2 {
  min-height: 100vh;
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url('../../../../assets/images/FunnelPage/bg.png');
  background-size: cover;
  background-position: center;

  .Steps {
    min-height: 100vh;
    width: 570px;
    margin: auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include respond(phone) {
      width: 100%;
    }

    &.StepOne {
      background: url('../../../../assets/images/FunnelPage/aff1img1.png');
      background-size: inherit;
      background-position: center;
      background-repeat: no-repeat;
    }

    &.StepTwo {
      background: url('../../../../assets/images/FunnelPage/aff1img2.png');
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }

    &.StepThree {
      background: url('../../../../assets/images/FunnelPage/aff1img3.png');
      background-size: inherit;
      background-position: center;
      background-repeat: no-repeat;
    }

    &.StepFour {
      background: url('../../../../assets/images/FunnelPage/aff1img4.png');
      background-size: inherit;
      background-position: center;
      background-repeat: no-repeat;
    }

    &.StepFive {
      background: url('../../../../assets/images/FunnelPage/aff1img5.png');
      background-size: inherit;
      background-position: center;
      background-repeat: no-repeat;
    }

    &.StepSix {
      background: url('../../../../assets/images/FunnelPage/aff1img6.png');
      background-size: inherit;
      background-position: center;
      background-repeat: no-repeat;
    }

    .LogoAndMedia {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      padding: 15px;

      @include respond(tablet) {
        flex: 2;
      }

      @include respond(phone) {
        flex: 2;
      }

      .LogoContainer {
        width: 240px;

        @include respond(phone) {
          width: 135px;
        }

        img {
          width: 100%;
        }
      }

      .MediaContainer {
        .IconHolder {
          margin-bottom: 20px;
          color: #fff;
          text-align: center;
          display: block;

          @include respond(phone) {
            width: 40px;

            .ImageComponent {
              img {
                width: 100%;
              }

              &.ProfilePlusIcon {
                img {
                  width: 14px;
                }
              }
            }
          }

          &.AffImageContainer {
            margin-bottom: 40px;
            position: relative;

            @include respond(phone) {
              margin-bottom: 30px;
            }
          }
          .ProfilePlusIcon {
            position: absolute;
            display: block;
            top: 62px;

            @include respond(phone) {
              top: 28px;
            }
          }
        }
      }
    }

    .FormAndInfoContainer {
      width: 85%;
      margin: 0 auto;

      @include respond(tablet) {
        flex: 1;
      }

      @include respond(phone) {
        flex: 1;
      }

      .InfoContainer {
        color: #fff;
        text-align: left;
        margin-bottom: 10px;
      }

      .FormContainer {
        background-color: rgba(0, 0, 0, 0.6);
        border-radius: 5px;
        color: #fff;
        padding: 20px;

        .TextContainer {
          text-align: center;
        }

        .GoogleLoginContainer {
          width: 100%;
          margin-top: 5px;
          margin-bottom: 10px;

          button {
            width: 100%;
            text-align: center;
            justify-content: center;
            border-radius: 8px !important;
            height: 60px;
          }
        }

        .TitleContainer {
          text-align: center;
          font-size: 20px;
          font-weight: 600px;
          margin-bottom: 10px;

          @include respond(phone) {
            font-size: 14px;
          }
        }

        .InputContainers {
          position: relative;
          input {
            height: 60px;
            color: #000;

            @include respond(phone) {
              height: 40px;
            }
          }
          .InputField {
            height: auto;
            min-height: 80px;
            input {
              border: 2px solid transparent;

              &:hover {
                border: 2px solid #dd002a;
                box-shadow: 0px 9px 14px rgba(221, 0, 42, 0.5);
              }
            }
          }
          .DatePickerField {
            height: 80px;
            input {
              border: 2px solid transparent;

              &:hover {
                border: 2px solid #dd002a;
                box-shadow: 0px 9px 14px rgba(221, 0, 42, 0.5);
              }
            }
          }

          @include respond(phone) {
            .InputField {
              height: 60px;

              .Error-Container {
                font-size: 10px;
              }
            }
            .DatePickerField {
              height: 60px;

              .Error-Container {
                font-size: 10px;
              }
            }
          }

          // .DatePickerIcon {
          //   top: -10px;
          //   right: 10px;
          //   width: auto;

          //   @include respond(phone) {
          //     top: -5px;
          //   }
          // }
        }

        .DatePicker {
          width: 100%;
        }

        .FormHolder {
          display: flex;
          justify-content: space-evenly;

          .Button {
            border: 4px solid transparent;
            padding: 15px 50px;
            width: 40%;

            &:hover {
              background: #dd002a;
              border: 4px solid rgba(159, 2, 32, 0.6);
              box-shadow: 0px 9px 24px rgba(179, 10, 26, 0.6);
              color: $white;
            }

            &.Inverted {
              background-color: #fff;
              color: $main-color;

              &:hover {
                background: #a3a3a3;
                border: 4px solid rgba(0, 0, 0, 0.1);
                box-shadow: 0px 9px 14px rgba(191, 191, 191, 0.3);
                color: #fff;
              }
            }

            @include respond(phone) {
              padding: 10px 20px;
            }
          }
        }

        .ButtonHolder {
          .Button {
            border: 4px solid transparent;

            &.DisabledButton {
              background: #a25665;
              pointer-events: none;
            }

            &:hover {
              background: #dd002a;
              border: 4px solid rgba(159, 2, 32, 0.6);
              box-shadow: 0px 9px 24px rgba(179, 10, 26, 0.6);
              color: $white;
            }
            @include respond(phone) {
              padding: 10px 20px;
            }
          }
        }
      }
    }
  }
}

.DatePickerWrapper {
  position: relative;
  .react-date-picker__wrapper {
    border-radius: 7px;
    height: 40px;
    margin-bottom: 20px;
    padding: 0 10px;

    .react-date-picker__inputGroup {
      input {
        height: 40px !important;
      }
      .react-date-picker__inputGroup__input {
        &:focus-visible {
          outline: none;
        }
      }
    }
  }

  .Example {
    color: silver;
    font-size: 12px;
    position: absolute;
    left: 287px;
    top: 10px;
    @include respond(smallPhone) {
      top: 13px;
      left: 130px;
      font-size: 10px;
    }
  }
}
