@import '../../../variables';
@import '../../../mixins';

.SelectField {
  height: 70px;

  .Select {
    height: 40px;
    padding-left: 30px;
    border: 1px solid $basic-input-border;
    box-sizing: border-box;
    border-radius: 8px;
    outline: none;
    width: 100%;

    @include respond(phone) {
      padding-left: 5px;
    }
  }

  .Error-Container {
    color: red;
    font-size: 12px;
  }
}
