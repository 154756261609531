@import '../../mixins';

.LogoComponent {
  display: flex;

  &:hover {
    cursor: pointer;
  }

  img {
    width: 256px;
    @include respond(phone) {
      width: 230px;
      height: 37px;
    }
  }

  .ImageComponent:nth-child(2) {
    margin-left: 5px;
  }
}
