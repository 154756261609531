.page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 80px;
  min-height: calc(100vh - 329px - 80px);
}

@media only screen and (max-width: 600px) {
  .page {
    padding: 30px;
  }

  .page:has(.card-credit-v2) {
    padding: 30px 15px;
  }
}
