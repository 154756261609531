@import '../../../mixins.scss';

.header {
  font-family: 'Raleway', serif;
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 132%;
  text-align: center;
  margin-bottom: 15px;
  span {
    color: #9f0220;
  }
}
.hurry {
  font-family: 'Open Sans', serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #737373;
  margin-bottom: 35px;
}
@include respond(phone) {
  .special-offer {
    .ant-modal-content {
      .ant-modal-close {
        top: 5px;
        inset-inline-end: 5px;
      }
    }
  }
}
