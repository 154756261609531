@import '../../../../../../../../mixins.scss';

.MoreOptionsButton {
  display: flex !important;
  text-transform: unset !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  background-color: #ffffff !important;
  position: relative !important;

  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 22px !important;
  letter-spacing: 0 !important;
  color: #1e1e1e !important;
  font-family: 'Open Sans' !important;

  @include respond(phone) {
    font-size: 14px !important;
  }

  &:hover {
    background: #ffffff !important;
    border-color: #ffffff !important;
    color: #1e1e1e !important;
  }

  .googleLoginHide,
  .anticon {
    display: none;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    background-size: contain;
    width: 43px;
    height: 100%;
    background-repeat: no-repeat;
    background-image: url('../../../../../../../../assets/images/Lander11/mail.png');
  }

  &Google {
    background-color: #5383ec !important;
    padding: 10px !important;
    color: #ffffff !important;
    margin-top: 10px;

    span {
      @include respond(phone) {
        font-size: 14px !important;
        margin-left: 40px;
      }
    }

    &:hover {
      background: #5383ec !important;
      color: #ffffff !important;
      border-color: #5383ec !important;
    }

    &::after {
      background-image: url('../../../../../../../../assets/images/Lander11/google-plus.png');
    }
  }
}
