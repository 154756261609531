@import '../../../../../../variables.scss';
@import '../../../../../../mixins.scss';
a {
  text-decoration: none;
}
.FooterWrapper {
  transition: 850ms;
  width: 100%;
  margin-left: auto;
  background-color: #000;

  @include respond(phone) {
    margin-top: 80px;
  }

  &.SideBarOpen {
    width: calc(100% - 250px);
    transition: 350ms;
  }

  .UpperFooter {
    a {
      color: $footer-text-color;
    }
    color: $footer-text-color;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 269px;
    max-width: 80%;
    margin: auto;

    @include respond(phone) {
      display: flex;
      margin: auto;
      flex-direction: column;
      height: 100%;
      padding: 10px;
      max-width: 100%;
    }

    @include respond(tablet) {
      margin: auto;
      max-width: 90%;
      padding: 0;
      display: flex;
      justify-content: space-between;
      gap: 25px;
    }

    .Links-Header {
      margin-bottom: 30px;
      margin-top: 25px;
      font-weight: 700;
      font-family: 'Raleway', serif;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.03em;
      color: #fff;
      white-space: nowrap;

      @include respond(phone) {
        margin: 15px 0 10px 0;
      }
    }

    .LogoText-Wrapper {
      margin-top: 10px;
      font-family: 'Roboto', serif;
      display: flex;
      flex-direction: column;
      text-align: center;

      @include respond(phone) {
        width: 90%;
        margin: auto;
        margin-top: 30px;
        text-align: center;
      }

      img {
        cursor: pointer;
      }

      p {
        font-size: 14px;
        line-height: 24px;
        color: #b8b8b8;
      }
      .Para-SidebarOpen {
        font-family: 'Roboto', serif;
        font-size: 14px;
        line-height: 24px;
        color: #b8b8b8;

        @media (min-width: 721px) and (max-width: 750px) {
          font-family: 'Roboto', serif;
          font-size: 12px;
          line-height: 21px;
          margin-bottom: 7px;
        }
      }
    }

    .AddressFooter-Sidebar {
      margin: 0;
    }

    .FooterLogo {
      max-height: 37px;
      margin-top: 20px;
      margin-bottom: 5px;
    }

    .Footer-Links {
      display: flex;
      justify-content: center;
      width: 100%;
      margin: 0;

      li {
        cursor: pointer;
      }

      @include respond(phone) {
        display: flex;
        flex-direction: column;
        margin: auto;
        width: 100%;
        text-align: center;
      }

      @include respond(tablet) {
        margin: 0;
        width: 100%;
      }

      .Profile-Links {
        list-style-type: none;
        padding: 0;
        display: flex;
        flex-direction: column;
        margin: 0;
      }

      .Quick-Links {
        display: flex;
        flex-direction: column;
        text-align: center;
        justify-content: center;
        align-items: center;
        // width: 100%;

        height: 100%;
        margin: auto;

        @include respond(phone) {
          width: 100%;
          order: 1;
        }

        .Quick {
          @media (min-width: 721px) and (max-width: 992px) {
            margin-right: 10px;
          }
        }

        .Quick-Links-List {
          list-style-type: none;
          padding: 0;
          display: flex;
          flex-direction: column;
          align-items: center;
          margin: 0;
          font-family: 'Open Sans', serif;
          font-size: 14px;
          line-height: 28px;

          @include respond(phone) {
            align-items: center;
          }
        }
      }

      .QuickLink {
        margin: auto;
      }

      .Account-Links {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        // width: 100%;
        text-align: center;
        height: 100%;
        margin: auto;

        @include respond(phone) {
          width: 100%;
        }

        .Account {
          @media (min-width: 790px) and (max-width: 993px) {
            margin-right: 5px;
          }
        }

        .Links-List {
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          white-space: nowrap;
          font-family: 'Open Sans', serif;
          font-size: 14px;
          margin: auto;

          @include respond(phone) {
            flex-direction: column;
            gap: 10px;
          }

          @include respond(tablet) {
            flex-direction: column;
            gap: 10px;
          }
        }

        .Account-Links-List {
          list-style-type: none;
          padding: 0;
          display: flex;
          flex-direction: column;
          align-items: center;
          margin: 0;
          line-height: 28px;

          @include respond(phone) {
            align-items: center;
            width: 100%;
          }

          &:first-child {
            margin-right: 30%;
          }
        }
      }

      .Corporation-Links {
        list-style-type: none;
        padding: 0;
        margin: 0;
        display: flex;
        flex-direction: column;
        line-height: 24px;
        font-family: 'Roboto', serif;
        font-size: 14px;

        @include respond(phone) {
          width: 100%;
          text-align: center;
          margin: auto;
        }

        .Corporation {
          @media (min-width: 721px) and (max-width: 789px) {
            margin-left: 5px;
          }
        }

        .Corporation-Links-List {
          list-style-type: none;
          padding: 0;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          margin: 0;
          line-height: 28px;

          @include respond(phone) {
            align-items: center;
          }

          @media (min-width: 721px) and (max-width: 789px) {
            padding-left: 5px;
          }

          .Address-Li {
            cursor: auto;
          }
        }
      }
    }
  }

  .FooterSideBar {
    max-width: 100%;

    @include respond(tablet) {
      max-width: 100%;
      margin: auto;
      display: flex;
      flex-direction: row;
    }
    .LogoText-Wrapper {
      // width: 100%;

      @include respond(phone) {
        order: 2;
      }

      img {
        @include respond(tablet) {
          margin: 0;
          padding: 0;
          width: 100%;
          margin-top: 15px;
        }
      }
      p {
        @media (min-width: 721px) and (max-width: 729px) {
          font-size: 12px;
        }
      }
    }
    .Footer-Links {
      @include respond(tablet) {
        width: 100%;
      }
    }
  }

  .LowerFooter {
    background-color: $button-color;
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10%;
    height: 60px;
    font-family: 'Roboto', serif;
    font-size: 12px;

    @include respond(phone) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 0;
      padding: 5px;
      line-height: 25px;
      height: 100%;
      margin-top: 40px;
      text-align: center;
    }

    span {
      cursor: pointer;
    }
  }
}

.Copyright-Text,
.Terms-Container {

  @include respond(phone) {
    display: flex;
    flex-direction: column;
    gap: 2px;
    align-items: center;
  }
  .Text_Line_Separator {
    @include respond(phone) {
      display: none;
    }
  }
  a {
    color: white;
  }

  font-family: 'Open Sans', serif;
}

.Footer-Description,
.AddressFooter,
.ContactNumber-Footer {
  font-family: 'Open Sans', serif;
}
