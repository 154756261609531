@import '../../../../../../variables';
@import '../../../../../../mixins';

.WhyChooseFlirt {
  background: $why-choose-flirt-background;
  background-image: url('../../../../../../assets/images/LandingPage/bg_flirt.svg');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;

  .WhyChooseFlirt-Container {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 110px;
    padding-bottom: 90px;

    @include respond(phone) {
      padding: 56px 0 90px;
    }
  }

  .Subtitle {
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color: #101010;
    margin-bottom: 9px;

    @include respond(phone) {
      font-size: 16px;
      text-align: center;
      line-height: 150%;
      width: 55%;
    }
  }

  .MainTitle {
    font-family: 'Raleway', sans-serif;
    font-weight: 700;
    font-size: 40px;
    line-height: 54px;
    text-align: center;
    color: $text-red;
    @include respond(phone) {
      text-align: center;
      margin: 20px 0;
      width: 90%;
      font-weight: 700;
      font-size: 30px;
      line-height: 40px;
    }
  }

  .LandingPageButton {
    width: 250px;
    p {
      color: #fff;
      font-weight: 700;
    }
  }

  .MainContent {
    width: 100%;
    margin-bottom: 60px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 30px;
    margin: 95px 0 55px;

    @include respond(phone) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 20px;
      margin-bottom: 10px;
    }

    @include respond(tablet) {
      max-width: 90%;
    }

    .Content-Box {
      display: flex;
      flex-direction: column;
      align-items: center;

      @include respond(phone) {
        width: 80%;
        margin-bottom: 20px;
      }

      img {
        height: 103px;
      }

      .MainIcon {
        margin-bottom: 30px;
        justify-content: flex-start;
        height: 103px;
        @include respond(phone) {
          margin-bottom: 24px;
        }
      }
      // .DiscreetImage {
      //   @include respond(phone) {
      //     width: 186px;
      //     height: 186px;
      //   }
      // }

      // .ImageComponent {
      //   .FireImage .ExcitingImage {
      //     @include respond(phone) {
      //       width: 140px;
      //       height: 140px;
      //     }
      //   }
      // }

      .Title {
        color: $text-red;
        margin-bottom: 25px;
        font-family: 'Raleway', sans-serif;
        font-weight: 700;
        font-size: 28px;
        line-height: 44px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        @include respond(phone) {
          font-size: 28px;
          line-height: 44px;
          margin-bottom: 8px;
        }
      }

      .Description {
        font-family: 'Open Sans', sans-serif;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        text-align: center;
        color: #000000;

        @include respond(phone) {
          width: 100%;
          min-height: 20px;
        }
      }
    }
  }
}
