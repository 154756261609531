.gallery-tab {
  display: flex;
  align-items: flex-start;

  @media (max-width: 720px) {
    flex-direction: column;
  }

  .form-container {
    flex: 2;
    display: flex;
    width: 100%;

    @media (max-width: 720px) {
      flex-direction: column;
    }

    .gallery-photo-title {
      font-size: 17px;
      font-weight: 600;
      text-align: center;
      margin-bottom: 30px;
      margin-top: 30px;
    }

    .profile-photo-container {
      flex: 1;
    }

    .gallery-photo-container {
      flex: 1;

      @media (max-width: 720px) {
        padding: 20px;
      }

      .ant-upload-drag {
        height: auto;
      }
    }
  }

  .tip-container {
    flex: 1;

    .tip-info-container {
      justify-content: flex-start;
    }
  }
}
