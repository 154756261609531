@import 'src/assets/css/colors';
@import 'src/assets/css/mixins';
@import '../../../../../../../../mixins.scss';

.conversations-container {
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 20px 0 0 0;
  width: 300px;
  min-width: 300px;
  max-width: 300px;
  .tabs-list {
    display: flex;
    flex-direction: row;
    margin: 0;
    justify-content: center;
    grid-gap: 20px;
    padding: 0 0 25px 0;
    .tabs-item {
      text-align: center;
      text-transform: uppercase;
      font-size: 16px;
      cursor: pointer;
      color: #c4c4c4;
      font-family: 'Open Sans', serif;
      font-weight: 700;
      transition: color 0.5s;
      &.active {
        color: $text-dark-blue;
      }
    }
  }
  .conv-list {
    display: flex;
    flex-direction: column;
    overflow: auto;
    position: relative;
    height: 100%;
    .inbox-empty {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      height: 100%;
      h2 {
        font-weight: 900;
        color: rgba(0, 0, 0, 0.2);
      }
    }
    .inbox-item {
      flex-shrink: 0;
      cursor: pointer;
      display: grid;
      grid-template-columns: 45px 60% 20%;
      padding: 10px;
      transition: all 0.3s ease-out;
      &:hover {
        transition: background-color 0.5s;
        background-color: rgba(0, 0, 0, 0.05);
      }
      &.active {
        transition: all 0.1s ease-in;
        border-left: 3px solid;
        border-color: $main;
        background: #ffe6e6;
        border-radius: 5px 0 0 5px;
      }
      .UserPhotoContainer {
        position: relative;
        margin-right: 10px;
        .status {
          position: absolute;
          bottom: 0;
          right: 0;
        }
        .ImageComponent {
          width: 36px;
          height: 36px;
          margin-right: 10px;
          border-radius: 100%;
          overflow: hidden;
          @media screen and (min-width: 500px) and (max-width: 900px) {
            width: 30px;
            height: 30px;
          }
          img {
            width: 100%;
            height: 100%;
            object-position: center;
            object-fit: cover;
          }
        }
      }
      .username {
        @include username;
        overflow: hidden;
      }
      .message-body {
        overflow: hidden;
        white-space: nowrap;
      }
      .meta {
        display: flex;
        align-items: flex-end;
        flex-direction: column;
        justify-content: space-around;
        .time {
          color: #5e6c84;
          font-size: 8px;
          text-align: center;
        }
      }
    }
  }
  .older_messages_wrapper {
    margin-top: auto;
    flex-shrink: 0;
    height: 50px;
    width: 100%;
    max-width: 375px;
    background: #fff;
    color: #921d26;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    border: 1px solid #ece3e3;
    border-radius: 4px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    cursor: pointer;
    .lds-roller {
      width: 40px;
      height: 40px;
    }
    @include respond(phone) {
      max-width: 100%;
      flex-shrink: 0;
    }
    &--title {
      color: #921d26;
      font-family: 'Open Sans', sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 19px; /* 135.714% */
      text-decoration-line: underline;
      margin-bottom: 0;
    }
    .animate-spin {
      animation: spin 1s linear infinite;

      @keyframes spin {
        from {
          transform: rotate(0deg);
        }
        to {
          transform: rotate(360deg);
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    &.hide {
      display: none;
    }
    width: 100%;
    min-width: 100%;
    max-width: 100%;
  }
}
.badge {
  display: flex;
  border-radius: 50px;
  padding: 3px;
  color: white;
  text-align: center;
  align-items: center;
  justify-content: center;
  aspect-ratio: 1;
}
