@import '../../../../../../../../variables.scss';
@import '../../../../../../../../mixins.scss';

.LanderContainer15 {
  &.Version15-3 {
    .RoundButton15 {
      background: #018fde;
      border: 1px solid #018fde;

      &:disabled {
        background: #99d2f2;
        color: #fff;
        cursor: not-allowed;
        border: 1px solid #99d2f2;

        &:hover,
        &:focus,
        &:active {
          color: #fff;
          cursor: not-allowed;
        }
      }
    }
  }
  .RoundButton15 {
    font-family: 'Syne', sans-serif;
    background: #dd3394;
    font-weight: bold;
    font-size: 18px;
    letter-spacing: 0.8px;
    padding: 24px 35px;
    border-radius: 0;
    text-transform: none;
    margin-top: 0;
    border: 1px solid #dd3394;

    @include respond(phone) {
      padding: 22px;
    }

    &:hover {
      cursor: pointer;
    }

    &:disabled {
      background: #f2add4;
      color: #fff;
      cursor: not-allowed;
      border: 1px solid #f2add4;

      &:hover,
      &:focus,
      &:active {
        color: #dd3394;
        cursor: not-allowed;
      }
    }

    @include respond(phone) {
      font-size: 16px;
      padding: 18px;
    }
  }
}
