@import '../../../../../../../../variables.scss';
@import '../../../../../../../../mixins.scss';

.LanderContainer18 {
  .BodyTypeContainer {
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: center;
    height: 100%;
    background-image: url('../../../../../../../../assets/images/Lander18/animated/animated4.gif');
    background-size: cover;
    background-position: center;

    .StepContent {
      background-color: rgba(0, 0, 0, 0.40);
      width: 100%;
    }

    .VerticalOptionMainContainer {

      @include respond(phone) {
        display: grid;
        gap: 10px;
        grid-template-columns: repeat(2, 1fr);
        padding: 10px;
      }
    }
    
    .ButtonContainer {
      width: 60%;
      margin: auto;
      margin-bottom: 40px;

      .Button {
        margin-bottom: 10px;
      }
    }

    .BodyTypeImage {
      height: 288px;
      width: 258px;
      background-size: contain;
    }

    .BodyTypeText {
      color: #fff;
      text-align: center;
      font-size: 24px;
      line-height: 33px;
      padding: 35px 0;
      margin-bottom: 0;
      width: 60%;
      margin: auto;
      font-weight: bold;

      @include respond(phone) {
        font-size: 19px;
      }
    }
  }
}
