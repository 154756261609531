@import '../../../../../../variables.scss';
@import '../../../../../../mixins.scss';

.CountdownLanderNavbar {
  background: #310c0e;
  padding-top: 17px;
  padding-bottom: 17px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    @include respond(phone) {
      height: 30px;
    }
  }
}

.CountdownLanderContainer {
  display: flex;
  font-family: 'Raleway';
  justify-content: center;
  align-items: center;
  height: calc(100vh - 87px);
  background-image: url('../../../../../../assets/images/Lander11/bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  .Countdown {
    background: rgba(22, 15, 15, 0.65);
    padding: 10px 26px;
    position: fixed;
    bottom: 5%;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 27px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #ffffff;

    @include respond(phone) {
      width: 95%;
      font-size: 18px;
      padding: 10px 20px;
      text-align: center;
      bottom: 1%;
    }

    span {
      color: #1cb23d;
    }
  }

  .LoadingOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    background: linear-gradient(
      180deg,
      rgba(49, 12, 14, 0.9) 60.89%,
      rgba(146, 29, 38, 0.9) 124.77%
    );
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      animation: rotate 2s infinite linear;

      @include respond(phone) {
        height: 40px;
      }
    }
  }

  .LanderContent {
    z-index: 1;
    width: 460px;

    @include respond(phone) {
      width: 100%;
      padding-bottom: 74px;
    }

    .LanderContentInner {
      box-sizing: border-box;
      background: #f2efef;
      box-shadow: 4px 4px 9px rgba(31, 29, 29, 0.45);
      border-radius: 9px 0 9px 0;
      padding: 0 !important;
      border: none;

      @include respond(phone) {
        margin: auto 3%;
        padding: 40px 30px;
      }

      .InnerHeader {
        background: #1e1e1e;
        padding-top: 11px;
        padding-bottom: 11px;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 20px;
        line-height: 23px;
        letter-spacing: 0.165em;
        color: #ffffff;
        border-top-left-radius: 9px;
        text-align: center;

        @include respond(phone) {
          flex-direction: column;
          font-size: 16px;
        }
      }

      .InnerContainer {
        padding: 30px 26px;
        display: flex;
        flex-direction: column;
        gap: 15px;

        .CountdownLabel {
          font-weight: 600;
          font-size: 18px;
          line-height: 21px;
          color: #000000;

          @include respond(phone) {
            font-size: 15px !important;
          }
        }

        .CountdownLabelCenter {
          text-align: center;
        }
      }
    }
  }

  .InputsContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    width: 100%;
    margin-top: 30px;
  }

  .ButtonsContianer {
    display: flex;
    justify-content: space-between;
  }

  .CountdownLabelWithBack {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .CountdownBackSm {
      display: none;

      @include respond(phone) {
        display: block;
      }
    }

    .CountdownBackLg {
      @include respond(phone) {
        display: none;
      }
    }

    .CountdownBack {
      img {
        margin-right: 5px;
      }

      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: #929292;
      cursor: pointer;

      @include respond(phone) {
        font-size: 15px;
      }
    }
  }
}

.CountdownSelect {
  background: #ffffff !important;
  border: 1px solid #dfdfdf !important;
  border-radius: 8px !important;
  padding: 6px !important;
  box-shadow: none !important;
  font-family: 'Open Sans', sans-serif !important;

  &:hover {
    border-color: #dfdfdf !important;
    box-shadow: none !important;
  }
}

.CountdownSelectValue,
.CountdownSelectOption,
.CountdownSelectOptionSelected {
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 22px !important;
  color: #929292 !important;
  font-family: 'Open Sans', sans-serif !important;

  @include respond(phone) {
    font-size: 14px !important;
  }
}

.CountdownSelectOptionSelected {
  color: #160f0f !important;
  background-color: #ffffff !important;
  position: relative;
  font-family: 'Open Sans', sans-serif !important;

  &::after {
    content: '';
    position: absolute;
    top: 20%;
    right: 2%;
    width: 21px;
    height: 21px;
    background-image: url('../../../../../../assets/images/Lander11/check.svg');
  }
}

.CountdownSelectOptionFocused,
.CountdownSelectOption:hover {
  background: #f0f0f0 !important;
  color: #160f0f !important;
}

.CountdownSelectMenu {
  margin: 0 !important;
}
