@import '../../variables';

.Checkbox {
  height: 18px;
  position: relative;
  width: 18px;
  border: 1px solid $main-color;
  display: flex;
  justify-content: center;
  align-items: center;
  // border-radius: 100%;
  box-sizing: border-box;

  .Circle {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    &:before {
      content: '\2713';
      color: #fff;
      background-color: $main-color;
      font-weight: 700;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
