@import '../../variables';

.LoaderComponent {
  height: 0;
  width: 0;
  padding: 15px;
  border: 4px solid #cbcbcb;
  border-right-color: #d41411;
  border-radius: 22px;
  animation: rotate 0.5s infinite linear;
}

@keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
