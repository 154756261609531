@import '../../../variables';

.Radio {
  .FormRadioInput {
    display: none;
  }

  .FormRadioLabel {
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;

    .FormRadioButton {
      margin-right: 10px;
    }
  }

  .FormRadioButton {
    height: 18px;
    width: 18px;
    border: 1px solid $main-color;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    box-sizing: border-box;
  }
  .FormRadioButton::after {
    content: '';
    height: 12px;
    width: 12px;
    background: $main-color;
    border-radius: 100%;
    opacity: 0;
    transition: opacity 0.2s;
  }

  .FormRadioInput:checked ~ .FormRadioLabel .FormRadioButton::after {
    opacity: 1;
  }
}
