.chat-starters-list .ant-select-item-option-content {
  overflow: auto;
  white-space: pre-wrap;
}

.ChatStarters {
  background: #fff;
  padding: 12px;
  display: flex;
  align-items: center;
  .label {
    margin-right: 15px;
    color: #d41411;
    font-size: 20px;
    line-height: 25px;
    font-family: 'Lobster', cursive;
    white-space: nowrap;
  }

  @media screen and (min-width: 500px) and (max-width: 900px) {
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
  }

  .ImageComponent {
    margin-right: 20px;
    width: auto;

    @media screen and (min-width: 500px) and (max-width: 900px) {
      width: 60px;
    }

    img {
      @media screen and (min-width: 500px) and (max-width: 900px) {
        width: 100%;
      }
    }
  }

  .SelectContainer {
    flex: 1;
    max-width: 100%;
    width: 200px;

    div.ant-select {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    span.ant-select-selection-search {
      display: none;
    }

    select {
      position: relative;
      box-sizing: border-box;
      padding: 12px 30px;
      width: 100%;
      border: 1px solid #d6d6d6;
      border-radius: 8px;
      color: #5e6c84;
      appearance: none;
      background-color: #fff;
      background-image: url('../../../../../../../../assets/images/down_arrow.png');
      background-position: calc(100% - 20px);
      background-size: 12px;
      background-repeat: no-repeat;
      font-family: 'Roboto', serif;

      @media screen and (min-width: 500px) and (max-width: 900px) {
        padding: 5px 10px;
        font-size: 12px;
        padding-right: 40px;
      }

      &:active,
      &:focus {
        outline: none;
      }
    }
  }
}
.gift-dropdown {
  display: flex;
  align-items: center;
  div.ant-select {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  span.ant-select-selection-search {
    display: none;
  }
}
