.profile {
  position: relative;
  width: 100%;
  padding-top: calc(100% / 0.75); /* Set the aspect ratio here */
  .ant-card {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
    border-radius: 8px;
    overflow: hidden;
    cursor: pointer;
    .overlay {
      background: red;
      opacity: 0;
      border-radius: 0;
      z-index: 999;
    }
    .ant-card-cover {
      height: 100%;
      img {
        height: 100%;
        object-fit: cover;
        transform: scale(1);
        transition: all 0.3s;
      }
    }
    &:hover {
      img {
        transform: scale(1.1);
        transition: all 0.3s;
      }
    }
    .ant-card-body {
      position: absolute;
      padding: 5px;
      z-index: 9;
      bottom: 0;
      width: 100%;
      height: 40px;
      transition: all 0.3s;
      .ant-card-meta-title {
        font-size: 14px;
        color: white;
        -webkit-text-fill-color: white; /* Will override color (regardless of order) */
        font-family: 'Raleway', serif;
        overflow-wrap: break-word;
        white-space: break-spaces;
        font-weight: 900;
      }
      &:hover {
        background-color: rgba(0, 0, 0, 0.41);
        transition: all 0.3s;
        .ant-card-meta-title {
          color: white;
          -webkit-text-fill-color: white; /* Will override color (regardless of order) */
          -webkit-text-stroke: 0 transparent;
        }
      }
    }
    &.hideButton {
      .ant-card-body {
        height: 40px;
      }
    }
    &.showButton {
      .ant-card-body {
        height: 70px;
      }
    }
  }
}
