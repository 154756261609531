@import '../../../../mixins.scss';
@import '../../../../variables.scss';

.SignUpAff5sso {
  min-height: 100vh;
  background: rgba(14, 14, 14, 0.8) url('../../../../assets/images/FunnelPage/aff5background.png');
  background-size: cover;
  background-position: center;

  .TextContainer {
    text-align: center;
    color: #fff;
  }

  .GoogleLoginContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 5px;
    margin-bottom: 10px;

    button {
      width: 100%;
      text-align: center;
      justify-content: center;
      border-radius: 8px !important;
      height: 60px;
    }
  }

  .NavbarAff {
    width: 100%;
    height: 120px;
    padding: 0 30px;

    @include respond(phone) {
      height: 80px;
      opacity: 70%;
    }
  }

  .StepOne {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .MultiContent {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 36px;
      width: 430px;
      // overflow: hidden;

      @include respond(phone) {
        .ImageComponent {
          img {
            width: 100%;
          }
        }
      }

      .CheckedProfile {
        display: flex;
        width: 100%;
        margin-left: 30%;
        align-items: center;

        img {
          margin-right: 10px;
        }
        span {
          font-family: 'Gothic A1';
          font-style: normal;
          font-weight: 900;
          font-size: 18px;
          line-height: 36px;
          color: #ffffff;
        }

        @include respond(phone) {
          margin-left: 10%;
          span {
            font-size: 14px;
          }
        }
      }

      .FinalHead {
        @include respond(phone) {
          margin-top: 2em;
          font-size: 36px;
          line-height: 48px;
          span {
            font-size: 70px;
          }
        }
        p {
          font-family: 'Playfair Display';
          font-style: normal;
          font-weight: 600;
          font-size: 47px;
          line-height: 67px;
          text-align: center;
          color: #ffffff;
          text-shadow: 0px 4px 14px rgba(0, 0, 0, 0.35);

          @include respond(phone) {
            font-size: 30px;
            line-height: 48px;
          }
        }
      }

      .ButtonHolder {
        width: 100%;
        display: flex;
        justify-content: center;
        &.Final {
          .Button {
            width: 100%;
          }
        }
        .Button {
          width: 80%;
          padding: 10px 0 10px 0;

          &.DisabledButton {
            pointer-events: none;
            background: #a25665;
          }
          @include respond(phone) {
            width: 100%;
          }
        }
      }

      p {
        font-family: 'Gothic A1';
        font-style: normal;
        font-weight: normal;
        font-size: 25px;
        line-height: 36px;

        text-align: center;

        color: #ffffff;
      }
      span {
        font-weight: 900;
      }

      @include respond(phone) {
        width: 270px;

        p {
          font-size: 18px;
          line-height: 30px;
        }
      }
      @include respond(phone) {
        margin-bottom: 0px;
      }
      .Button {
        border: 4px solid transparent;
        padding: 10px 50px;
        margin: 20px 0 20px 0;

        p {
          font-weight: 900;
          font-size: 20px;

          @include respond(phone) {
            font-size: 15px;
          }
        }

        &.ButtonMessage {
          margin-top: 19px;
        }

        &:hover {
          background: #dd002a;
          border: 4px solid rgba(159, 2, 32, 0.6);
          box-shadow: 0px 9px 24px rgba(179, 10, 26, 0.6);
          color: $white;
        }
      }

      .InputContainers {
        width: 100%;
        position: relative;
        input {
          height: 60px;
          color: #000;

          @include respond(phone) {
            height: 40px;
          }
        }
        .InputField {
          height: 80px;
          input {
            border: 2px solid transparent;

            &:hover {
              border: 2px solid #dd002a;
              box-shadow: 0px 9px 14px rgba(221, 0, 42, 0.5);
            }
          }
        }
        // .DatePickerField {
        //   height: 80px;
        //   input {
        //     border: 2px solid transparent;

        //     &:hover {
        //       border: 2px solid #dd002a;
        //       box-shadow: 0px 9px 14px rgba(221, 0, 42, 0.5);
        //     }
        //   }
        // }
        .react-date-picker {
          width: 100%;
        }

        .Example {
          @include respond(smallPhone) {
            top: 13px;
            left: 145px;
            font-size: 10px;
          }
        }

        .Checkbox-Container {
          display: flex;
          flex-direction: column;
          margin-bottom: 10px;

          .Checkbox-Holder {
            display: flex;
            .Checkbox {
              margin-right: 10px;
            }
            p {
              font-family: 'Gothic A1';
              font-style: normal;
              font-weight: 700;
              font-size: 14px;
              line-height: 18px;
              color: #ffffff;
            }
          }
        }

        @include respond(phone) {
          .InputField {
            height: 60px;

            .Error-Container {
              font-size: 10px;
            }
          }
          .DatePickerField {
            height: 60px;

            .Error-Container {
              font-size: 10px;
            }
          }
        }

        .DatePickerIcon {
          top: -10px;
          right: 10px;
          width: auto;

          @include respond(phone) {
            top: -10px;
          }
        }
      }
    }
  }

  .StepTwo {
    display: flex;
    justify-content: center;
    height: 100%;

    p {
      position: relative;
      bottom: -12rem;
      left: 1rem;

      font-family: 'Gothic A1';
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 36px;
      text-align: center;
      color: #ffffff;
    }
    .spinner-eff.spinner-eff-3 {
      position: relative;
      @keyframes pulse {
        0% {
          transform: scale(0);
        }

        50% {
          transform: scale(1.3);
          opacity: 0;
        }

        100% {
          transform: scale(1.3);
          opacity: 0;
        }
      }

      @keyframes pulse-2 {
        0% {
          transform: scale(0);
        }

        100% {
          transform: scale(1.3);
          opacity: 0;
        }
      }

      .circle {
        border-radius: 50%;
        left: 0px;
        right: 0px;
        margin: auto;
        transform: scale(1);
        transform-origin: center center;

        &-1 {
          position: absolute;
          width: 440px;
          height: 440px;
          background-color: white;
          left: -5.3rem;
          top: -6rem;
          animation: pulse 1.6s linear 0s infinite;
        }

        &-2 {
          position: absolute;
          width: 340px;
          height: 340px;
          background-color: white;
          animation: pulse-2 1.6s linear 0s infinite;
          top: -3rem;
          left: -1.7rem;
        }

        &-3 {
          position: absolute;
          width: 240px;
          height: 240px;
          background-color: white;
          animation: pulse-2 1.6s linear 0s infinite;
        }
        &-4 {
          position: absolute;
          width: 140px;
          height: 140px;
          background-color: white;
          animation: pulse-2 1.6s linear 0s infinite;
          top: 3.1rem;
        }
        &-5 {
          position: absolute;
          width: 80px;
          height: 80px;
          background: url('../../../../assets/images/FunnelPage/Aff5SpinnerImage.svg');
          top: 5rem;
        }
        &-6 {
          position: absolute;
          width: 35px;
          height: 35px;
          background: url('../../../../assets/images/FunnelPage/Aff5Spinner.svg');
          top: 6.5rem;
          left: 0.6rem;
        }
      }
    }
  }

  .MultiSteps {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    .Profileholder {
      display: flex;
      flex-direction: column;
      width: 440px;
      background-color: white;
      border-radius: 10px;

      @include respond(phone) {
        width: 320px;
      }
      p {
        padding: 20px 0 0 20px;
        font-family: 'Gothic A1';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17.5px;
        color: #000000;
      }
      span {
        font-family: 'Gothic A1';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: #000000;
      }
    }
    .TextHolder {
      display: flex;
      flex-direction: column;
      margin-top: 36px;
      width: 440px;
      p {
        font-family: 'Gothic A1';
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 36px;
        text-align: center;
        color: #ffffff;
      }
      span {
        font-size: 18px;
        line-height: 36px;
        color: #9a9a9a;
        @include respond(phone) {
          font-size: 14px;
        }
      }

      @include respond(phone) {
        width: 300px;
        p {
          font-size: 16px;
          line-height: 30px;
        }
      }
    }
    .ButtonHolder {
      display: flex;
      &:hover {
        cursor: pointer;
      }

      .IconHolder {
        margin-bottom: 20px;
        color: #fff;
        text-align: center;
        display: block;

        @include respond(phone) {
          .ImageComponent {
            img {
              width: 100%;
            }

            &.ProfilePlusIcon {
              cursor: pointer;
              img {
                width: 28px;
              }
            }
          }
        }

        &.AffImageContainer {
          margin-bottom: 40px;
          position: relative;

          @include respond(phone) {
            margin-bottom: 30px;
          }
        }
        .ProfilePlusIcon {
          position: absolute;
          display: block;
          top: 25px;

          @include respond(phone) {
            top: 28px;
          }
        }
      }
    }
  }

  .Loader {
    border: 1px solid #f3f3f3;
    border-top-color: rgb(243, 243, 243);
    border-top-style: solid;
    border-top-width: 1px;
    border-radius: 50%;
    border-top: 1px solid red;
    width: 20px;
    height: 20px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
    margin-right: 10px;
  }

  /* Safari */
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
