@import 'src/assets/css/colors';
@import 'src/assets/css/fonts';

.card-credit-v2 {
  .smallFont {
    font-family: 'Open Sans', serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
    margin-top: 20px;

    @media only screen and (max-width: 600px) {
      display: none;
    }
  }
  .special-header {
    @media only screen and (max-width: 600px) {
      display: none;
    }
  }

  .old-label {
    text-decoration: line-through;
    text-decoration-color: red;
    margin-bottom: 10px;
  }

  .card-wrapper {
    position: relative;
    text-align: center;
    cursor: pointer;
    font-family: 'Open Sans', sans-serif;
    border-radius: 23px;
    border: 1px solid #d8d5d5;
    color: #0a0202;
    min-width: 163px;
    width: 200px;

    @media only screen and (max-width: 600px) {
      min-width: 225px * 0.7;
      width: 100% !important;
      display: flex;
      justify-content: space-between;
      border-radius: 12px;
    }

    .card-header {
      font-family: 'Raleway', serif;
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 15px;
      text-transform: uppercase;
      margin: 11px;

      @media only screen and (max-width: 600px) {
        display: none;
      }
    }

    .card-header-modal-sm {
      display: none;

      @media only screen and (max-width: 600px) {
        display: block;
        margin-bottom: 10px;
        text-transform: uppercase;
        font-size: 13px;
      }
    }

    .card-main-info {
      display: flex;
      align-self: center;
      justify-content: center;
      flex-direction: column;
      padding: 15px 0;
    }

    .card-amount-old {
      text-decoration: line-through;
      font-size: 40px;
      text-decoration-color: #9f0220;

      @media only screen and (max-width: 600px) {
        display: none;
      }
    }

    .card-amount-container {
      @media only screen and (max-width: 600px) {
        position: relative;
        margin: 10px 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 25%;

        &::after {
          content: '';
          position: absolute;
          right: 0;
          top: 0;
          bottom: 0;
          width: 1px;
          background-image: repeating-linear-gradient(
            to bottom,
            #bdbdbd 0,
            #bdbdbd 6px,
            transparent 6px,
            transparent 12px
          ) !important;
        }
      }
    }

    .card-amount {
      font-size: 60px;
      font-weight: 600;
      line-height: 80.4px;

      @media only screen and (max-width: 600px) {
        border-radius: 23px;
        color: #9f0220;
        line-height: 35px;
        background-color: inherit !important;
        font-size: 35px;
        font-weight: 700;
      }
    }

    .card-subtext {
      padding-bottom: 10px;
      border-bottom: 1px solid #c1c1c1;
      text-transform: uppercase;
      font-weight: 400;
      font-size: 14px;
      line-height: 20.23px;
      font-family: 'Jost', sans-serif;
      margin: 0 10px 10px;

      @media only screen and (max-width: 600px) {
        border-bottom: none;
        padding: 0;
        margin: 0;
        color: #0d0d0d;
        text-align: center;
        font-size: 13px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
      }
    }

    .card-price-container {
      @media only screen and (max-width: 600px) {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
      }
    }

    .card-price {
      color: #818181;
      font-weight: 700;
      font-size: 14px;
      line-height: 20.23px;
      font-family: 'Jost', sans-serif;

      @media only screen and (max-width: 600px) {
        color: #767676;
        font-family: 'Open Sans', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
      }
    }

    .card-discount {
      font-family: 'Jost', sans-serif;
      font-size: 20px;
      line-height: 24px;
      font-weight: 600;
      color: #2b0507;
      padding-bottom: 11px;

      @media only screen and (max-width: 600px) {
        color: #0d0d0d;
        font-family: 'Open Sans', sans-serif;
        font-size: 20px;
        font-weight: 700;
        line-height: 20px;
        text-transform: uppercase;
        padding-bottom: 5px;
      }
    }

    .card-buy-now {
      background-color: $main;
      height: 55px;
      padding: 8px 0;
      border-radius: 0 0 23px 23px;

      @media only screen and (max-width: 600px) {
        border-radius: 0 12px 12px 0;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 25%;
        background-color: #9f0220;
      }

      .buy-title-sm,
      .buy-title {
        text-transform: uppercase;
        font-weight: 700;
        line-height: 21.13px;
        color: white;
      }

      .buy-title-sm {
        display: none;

        @media only screen and (max-width: 600px) {
          display: block;
          color: #fffef9;
          text-align: center;
          font-family: 'Open Sans';
          font-size: 22px;
          font-style: normal;
          font-weight: 700;
          line-height: 28px;
        }
      }

      .buy-title {
        @media only screen and (max-width: 600px) {
          display: none;
        }
      }

      .buy-price {
        font-family: 'Open Sans', sans-serif;
        font-weight: 700;
        font-size: 14px;
        line-height: 19.07px;
        color: #ca9c9e;

        @media only screen and (max-width: 600px) {
          color: #fffef9;
          text-align: center;
          font-family: 'Open Sans';
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 20px;
        }
      }
    }

    .card-icon-container {
      position: absolute;
      display: flex;
      flex-direction: column;
      bottom: 0;
      gap: 10px;
      top: -20px;
      right: -25px;
      overflow: visible;
      z-index: 2;

      @media only screen and (max-width: 600px) {
        display: none;
      }
    }
  }

  .overlay {
    background: rgba($bg-color, 0.6);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 23px;

    @media only screen and (max-width: 600px) {
      border-radius: 12px;
    }
  }

  .before-now {
    @media only screen and (max-width: 600px) {
      display: none;
    }
  }

  &-offer-type-header {
    display: none;

    @media only screen and (max-width: 600px) {
      width: 100%;
      background: #01040a;
      display: flex;
      justify-content: space-between;
      padding: 10px 15px;
      top: -3rem;
      right: 0;
      position: absolute;
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
      align-items: center;

      svg {
        width: 30px;
        height: 30px;
      }

      span {
        color: #fff;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: uppercase;
      }
    }
  }

  .dark {
    background-color: #160f0f;

    .card-subtext {
      color: #a4a4a4;
    }

    .card-discount {
      color: #fff;
    }
  }

  @media only screen and (max-width: 600px) {
    width: 100%;
    position: relative;

    &--special-offer,
    &--featured,
    &--limited {
      margin-top: 3rem;

      .card-wrapper {
        border-top-left-radius: 0 !important;
        border-top-right-radius: 0 !important;
        border-top: none !important;
      }

      .card-buy-now {
        border-top-right-radius: 0 !important;
      }
    }

    &--featured {
      .card-wrapper {
        background: #efb025;

        .card-amount {
          color: #262626;
        }

        .card-discount {
          color: #fff;
        }

        .card-price {
          color: #926400;
        }
      }

      .card-credit-v2-offer-type-header span {
        color: #efb025;
      }
    }

    &--limited {
      .card-wrapper {
        background: #36b122;

        .card-amount {
          color: #fff;
        }

        .card-discount {
          color: #fff;
        }

        .card-subtext {
          color: #fff;
        }

        .card-price {
          color: #235a1a;
        }
      }

      .card-credit-v2-offer-type-header span {
        color: #36b122;
      }
    }

    &--special-offer {
      .card-credit-v2-offer-type-header span {
        color: #fff;
      }
    }
  }

  @media only screen and (max-width: 600px) {
    .card-main-info {
      padding: 12px 0;
    }

    .card-amount {
      margin-bottom: 5px;
    }

    .card-discount {
      text-transform: capitalize;
      font-size: 18px;
    }

    .card-price-container {
      flex-direction: column;
    }

    .card-buy-now {
      padding: 0 10px;
      font-size: 13px;

      .buy-title-sm {
        display: none !important;
      }

      .buy-title {
        display: block !important;
      }

      .buy-price {
        color: #efb025;
      }
    }
  }
}
