@import '../../variables.scss';

.MatchGame-Modal {
  padding: 30px;
}

.MatchGame-Header {
  font-family: 'Open Sans';
  font-weight: 700;
  font-size: 30px;
  line-height: 34px;
  text-align: center;
  color: #514f4f;
}

.Filters-Wrapper {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.ThumbLeft {
  z-index: 3;
  bottom: 81%;
  left: 0px;
}

.ThumbRight {
  z-index: 4;
  bottom: 81%;
  right: 0px;
}

.slider {
  position: relative;
  height: 100%;
  width: 100%;
  margin: auto;
}

.HeaderNumbers {
  color: $main-color;
  font-size: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
}

.AgeRangeSlider {
  margin: 0;
  padding: 0;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  white-space: nowrap;
  font-family: 'Raleway';
  color: $credits-dark-text;
}
.AgeRangeSliderNumbers {
  // margin: 0;
  // padding: 0;
  // font-family: 'Roboto';
  // font-weight: 600;
  // font-size: 16px;
  // line-height: 24px;
  white-space: nowrap;

  font-family: 'Raleway';
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: $main-color;
}

.BodyType,
.HairColor {
  display: flex;
  flex-direction: column;
}

label {
  color: #44444f;
  font-family: 'Open Sans';
  font-weight: 600;
  font-size: 16px;
}

.HairOption,
.BodyOption {
  font-family: 'Open Sans';
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #6d6d75;
  outline: none;
  height: 48px;
  border: 1px solid #d6d6d6;
  border-radius: 8px;
}

.HairColor,
.BodyType,
.AgeRange {
  margin-bottom: 40px;
  select {
    text-transform: capitalize;
  }
  .To-Uppercase {
    text-transform: capitalize;
  }
}
