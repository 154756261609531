@import '../../../../../../variables.scss';
@import '../../../../../../mixins.scss';

.LanderContainer18 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  position: relative;
  background-size: cover;

  &.zoomed150 {

    .Button {
      padding: 20px 25px;
    }

    .InputMainContainer {
      .InputContainer18{
        input {
          height: 40px;
          border-radius: 10px;
        }
      }
      .InputField{
        height: 40px !important;
        input {
          height: 40px !important;
          border-radius: 10px !important;
        }
      }
    }
  }

  @include respond(phone) {
    overflow: hidden;
  }

  .LoadingOverlay {
    background: #212121c2;
  }

  .BackgroundOverlay18 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: space-between;
    
    .leftImage, .rightImage {
      width: 33.34%;
      height: 100%;
      background-size: cover;
      background-position: center;
      background-color: rgba(0, 0, 0, 0.60);
    }

    .leftImage {
      background-image: url('../../../../../../assets/images/Lander18/bg-left.png');
    }

    .rightImage {
      background-image: url('../../../../../../assets/images/Lander18/bg-right.png');
      background-position: right;
    }
  }
  @media (max-width: 1020px) and (orientation: 'landscape') {
    padding: 2.5em;
  }

  .LoadingOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      animation: rotate 2s infinite linear;

      @include respond(phone) {
        height: 40px;
      }
    }
  }

  .LanderContent {
    z-index: 1;
    width: 100%;

    @include respond(tablet) {
      width: 80%;
    }

    @include respond(phone) {
      width: 100%;
    }

    @include respond(extraSmallPhone) {
      width: 120%;
    }

    .LanderContentInner18 {
      box-sizing: border-box;
      border: none;
      // padding: 69px 84px;
      height: 100vh;
      width: 33.33333%;
      margin: auto;

      @media only screen and (max-width: 420px) and (min-width: 320px) {
      }

      @include respond(extraSmallPhone) {
        width: 100%;
      }

      @include respond(phone) {
        width: 100%;
      }
    }
  }
}
