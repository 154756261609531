@import '../../../../../../../../mixins.scss';

.MessageInput {
  background: #fff;
  width: 87%;
  margin: auto;
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translate(-50%, 0px);
  padding: 15px;
  padding-bottom: 0;
  border-radius: 8px;
  border: 1px solid #e9edf0;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.04);

  @include respond(phone) {
    bottom: 110px;
  }

  @include respond(tablet) {
  }

  @media screen and (min-width: 500px) and (max-width: 900px) {
    bottom: 10px;
  }

  .UploadImagePreviewContainer {
    position: relative;

    .ImageComponent {
      width: 60px;
      height: 60px;
      margin-bottom: 10px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .removeImage {
      position: absolute;
      top: -9px;
      left: 52px;
      width: 14px;

      path {
        fill: #9f0220;
      }

      &:hover {
        cursor: pointer;
      }
    }
  }

  .InputMessage {
    .TextArea {
      border: none;
      width: 100%;
      height: 53px;
      resize: none;
      font-family: 'Roboto';
      font-size: 16px;

      @media screen and (min-width: 500px) and (max-width: 900px) {
        height: 35px;
        font-size: 12px;
      }

      &:active,
      &:focus {
        outline: none;
      }
      &::placeholder {
        color: #5e6c84;
        font-size: 16px;

        @media screen and (min-width: 500px) and (max-width: 900px) {
          font-size: 12px;
        }
      }
    }
  }

  .ButtonsContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media screen and (min-width: 500px) and (max-width: 900px) {
      height: 30px;
    }

    .AttachmentContainer {
      display: flex;

      .ImageComponent {
        &:hover {
          cursor: pointer;
        }
      }

      .AttachmentIcon {
        margin-right: 15px;
        fill: #344563;

        @media screen and (min-width: 500px) and (max-width: 900px) {
          width: 12px;
        }

        &:hover {
          cursor: pointer;
          fill: #9f0220;
        }
      }

      button {
        &:hover {
          cursor: pointer;
        }
        span {
          @media screen and (min-width: 500px) and (max-width: 900px) {
            width: 12px;
          }
        }
      }
    }

    .SendMessageButtonContainer {
      .ImageComponent {
        &:hover {
          cursor: pointer;
        }

        @media screen and (min-width: 500px) and (max-width: 900px) {
          width: 30px;
        }

        img {
          @media screen and (min-width: 500px) and (max-width: 900px) {
            width: 100%;
          }
        }
      }
    }
  }
}
