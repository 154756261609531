@import '../../../../../../variables';
@import '../../../../../../mixins';
.MyMatches {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  padding: 0 80px;
  min-height: calc(100vh - 329px - 80px);

  @media (min-width: 1025px) {
    padding: 0;
  }

  @include respond(phone) {
    padding: 0;
  }

  @include respond(tablet) {
    padding: 0;
  }

  .EmptyArray {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    min-height: 400px;

    span {
      display: block;
      margin-left: 5px;
      color: $main-color;
      font-weight: 600;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .MyMatchesTitle {
    margin-top: 70px;
    margin-bottom: 60px;
    color: #253858;
    font-size: 34px;
    font-family: 'Roboto', serif;
    font-weight: 700;
  }

  .ProfilesGridContainer {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 10px;
    width: 100%;
    margin: auto;
    margin-bottom: 50px;
    position: relative;

    @media (min-width: 1025px) {
      grid-gap: 10px;
    }

    @media (min-width: 450px) and (max-width: 540px) {
      padding: 0 10px;
    }

    @media (min-width: 541px) and (max-width: 640px) {
      padding: 0 25px;
      grid-gap: 25px !important;
    }

    @media (min-width: 641px) and (max-width: 720px) {
      padding: 0 30px;
      grid-gap: 35px !important;
    }

    @include respond(phone) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 0;
    }

    @include respond(tablet) {
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 20px;
    }

    .ProfileContainer {
      margin: 20px 10px;

      @include respond(phone) {
        margin: 5px;
      }

      @media (max-width: 400px) {
        width: 170px;
      }

      @media (max-width: 360px) {
        width: 160px;
      }

      @media (max-width: 330px) {
        width: 140px;
      }

      .ProfileImageContainer {
        position: relative;
        border-radius: 8px;
        overflow: hidden;
        margin-bottom: 20px;
        height: 303px;
        cursor: pointer;

        @media (min-width: 1025px) {
          height: 303px;
        }

        @include respond(phone) {
          height: 230px;
        }

        @media (max-width: 400px) {
          height: 180px;
        }

        @include respond(tablet) {
          height: 303px;
        }

        .ViewProfileIconContainer {
          position: absolute;
          display: flex;
          justify-content: center;
          align-items: center;
          background: #797979c7;
          right: 15px;
          top: 15px;
          border-radius: 8px;
          width: 38px;
          height: 38px;
          z-index: 2;

          .ImageComponent {
            position: absolute;
            width: auto;
          }

          &:hover {
            cursor: pointer;
          }
        }

        .GradientBackground {
          position: absolute;
          height: 50%;
          width: 100%;
          bottom: 0;
          background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 0.6727065826330532) 0%,
            rgba(255, 255, 255, 0) 100%
          );
          z-index: 1;
        }

        .ProfilePhotoContainer {
          height: 100%;

          .ImageComponent {
            transition: transform 0.6s;
            width: auto;

            img {
              height: 100%;
              width: 100%;
            }
          }
        }

        .UserInfo {
          position: absolute;
          bottom: 10px;
          left: 10px;
          color: #fff;
          z-index: 2;
        }

        &:hover {
          .ProfilePhotoContainer {
            .ImageComponent {
              transform: scale(1.2);
            }
          }
        }
      }
    }

    .SendMessageButtonContainer {
      .MyFavoritesButton {
        position: relative;
        font-size: 16px;
        line-height: 20px;
        color: #ffffff;
        background: $main-color;
        text-transform: uppercase;
        border-radius: 8px;
        padding: 15px 30px;
        cursor: pointer;
        font-family: 'Raleway', serif;
        font-weight: 700;
        font-style: normal;
        text-align: center;
        display: flex;
        align-items: center;

        .ImageComponent {
          position: absolute;
          width: auto;
        }

        span {
          margin-left: 40px;
        }
      }

      &:hover {
        span {
          text-decoration: underline;
        }
      }
    }
  }
}
