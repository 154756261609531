.preview {
  position: fixed;
  width: 100%;
  height: 100vh;
  max-height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0);
  z-index: 10;
  &.visible {
    z-index: 999;
  }
}
.tools {
  position: fixed;
  z-index: 12;
  cursor: pointer;
  color: white;
  font-size: 24px;
  font-weight: bold;
  &.lightbox {
    z-index: 12;
    padding: 5px;
    font-size: 40px;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 8px;
  }
  &.arrow {
    top: 50%;
  }
  &.close {
    top: 5%;
  }
  &.right {
    right: 5%;
  }
  &:hover {
    transform: scale(1.2);
    transition: all 0.3s;
  }
}
