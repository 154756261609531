@import '../../variables.scss';
@import '../../mixins.scss';

.popupMessageTextProfile {
  cursor: pointer;
  padding: 0px 60px 12px 24px;
  color: #712d6b;
  display: flex;

  .iconContainer {
    margin-top: 5px;
    margin-right: 10px;

    .ImageComponent {
      justify-content: start;
    }
  }
  .messageContainer {
    .popupMessageText {
      cursor: pointer;
      color: #fff;
      font-size: 16px;
      font-weight: 600;
    }

    span {
      display: block;
      width: 100%;
      margin-top: 10px;
      color: #fff;
    }
  }
}

.ant-notification {
  margin-right: 0;
  top: 100px !important;

  @include respond(phone) {
    top: 80px !important;
    width: 100%;
  }

  .ant-notification-notice {
    background-color: #da3acc;
    background: #da3acc;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
   
    @include respond(phone) {
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
      margin: auto;
      margin-top: 5px;
      max-width: 95%;
      width: 95%;
    }

    &.custom-class {
      padding: 0;
      background-color: #da3acc;
      color: #fff;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
      padding: 6px;
      z-index: 999;

      @include respond(phone) {
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        margin: auto;
        margin-top: 5px;
        max-width: 95%;
        width: 95%;
      }
    }
  }
}

.custom-class {
  padding: 0;
  background-color: #da3acc;
  color: #fff;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  padding: 6px;

  @include respond(phone) {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    margin: auto;
    margin-top: 5px;
    max-width: 95%;
    width: 95%;
  }
}
