@import '../../../../../../../../variables.scss';
@import '../../../../../../../../mixins.scss';

.LanderContent .LanderContentInner {
  background-image: none !important;
}

.DividerText {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #5f2022;
}

.RedGoogleLoginButton {
  background: #db4a39;
  color: #ffffff;
  padding-top: 12px;
  padding-bottom: 12px;
  border-radius: 9px;
  font-size: 14px;
  text-align: center;
  margin-bottom: 7px;
  display: flex;
  align-items: center;
  justify-content: center;

  @include respond(phone) {
    padding-top: 11px;
    padding-bottom: 11px;
  }

  span svg {
    width: 23px;
    height: 23px;
    margin-right: 20px;
  }

  &:hover {
    cursor: pointer;
    box-shadow: 0px 9px 14px rgba(255, 255, 255, 0.6);
  }

  .googleLoginHide {
    display: none;
  }
}
