@import '../../../variables';
@import '../../../mixins.scss';

.ModalDialog {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  top: 0;
  position: fixed;
  z-index: 1000;

  @include respond(phone) {
    max-height: 100%;
    overflow: auto;
  }

  .CloseButton {
    position: absolute;
    right: 20px;
    top: 20px;
    height: 15px;
    width: 15px;
    cursor: pointer;

    img {
      height: 100%;
      width: 100%;
    }
  }

  .Background.disabled {
    pointer-events: none;
  }

  .Background {
    position: absolute;
    background: $modal-background;
    mix-blend-mode: multiply;
    opacity: 0.93;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
  }

  .ModalContainer.disabled {
    pointer-events: none;
  }

  .ModalContainer {
    display: flex;
    flex-direction: column;
    position: absolute;
    padding: 30px;
    background: $white;
    border-radius: 8px;
    box-sizing: border-box;
    width: 500px;
    overflow: auto;
    max-height: 850px;
    //max-height: 80%;

    @include respond(phone) {
      overflow: auto;
      max-height: 100%;
      width: 90%;
    }

    @include respond(tablet) {
      max-height: 100%;
      overflow: auto;
      width: auto;
    }
  }

  // @media (min-width: 992px) {
  //   .ModalContainer {
  //     // width: calc(100% / 3);
  //     max-width: 500px;
  //     // height: 80%;
  //     padding: 20px;
  //   }
  // }
}

.MatchNoteWrapper {
  background: linear-gradient(180deg, #d41411 0%, #000000 139.94%) !important;
  border-radius: 8px;
}
