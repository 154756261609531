@import '../../../variables';
@import '../../../mixins.scss';

.PurchaseCredits {
  max-width: 620px;
  align-items: center;
  .PurchaseCreditsTitle {
    text-align: center;
    font-size: 20px;
    margin-top: 20px;
    margin-bottom: 25px;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 900;
    line-height: 34px;
    color: #514f4f;

    @include respond(phone) {
      margin: 0;
      font-size: 22px;
    }

    span {
      color: $main-color;
    }
  }

  .PurchaseCreditsSecondTitle {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 15px;
    text-align: center;
    font-size: 20px;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 900;
    line-height: 24px;
    color: #514f4f;

    @include respond(phone) {
      font-size: 13px;
    }

    .TitleWarningIcon {
      width: auto;
      margin-right: 20px;
    }
  }

  .PurchaseCreditsSubtitle {
    text-align: center;
    margin-bottom: 30px;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #000000;

    @include respond(phone) {
      font-size: 12px;
      margin-bottom: 10px;
    }
  }

  .OffersContainer {
    margin: auto auto 20px;
    display: flex;
    flex-wrap: wrap;

    @include respond(phone) {
      display: flex;
      flex-wrap: wrap;
      margin: auto;
      margin-bottom: 10px;
    }

    .CreditsCardHolder {
      display: inline-block;
      flex: 0 0 33.333333%;
      padding: 7px;

      @include respond(phone) {
        flex: 0 0 50%;
      }
    }

    .OfferContainer {
      display: flex;
      align-items: flex-start;
      padding: 20px 5px 15px 40px;
      font-family: 'Open Sans', sans-serif;
      margin-bottom: 10px;
      border-radius: 8px;
      justify-content: space-between;
      background-color: #000;
      position: relative;

      @include respond(phone) {
        margin-bottom: 10px;
        padding: 15px 15px;
      }
      .featured-badge {
        color: $pricing-purple-background;
        position: absolute;
        background-color: $badge-green;
        font-family: 'Roboto', sans-serif;
        font-size: 10px;
        top: -8px;
        left: 20px;
        border-radius: 50px;
        padding: 1px 20px;
        text-align: center;
        text-transform: uppercase;
        font-weight: bolder;
      }
      .MessageContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        font-family: 'Roboto', sans-serif;

        .NumberOfMessages {
          font-size: 24px;
          font-weight: 900;
          line-height: 1;
          @include respond(phone) {
            font-size: 18px;
          }
        }
        .MessageText {
          font-size: 12px;
          text-transform: uppercase;
          font-weight: bold;
          @include respond(phone) {
            font-size: 10px;
          }
        }
      }

      .PriceContainer {
        font-size: 20px;
        font-weight: 700;
        margin-top: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-family: 'Open Sans', sans-serif;
        .Badge {
          color: #29024b;
          font-size: 10px;
          background: #efb025;
          border-radius: 20px;
          padding: 3px 10px;
          font-weight: 700;
          margin-top: 5px;
          display: flex;
          text-align: center;
          justify-content: center;
          align-items: center;

          @include respond(phone) {
            font-size: 8px;
            padding: 3px;
          }
        }
        .discount {
          font-size: 12px;
        }
      }

      .CheckoutContainer {
        display: block;
        position: relative;
        font-size: 22px;
        line-height: 1;
        font-weight: 700;
        margin-top: 10px;
        color: $pricing-golden;
      }

      .Price {
        line-height: 1;
        @include respond(phone) {
          font-size: 16px;
        }
      }

      &:hover {
        cursor: pointer;
      }

      &.Active {
        // border: 3px solid #fff;
        background: $main-color;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.5);

        // .PriceContainer {
        //   // margin-right: 20px;
        // }

        .CheckoutContainer {
          display: block;
          position: relative;
          font-size: 22px;
          color: white;

          .Checkout {
            display: flex;
            justify-content: center;
            align-items: center;

            .CheckImage {
              position: absolute;

              img {
                @include respond(phone) {
                  max-width: 30px;
                  max-height: 30px;
                }
              }
            }
          }
        }
      }
    }
  }

  .Unchecked {
    display: none;
    visibility: hidden;

    @include respond(phone) {
      visibility: visible;
      display: flex;
    }
  }

  .CheckImage-Desktop {
    display: flex;
    visibility: visible;

    @include respond(phone) {
      display: none;
      visibility: hidden;
    }
  }

  .WhyCreditsContainer {
    font-family: 'Roboto', sans-serif;
    margin-bottom: 30px;

    @include respond(phone) {
      margin-bottom: 5px;
    }

    .WhyCreditsTitle {
      font-size: 22px;
      font-family: 'Roboto', sans-serif;
      font-weight: 700;
      color: #514f4f;
      margin-bottom: 5px;

      @include respond(phone) {
        font-size: 20px;
      }
    }

    .WhyCreditsDesc {
      display: flex;
      position: relative;
      padding: 15px 20px;
      margin-left: 5px;

      @include respond(phone) {
        padding: 5px 15px;
      }

      .CheckImageContainer {
        display: flex;
        align-items: center;
        justify-content: center;

        .CheckImage {
          position: absolute;
          width: 30px;

          @include respond(phone) {
            width: 20px;
          }

          img {
            @include respond(phone) {
              width: 100%;
            }
          }
        }
      }

      .WhyCreditsText {
        margin-left: 30px;
        font-family: 'Roboto', sans-serif;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #514f4f;

        @include respond(phone) {
          font-size: 14px;
        }
      }
    }
  }

  .Checkout-Btn-Credits {
    @include respond(phone) {
      margin: 5px;
      font-size: 14px;
      padding: 10px 5px;
    }
  }

  .SecureContainer {
    margin-bottom: 10px;

    .SecureText {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 30px;
      gap: 10px;

      @include respond(phone) {
        margin: 12px;
      }

      .SafePayment {
        font-family: 'Raleway', sans-serif;
        font-weight: 700;
        font-size: 12px;
        line-height: 150%;
        color: #514f4f;

        @include respond(phone) {
          text-align: center;
        }
      }
    }
    .SecureImages {
      display: block;
      width: 90%;
      margin: auto;
      margin-top: 40px;
      border-top: 1px dashed #d3d3d3;
      padding-top: 30px;
      padding-bottom: 10px;

      .MasterVisa {
        width: 73%;
        margin: auto;

        img {
          width: 100%;
        }
      }
    }

    .CheckImage {
      width: fit-content;
    }
  }
}
