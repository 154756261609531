@import '../../../../../../../../variables.scss';
@import '../../../../../../../../mixins.scss';

.LanderContainer14 {
  .RoundButton {
    font-family: 'Syne', sans-serif;
    background: #9f0220;
    font-weight: bold;
    font-size: 18px;
    letter-spacing: 0.8px;
    padding: 23px 35px;
    border-radius: 58px;
    text-transform: none;

    @include respond(phone) {
      padding: 22px;
    }

    &:hover {
      cursor: pointer;
      box-shadow: 0px 1px 14px rgba(255, 255, 255, 0.6);
    }

    &:disabled {
      background: transparent;
      color: #9f0220;
      cursor: not-allowed;
      border: 1px solid #9f0220;
      opacity: 0.3;

      @include respond(phone) {
        opacity: 0.5;
      }

      &:hover,
      &:focus,
      &:active {
        background: transparent !important;
        color: #9f0220;
        cursor: not-allowed;
        border: 1px solid #9f0220;
        opacity: 0.3;

        @include respond(phone) {
          opacity: 0.5;
        }
      }
    }

    @include respond(phone) {
      font-size: 16px;
      padding: 18px;
    }
  }
}
