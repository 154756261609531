.ModalLogo {
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    height: 100%;
    width: 100%;
  }

  .LogoImage {
    height: 30px;
  }

  .LogoText {
    margin-left: 5px;
    height: 15px;

    img {
      height: 100%;
      width: 100%;
    }
  }
}
