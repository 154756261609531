@import '../../../../../../variables';
@import '../../../../../../mixins.scss';

.WelcomeSection {
  height: 120%;
  background-image: url(../../../../../../assets/images/LandingPage/header-img.jpg);
  background-position: bottom;
  background-repeat: no-repeat;
  min-height: 900px;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;

  @include respond(phone) {
    background-position-x: 30%;
    padding-bottom: 85px;
    background-image: url(../../../../../../assets/images/LandingPage/bg_mainheader_mobile.jpg);
  }

  .MainContent {
    height: 100vh;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;

    @include respond(phone) {
      padding-top: 70px;
      display: flex;
      flex-direction: column-reverse;
      margin: 0;
      height: 100%;
    }

    .Main-Content {
      width: 100%;
      @include respond(tablet) {
        margin: auto;
        width: 90%;
        display: flex;
      }
      @include respond(phone) {
        flex-direction: column-reverse;
      }
    }

    .WelcomeText {
      width: 52%;
      color: #fffef9;

      @include respond(phone) {
        margin-top: 330px;
        width: 90%;
        text-align: center;
      }

      .Title {
        font-family: 'Raleway';
        font-size: 54px;
        line-height: 68px;
        font-weight: 700;
        margin-bottom: 24px;

        @include respond(phone) {
          font-size: 44px;
          line-height: 54px;
          text-align: center;
          margin: auto;
        }

        .br {
          @include respond(phone) {
            display: none;
          }
        }
      }

      .Description {
        font-family: Open Sans;
        font-size: 18px;
        line-height: 30px;
        width: 442px;
        height: 85px;

        @include respond(phone) {
          width: 90%;
          height: 100%;
          margin: 15px auto;
          text-align: center;
        }

        @include respond(tablet) {
          width: 100%;
        }
      }
    }

    .Login-Container {
      width: 39%;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      @include respond(phone) {
        width: 80%;
        margin-top: 40px;
      }
    }
    .VeriSign-Logo {
      width: 120px;
      margin-top: 20px;
      @include respond(phone) {
        display: none;
      }
    }
  }
}
