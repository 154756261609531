@import '../../../../mixins.scss';
@import '../../../../variables.scss';

.SignUpAff7 {
  height: 100vh;
  background: linear-gradient(rgba(51, 0, 0, 0.4), rgba(51, 0, 0, 0.3)),
    url('../../../../assets/images/SignUpAff7Photos/image-bg.jpg');
  background-size: cover;
  background-position: center;
  overflow: hidden;
  background-repeat: no-repeat;
  background-attachment: fixed;

  @include respond(phone) {
    overflow: scroll;
    padding-bottom: 100px;
  }

  @include respond(tablet) {
    overflow: scroll;
    padding-bottom: 100px;
  }

  .ProgressBarContainer {
    width: 95%;
    margin: auto;
    padding-top: 15px;
    margin-bottom: 20px;
  }

  .NavbarAff {
    background-color: #000000;
    width: 100%;
    height: 120px;
    padding: 0 30px;
    opacity: 0.7;

    @include respond(phone) {
      height: 80px;
      opacity: 70%;
    }
  }

  .StepsContainer {
    display: flex;
    width: 100%;
    height: 100vh;
    align-items: center;
    justify-content: center;

    @include respond(phone) {
      height: calc(100vh - 40px);
    }
  }

  .Steps {
    width: 35rem;
    margin: auto;
    min-height: 600px;
    // margin-top: 70px;
    padding: 30px 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: rgba(77, 0, 0, 0.6);

    @include respond(phone) {
      min-height: 200px;
      height: fit-content;
      justify-content: center;
    }

    &.AutoHeight {
      min-height: auto;
      @include respond(phone) {
        min-height: auto;
        height: auto;
      }
    }

    &.MobileAdjust {
      @include respond(phone) {
        justify-content: space-around;
      }
    }

    .NumberCounter {
      color: #fff;
      text-align: center;
      font-size: 30px;
    }

    .CounterSubtext {
      color: #fff;
      text-align: center;
      font-size: 20px;
    }

    .Aff7Message {
      display: flex;
      margin-bottom: 20px;
      align-self: flex-start;
      margin-left: 20px;
      visibility: hidden;
      opacity: 0;
      transition: visibility 0s, opacity 0.5s;
      height: 30px;

      @include respond(phone) {
        margin-bottom: 40px;
      }

      &.marginTop {
        margin-top: 20px;
      }

      img {
        height: 48px !important;
        width: 48px !important;
        margin-right: 20px;
        margin-top: 15px;
      }

      @include respond(phone) {
        img {
          width: 50px;
          height: 50px;
          margin-top: 14px;
        }
      }

      .Typing {
        height: 30px !important;
        width: 40px !important;
        margin: 0 !important;
      }

      .RectMessage {
        width: 100%;
        min-height: 45px;
        background: #ffffff;
        padding: 10px;
        border-radius: 10px;
        // padding: 10px 10px 15px 20px;
        margin-left: 15px;
        text-align: end;
        margin: 15px 0;

        @include respond(phone) {
          padding: 10px 10px 10px 20px;
          height: fit-content;
        }

        &.MessageBig {
          height: 70px !important;

          @include respond(phone) {
            height: 90px !important;
          }
        }

        p {
          display: block;
          font-family: 'Gothic A1';
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 30px;
          color: #000000;
          text-align: start;
          margin: 0;
        }

        &::after {
          content: url('../../../../assets/images/FunnelPage/pff4Angle.svg');
          display: block;
          width: 0px;
          background-color: #ffffff;
          position: relative;
          right: 21px;
          bottom: 2rem;
        }

        @include respond(phone) {
          p {
            font-size: 14px;
            line-height: 20px;
          }
        }
      }
      .forDate {
        height: 45px;
        text-align: start;
      }

      .RectMessageTyping {
        width: 100%;
        height: 45px;
        background: #ffffff;
        border-radius: 10px;
        padding: 10px 10px 15px 20px;
        text-align: end;
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.5s ease-in; //nastaviit
        margin: 15px 0;

        &::after {
          content: url('../../../../assets/images/FunnelPage/pff4Angle.svg');
          display: block;
          width: 0px;
          background-color: #ffffff;
          position: relative;
          right: 30px;
          bottom: 2rem;
        }
      }

      .RectMessageNoAvatar {
        width: 100%;
        height: 45px;
        background: #ffffff;
        border-radius: 10px;
        padding: 10px;
        // margin-left: 95px;
        text-align: end;
        margin: 10px 0;
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.5s ease-in; //nastaviit

        &.MessageBig {
          height: 70px !important;
        }

        p {
          font-family: 'Gothic A1';
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 30px;
          color: #000000;
          text-align: start;
          margin: 0;
        }
        @include respond(phone) {
          p {
            font-size: 14px;
            line-height: 20px;
          }
        }
        @include respond(phone) {
          padding: 10px 10px 10px 20px;
          height: fit-content;
        }
      }
      @include respond(phone) {
        .MessageSmall {
          height: 60px !important;
        }
      }
      @include respond(phone) {
        .MessageBig {
          height: 130px !important;
        }
      }
    }

    @include respond(phone) {
      width: 90%;
    }

    .Aff7FormContainer {
      display: flex;
      flex-direction: column;
      align-items: center;

      &.stepSeven {
        max-width: 560px;
      }

      .ClickCard {
        background-image: url(../../../../assets/images/SignUpAff7Photos/image-kind-of-relationships-1.jpeg);
        background-size: cover;
        background-position: center;
        width: 480px;
        height: 120px;
        cursor: pointer;
        color: white;
        font-weight: bold;
        font-size: 20px;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        padding-bottom: 20px;
        margin-bottom: 10px;
        border-radius: 10px;
        opacity: 0;
        transition: opacity 0.5s ease-in;
        pointer-events: none;

        @include respond(phone) {
          height: 80px;
        }

        &.show {
          pointer-events: all;
          opacity: 1 !important;
          visibility: visible !important;
        }

        @include respond(phone) {
          width: 100%;
        }

        &.kind-of-relationship-2 {
          background-image: url(../../../../assets/images/SignUpAff7Photos/image-kind-of-relationships-2.jpeg);
        }
        &.kind-of-relationship-3 {
          background-image: url(../../../../assets/images/SignUpAff7Photos/image-kind-of-relationships-3.jpeg);
        }
        &.a-man {
          background-image: url(../../../../assets/images/SignUpAff7Photos/image-male-variant.jpeg);
        }
        &.a-woman {
          background-image: url(../../../../assets/images/SignUpAff7Photos/image-female-variant.jpeg);
        }
        &.seeking-man {
          background-image: url(../../../../assets/images/SignUpAff7Photos/image-man-variant.jpeg);
        }
        &.seeking-woman {
          background-image: url(../../../../assets/images/SignUpAff7Photos/image-woman-variant.jpeg);
        }
        &.body-type-1 {
          background-image: url(../../../../assets/images/SignUpAff7Photos/image-body-type-variant-1.jpeg);
        }
        &.body-type-2 {
          background-image: url(../../../../assets/images/SignUpAff7Photos/image-body-type-variant-2.jpeg);
        }
        &.body-type-3 {
          background-image: url(../../../../assets/images/SignUpAff7Photos/image-body-type-variant-3.jpeg);
        }
        &.age-1 {
          background-image: url(../../../../assets/images/SignUpAff7Photos/image-age-variant-1.jpeg);
        }
        &.age-2 {
          background-image: url(../../../../assets/images/SignUpAff7Photos/image-age-variant-2.jpeg);
        }
        &.age-3 {
          background-image: url(../../../../assets/images/SignUpAff7Photos/image-age-variant-3.jpeg);
        }
      }

      .big {
        height: 185px;
        width: 480px;

        @include respond(phone) {
          width: 100%;
          height: 140px;
        }
      }

      .Button {
        border: 4px solid transparent;
        padding: 15px 50px;
        width: 100%;
        margin-bottom: 20px;

        &.ButtonMessage {
          margin-top: 19px;
        }

        &.DisabledButton {
          pointer-events: none;
          background: #a25665;
        }

        &:hover {
          background: #dd002a;
          border: 4px solid rgba(159, 2, 32, 0.6);
          box-shadow: 0px 9px 24px rgba(179, 10, 26, 0.6);
          color: $white;
        }

        &.Inverted {
          background-color: #fff;
          color: $main-color;

          &:hover {
            background: #a3a3a3;
            border: 4px solid rgba(0, 0, 0, 0.1);
            box-shadow: 0px 9px 14px rgba(191, 191, 191, 0.3);
            color: #fff;
          }
        }

        @include respond(phone) {
          padding: 10px 20px;
          margin: 0 10px 20px 10px;
          width: 100%;
          margin-left: 0;
        }
      }

      .InputMainContainer {
        width: 100%;
        opacity: 0;
        transition: opacity 0.5s ease-in;
      }

      .TextContainer {
        color: #fff;
        text-align: center;
        font-size: 18px;
      }

      .GoogleLoginContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-top: 20px;
        margin-bottom: 20px;

        iframe {
          width: 100% !important;
        }

        #container {
          width: 100% !important;
          padding: 0 !important;

          div {
            max-width: 100% !important;
          }
        }
        .GoogleLoginButton {
          width: 100%;
        }

        button {
          width: 100%;
          text-align: center;
          justify-content: center;
          border-radius: 8px !important;
          height: 60px;
        }
      }

      .InputContainers {
        width: 100%;
        position: relative;
        input {
          height: 60px;
          color: #000;

          @include respond(phone) {
            height: 40px;
          }
        }
        .InputField {
          height: 80px;
          input {
            border: 2px solid transparent;

            &:hover {
              border: 2px solid #dd002a;
              box-shadow: 0px 9px 14px rgba(221, 0, 42, 0.5);
            }
          }
        }
        // .DatePickerField {
        //   height: 80px;
        //   input {
        //     border: 2px solid transparent;

        //     &:hover {
        //       border: 2px solid #dd002a;
        //       box-shadow: 0px 9px 14px rgba(221, 0, 42, 0.5);
        //     }
        //   }
        // }
        .react-date-picker {
          width: 100%;
        }
        .Example {
          left: 392px;
          @include respond(smallPhone) {
            top: 13px;
            left: 220px;
            font-size: 10px;
          }
        }
        .Checkbox-Container {
          display: flex;
          flex-direction: column;
          margin-bottom: 10px;

          .Checkbox-Holder {
            display: flex;
            .Checkbox {
              margin-right: 10px;
            }
            p {
              font-family: 'Gothic A1';
              font-style: normal;
              font-weight: 700;
              font-size: 14px;
              line-height: 18px;
              color: #ffffff;
            }
          }
        }

        @include respond(phone) {
          .InputField {
            height: 60px;

            .Error-Container {
              font-size: 10px;
            }
          }
          .DatePickerField {
            height: 60px;

            .Error-Container {
              font-size: 10px;
            }
          }
        }

        .DatePickerIcon {
          top: -10px;
          right: 10px;
          width: auto;

          @include respond(phone) {
            top: -10px;
          }
        }
      }

      .ButtonContainers {
        display: flex;
        width: 100%;
        justify-content: space-between;

        .DropDownContainer {
          width: 48%;
          display: flex;
          flex-direction: column;

          select {
            border: 2px solid transparent;

            &:hover {
              border: 2px solid #dd002a;
              box-shadow: 0px 9px 14px rgba(221, 0, 42, 0.5);
            }
          }
        }

        .Dropdown-Select {
          background-color: #ffffff;
          margin-bottom: 0px;
        }
      }
    }
    .stepTwo {
      margin-top: 20px;
    }
  }
}

.show {
  opacity: 1 !important;
  visibility: visible !important;
}

.Avatar {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.5s ease-in;
}

.noMargin {
  margin-top: 0 !important;
}
