@import './variables.scss';

@mixin respond($breakpoint) {
  @if $breakpoint == phone {
    @media (max-width: 720px) {
      @content;
    }
  } @else if $breakpoint == tablet {
    @media only screen and (max-width: 1024px) and (min-width: 721px) {
      @content;
    }
  } @else if $breakpoint == desktop {
    @media (min-width: 1200px) {
      @content;
    }
  } @else if $breakpoint == smallPhone {
    @media only screen and (max-width: 560px) and (min-width: 320px) {
      @content;
    }
  } @else if $breakpoint == extraSmallPhone {
    @media only screen and (max-width: 320px) and (min-width: 260px) {
      @content;
    }
  }
}

@mixin modalTitle() {
  font-family: 'Raleway', sans-serif;
  font-weight: 700;
  font-size: 40px;
  line-height: 44px;
  color: #212121;
  text-align: center;
  margin-bottom: 20px;
  @include respond(phone) {
    font-size: 35px;
  }
}

@mixin modaSubtitle() {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  color: #666666;
  text-align: center;
}
