@import '../../variables.scss';
@import '../../mixins.scss';

.ValidateEmailTypo {
  text-align: right;
  margin-bottom: 5px;
  color: #fff;

  span {
    font-weight: 800;
    cursor: pointer;
    color: #fff;

    @include respond(phone) {
      font-size: 10px;
    }
  }
}
