@import 'src/assets/css/colors';
.settings-tab {
  display: flex;
  align-items: flex-start;

  @media (max-width: 720px) {
    flex-direction: column;
  }

  .settings-loading {
    display: flex;
    flex: 2;
    justify-content: center;
    align-items: center;
    width: 100%;
    // height: 100%;
    z-index: 999;
  }

  .form-container {
    flex: 2;
    display: flex;
    width: 100%;

    @media (max-width: 720px) {
      flex-direction: column;
    }

    .form-left-side {
      display: flex;
      // justify-content: space-between;
      flex: 1;
      flex-direction: column;
      padding: 20px;

      .remove-card-container {
        margin-top: 30px;
      }
    }

    .form-right-side {
      display: flex;
      // justify-content: space-between;
      flex: 1;
      flex-direction: column;
      padding: 20px;

      .height-container {
        .ant-form-item-label {
          margin-bottom: 30px;
        }

        .ant-slider {
          .ant-slider-track {
            background-color: #f9b6b6;
          }
          .ant-slider-handle {
            &:after {
              box-shadow: 0 0 0 2px $main;
            }
          }
        }
      }
    }
  }

  .tip-container {
    flex: 1;
  }
}
