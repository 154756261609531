@import '../../variables.scss';
@import '../../mixins.scss';

.PicturesWrapper {
  .drag-drop-text {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #253858;
    margin: 33px 0;
    @include respond(phone) {
      max-width: 196px;
      margin: 31px 0 22px 0;
    }
  }
  .DeleteIconProfile {
    height: 100%;
    width: 100%;
  }
  .drag-drop-zone {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 8px;
    background-color: #f3f6f8;
    height: 100%;
    padding: 155px 0 132px;
    @include respond(phone) {
      padding: 62px 0 47px;
    }
  }

  .drag-drop-zone.inside-drag-area {
    opacity: 0.7;
  }
  .dropped-files li {
    color: #07f;
    padding: 3px;
    text-align: left;
    font-weight: bold;
  }

  .DefaultButton {
    margin-top: 40px;
  }
  .button-wrapper {
    padding: 19px 77px 17px 76px;
  }

  .button-wrapper p {
    white-space: nowrap;
  }

  .err-message-container {
    width: 100%;
  }
  .err-message-text {
    margin-top: 15px;
    font-size: 14px;
    color: red;
  }

  .success-message {
    background-color: #6ac85b;
    border: none;
    position: fixed;
    color: white;
    font-family: 'Roboto', serif;
    font-size: 16px;
    line-height: 24px;
    padding: 10px;
    width: 423px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 80px;
    left: 50%;
    transform: translateX(-50%);
  }

  .failure-message {
    background-color: #e54242;
    border: none;
    position: fixed;
    color: white;
    font-family: 'Roboto', serif;
    font-size: 16px;
    line-height: 24px;
    padding: 10px;
    width: 423px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 80px;
    left: 50%;
    transform: translateX(-50%);
  }

  .LoaderComponentWrapper {
    min-height: 400px;
  }

  .ProfileImageWrapper {
    position: relative;
  }
}

.DeleteModal {
  display: flex;
  justify-content: center;
  align-items: center;

  .ant-modal-content {
    width: fit-content !important;
  }
  .ant-modal-body {
    background-color: white;
  }

  .DeletePhotoText {
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #253858;
  }

  .ant-modal-title {
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: #253858;
  }
}

.DeleteIconWrapperProfie {
  cursor: pointer;
  position: absolute;
  right: 0;
  padding: 10px;
  height: 50px;
  width: 50px;
  bottom: 0;
  opacity: 0.7;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: silver;
  border-radius: 50%;
  margin: 10px;
  z-index: 5;
  &:hover {
    opacity: 1;
  }

  &.img {
    height: 20px;
  }
}
