@import '../../../../../../../../variables.scss';
@import '../../../../../../../../mixins.scss';

.VerticalOption18 {
  width: 33%;
  height: 83px;
  margin: auto;
  margin-bottom: 10px;
  cursor: pointer;
  border-radius: 20px;
  border: 1px solid #fff;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;

  &:hover {
    border-color: #FF0050;
  }

  @include respond(phone) {
    width: 100%;
  }

  &.VerticalOptionActive {
    background-color: #FF0050;
  }

  .ThumbImageContainer {
    flex: 1;

    img {
      width: 61px;
      height: 61px;
    }
  }

  span {
    flex: 1;
    z-index: 1;
    position: relative;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    color: #ffffff;
    text-align: center;
    width: 100%;
    margin-left: 10px;
    text-align: left;
    text-shadow: 0px 4px 4px rgba(69, 33, 33, 0.58);

    &.FullText {
      text-align: center;
    }

    @include respond(tablet) {
      font-size: x-small;
    }
    @media only screen and (max-width: 1200px) and (min-width: 721px) {
      font-size: x-small;
    }
  }
}
