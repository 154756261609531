@import '../../../mixins.scss';

.MatchNote {
  padding: 40px;
  position: relative;
  background-image: url('../../../assets/images/MatchGame/backgroundFire.png');
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  background-position-x: center;

  @include respond(phone) {
    padding: 10px;
  }

  .BackgroundFire {
    position: absolute;
    top: 0;
  }
  .itsMatch {
    margin: 0 auto;
    @include respond(phone) {
      height: 40px;
    }
  }
  .Subtitle {
    font-family: ' Open Sans', sans-serif;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #ffffff;
    margin-top: 30px;
    margin-bottom: 45px;
  }

  .ProfileWrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 55px;
    @include respond(phone) {
      margin-bottom: 20px;
    }
  }
  .ProfilePhoto {
    height: 160px;
    width: 160px;
    border-radius: 50% !important;
    object-fit: cover;
    object-position: center;

    @include respond(tablet) {
      height: 140px;
      width: 140px;
    }

    @include respond(phone) {
      height: 120px;
      width: 120px;
    }
  }

  .matchImageWrapper {
    height: 160px;
    width: 160px;
    border-radius: 50% !important;
  }

  .MatchButton {
    background-color: white;
    margin-bottom: 30px;
    color: rgba(212, 20, 17, 1);
    @include respond(phone) {
      margin-bottom: 12px;
    }
  }

  .MatchButton1 {
    background-color: transparent;
    border: 2px white solid;
    margin-bottom: 30px;
  }
}
