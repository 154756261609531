@import '../../../mixins.scss';

.tip-info-container-mobile {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 5fr;
  align-items: center;
  justify-content: center;
  border: 1px solid #f9b6b6;
  background-color: #ffdada;
  color: #9f0220;
  padding-left: 15px;

  @include respond(phone) {
    margin-bottom: 20px;
    width: 100%;
  }

  .image-box {
    width: 80px;
    height: 80px;
    overflow: hidden;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 100%;
      height: 100%;
      overflow: hidden;
      border-radius: 100%;
    }
  }

  .tip-text {
    padding: 15px;
    border-radius: 8px;
    margin-top: 20px;
  }
}
