@import '../../../../../../../../variables.scss';
@import '../../../../../../../../mixins.scss';

.LanderContainer18 {
  .EmailShorterContainer {
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: center;
    height: 100%;
    background-image: url('../../../../../../../../assets/images/Lander18/animated/animated10.gif');
    background-size: contain;
    background-position: top;
    background-repeat: no-repeat;
    background-color: #151515;

    &.zoomed125 {
      .EmailShortList {
        margin-left: 20px;
      }
    }
    &.zoomed150 {

      .EmailShortText {
        padding: 10px 0;
      }

      .EmailShortList {
        margin-left: 10px;
      }

      .InputMainContainer {
        .InputContainer18{
          input {
            height: 40px;
            border-radius: 10px;
          }
        }
        .GreyGoogleLoginButton {
          height: 40px;
          border-radius: 10px;
          margin-bottom: 10px;
        }
      }

      .ButtonContainer {
        margin-bottom: 20px;

        .Button {
          padding: 20px 25px;
        }
      }
    }

    .StepContent {
      background-color: #151515;
      width: 100%;
    }

    .InputMainContainer {
      text-align: center;

      .InputContainer18 {
        width: 80%;
        margin: auto;
        margin-bottom: 20px;

        input {
          border: 1px solid #fff;
          background-color: transparent;
          color: #fff;
          height: 70px;
          border-radius: 20px;

          @include respond(phone) {
            height: 60px;
          }

          ::placeholder {
            color: #fff;
          }
        }
        ::placeholder {
          color: #fff;
        }
      }

      .GreyGoogleLoginButton {
        width: 80%;
        margin: auto;
        margin-bottom: 30px;
        border: 1px solid #6C5D5D;
        background-color: #372F2F;
        color: #fff;
        height: 70px;
        border-radius: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 24px;
        font-weight: bold;

        @include respond(phone) {
          height: 60px;
          margin-bottom: 20px;
        }

        &:hover {
          cursor: pointer;
        }
      }
    }

    .ButtonContainer {
      width: 80%;
      margin: auto;
      margin-bottom: 40px;

      .Button {
        margin-bottom: 10px;
      }
    }

    .EmailImage {
      height: 288px;
      width: 258px;
      background-size: contain;
    }

    .EmailShortText {
      color: #fff;
      text-align: center;
      font-size: 24px;
      line-height: 33px;
      padding: 35px 0;
      margin-bottom: 0;
      width: 100%;
      margin: auto;
      font-weight: bold;

      @include respond(phone) {
        font-size: 19px;
        padding-top: 10px;
        padding-bottom: 15px;
      }
    }

    .EmailShortList {
      width: 100%;
      margin-left: 80px;
      margin-bottom: 10px;
      color: #58FFF5;
      text-align: left;
      text-shadow: 0px 2px 4px rgba(1, 1, 1, 0.37);
      font-size: 18px;
      font-style: normal;
      font-weight: 400;

      @include respond(phone) {
       margin-left: 10px;
       font-size: 16px;
      }

      img {
        margin-right: 10px;
      }
    }
  }
}
