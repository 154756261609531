@import '../../../../../../variables';
@import '../../../../../../mixins';

.HomePageDescription {
  background: $home-page-description-background;

  .HomePageDescription-Container {
    height: 100%;
    padding: 110px 0;
    padding-bottom: 40px;
    @include respond(phone) {
      padding: 55px 0;
    }
  }

  .HomePageDescription-Content {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    align-items: center;

    @include respond(phone) {
      display: flex;
      flex-direction: column-reverse;
    }

    @include respond(tablet) {
      padding: 20px;
    }

    .Images-Container {
      width: 100%;
      margin-right: -48px;
      overflow: hidden;
      @include respond(tablet) {
        margin-right: 0;
      }
      @include respond(phone) {
        margin-right: 0;
      }

      img {
        width: 100%;
        height: 100%;

        @include respond(phone) {
          width: 120%;
        }
        @include respond(tablet) {
          width: 100%;
        }
      }
    }

    .Text-Container {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;

      @include respond(phone) {
        text-align: center;
        margin: auto 30px;
        margin-bottom: 50px;
      }

      .FlirtNearYou-Text {
        font-family: 'Open Sans', sans-serif;
        font-weight: 800;
        font-size: 16px;
        color: #101010;
        &-light {
          font-weight: 700;
        }
        @include respond(phone) {
          margin-bottom: 17px;
        }
      }

      .SignUp-HeaderText {
        font-family: 'Raleway';
        color: $text-red;
        line-height: 54px;
        font-size: 40px;
        margin-bottom: 17px;
        font-weight: 700;

        @include respond(phone) {
          font-size: 30px;
          line-height: 40px;
          margin-bottom: 30px;
        }
      }

      .FlirtNearYou-Description {
        font-family: 'Open Sans', sans-serif;
        color: $small-text-color;
        line-height: 30px;
        font-size: 18px;
        margin-bottom: 28px;

        @include respond(phone) {
          width: 100%;
        }
      }

      .HomeSignUpButton-Container {
        display: block;
        margin-top: 20px;

        @include respond(phone) {
          margin: auto;
        }

        .LandingPageButton {
          width: 250px;
          p {
            color: #fff;
            font-weight: 700;
          }
        }
      }
    }
  }
}
