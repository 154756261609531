@import '../../../variables';

.InputField {
  height: 70px;

  .Input {
    height: 40px;
    padding-left: 30px;
    border: 1px solid $basic-input-border;
    box-sizing: border-box;
    border-radius: 8px;
    outline: none;
    width: 100%;
  }

  .see-password {
    position: relative;
    .eye-icon {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      cursor: pointer;
      padding: 10px 15px;
      font-size: 20px;
      color: #777;
    }
  }

  .Error-Container {
    color: red;
    font-size: 12px;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
