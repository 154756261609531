.ConversationUserListAllContainer {
  height: calc(100vh - 275px);
  overflow: auto;
  flex: 1;
  background-color: #fff;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;

  .HasMore {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-left: 5px solid transparent;
    color: #253858;
    font-size: 14px;
    font-weight: 500;
    font-family: 'Open Sans';
  }

  .UserInfoContainer {
    display: flex;
    align-items: center;
    padding: 10px;
    border-left: 5px solid transparent;

    &:hover {
      cursor: pointer;
    }

    &.Active {
      border-left: 5px solid #d41411;
      background-color: #ffe6e6;
    }

    .UserPhotoContainer {
      flex: 1;
      position: relative;
      .ImageComponent {
        width: 36px;
        height: 36px;
        margin-right: 10px;
        border-radius: 100%;
        overflow: hidden;

        @media screen and (min-width: 500px) and (max-width: 900px) {
          width: 30px;
          height: 30px;
        }

        img {
          width: 100%;
          height: 100%;
        }
      }

      .OnlineStatusContainer {
        position: absolute;
        width: 36px;
        height: 36px;
        top: 0;
        left: 0;

        .OnlineStatus {
          display: block;
          position: absolute;
          right: -3px;
          bottom: -3px;
          border: 2px solid #fff;
          width: 9px;
          height: 9px;
          border-radius: 100%;
          background-color: #6ac85b;

          @media screen and (max-width: 500px) {
            width: 9px;
            height: 9px;
            right: -3px;
            bottom: -3px;
          }
        }
      }
    }

    .UserNameContainer {
      flex: 2;
      overflow: hidden;

      .Username {
        color: #253858;
        font-size: 16px;
        font-weight: 500;
        font-family: 'Open Sans';

        @media screen and (min-width: 901px) and (max-width: 1024px) {
          font-size: 13px;
        }

        @media screen and (min-width: 500px) and (max-width: 900px) {
          font-size: 9px;
        }
      }

      .MessagePreview {
        color: #5e6c84;
        font-size: 14px;
        clear: both;
        display: inline-block;
        white-space: nowrap;
        width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
        font-family: 'Open Sans';

        @media screen and (min-width: 901px) and (max-width: 1024px) {
          font-size: 11px;
        }

        @media screen and (min-width: 500px) and (max-width: 900px) {
          font-size: 8px;
        }

        p {
          font-size: 14px;
          margin: 0;

          @media screen and (min-width: 901px) and (max-width: 1024px) {
            font-size: 11px;
          }

          @media screen and (min-width: 500px) and (max-width: 900px) {
            font-size: 8px;
          }
        }
      }
    }

    .Notifications-Info {
      display: flex;
      align-content: space-between;
      width: 79%;

      .StatusContainer {
        margin-left: 5px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .Timecontainer {
          color: #5e6c84;
          font-size: 13px;
        }

        .MessageCountContainer {
          span {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 10px;
            margin-top: 5px;
            width: 16px;
            height: 16px;
            border-radius: 100%;
            background-color: #d41411;
            color: #fff;
          }
        }

        .none {
          display: none;
        }
      }
    }
  }

  .LoadMoreConvContainer {
    text-align: center;
    padding: 20px 10px;
    font-size: 12px;
  }
}
