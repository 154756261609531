@import '../../../../../../../variables.scss';
@import '../../../../../../../mixins.scss';

.ChatContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 45px;
}

.MessageContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1 auto;
  width: 100%;

  &:first-child:not(:only-child) {
    .Message {
      background: linear-gradient(
        0deg,
        #310c0e -64.05%,
        rgba(255, 177, 192, 0.07) 60.72%,
        rgba(213, 184, 190, 0.07) 78.41%
      );
      color: rgba(49, 12, 14, 0.38);
    }
  }

  &:last-child {
    .Message::after {
      right: 100%;
      top: 50%;
      border: solid transparent;
      content: '';
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-color: rgba(95, 32, 34, 0);
      border-right-color: #5f2022;
      border-width: 7px;
      margin-top: -7px;
    }
  }
}

.Message {
  background: #5f2022;
  color: #ffffff;
  border-radius: 9px;
  padding: 11px 25px;
  position: relative;
  font-weight: 400;
  font-size: 16px;
  line-height: 144.4%;
  white-space: pre-line;

  @include respond(phone) {
    font-size: 14px;
    padding: 11px 15px;
    margin-bottom: 7px;
  }
}

.AvatarContainer {
  height: 53px;
  margin-right: 5px;
  flex-basis: 60px;

  @include respond(phone) {
    flex-basis: 53px;
    margin-right: 12px;
  }

  img {
    height: 100%;
    border-radius: 50%;
  }
}

.typing {
  display: none;
  font-size: 0;
  animation: fadeInUp 400ms linear 1 both; /* Updated duration */

  &__bullet {
    display: inline-block;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.3);
    transition: all 600ms linear; /* Updated duration */

    &:not(:last-child) {
      margin-right: 3px;
    }
  }

  &.is-typing-init {
    display: inline-block;
  }

  &.is-typing-active {
    .typing__bullet {
      background-color: #fff;
      animation: bounce 1200ms linear infinite both; /* Updated duration */

      &:nth-child(2) {
        animation-delay: 300ms; /* Updated delay */
      }

      &:nth-child(3) {
        animation-delay: 600ms; /* Updated delay */
      }
    }
  }

  @keyframes bounce {
    0%,
    80%,
    100% {
      opacity: 1;
      transform: translateY(0);
    }
    50% {
      opacity: 0.5;
      transform: translateY(-100%);
    }
  }

  @keyframes fadeInUp {
    0% {
      opacity: 0;
      transform: translateY(50%);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
}
