@import '../../../../../../../variables.scss';
@import '../../../../../../../mixins.scss';

.ChatContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 45px;
  overflow: hidden;
  margin-left: 40px;
  margin-top: 10px;
  width: 90%;

  .hidden {
    display: none;
  }

  @include respond(phone) {
    margin-bottom: 25px !important;
    margin-left: 10px !important;
    margin-top: 0px !important;
  }
}

.MessageContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1 auto;
  width: 100%;

  // &:first-child:not(:only-child) {
  //   .Message {
  //     background: transparent;
  //     color: rgba(192, 100, 153, 1);
  //   }
  // }
}

.Message {
  // background: #5F2022;
  color: #ffffff;
  border-radius: 9px;
  padding: 11px 25px;
  position: relative;
  font-weight: 400;
  font-size: 16px;
  line-height: 144.4%;
  white-space: pre-line;

  @include respond(phone) {
    font-size: 14px;
    padding: 5px;
    margin-bottom: 3px;
  }
}

.LanderContainer15 {
  .AvatarContainer {
    height: 53px;
    width: 53px;
    margin-right: 20px;
    flex-basis: 53px;

    @include respond(phone) {
      margin-right: 2px;
      height: 30px;
      width: 30px;
      flex-basis: 30px;
    }

    img {
      height: 100%;
      border-radius: 10px;
    }
  }
}

.typing {
  display: none;
  font-size: 0;
  animation: fadeInUp 400ms linear 1 both; /* Updated duration */

  &__bullet {
    display: inline-block;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.3);
    transition: all 600ms linear; /* Updated duration */

    &:not(:last-child) {
      margin-right: 3px;
    }
  }

  &.is-typing-init {
    display: inline-block;
  }

  &.is-typing-active {
    .typing__bullet {
      background-color: #fff;
      animation: bounce 1200ms linear infinite both; /* Updated duration */

      &:nth-child(2) {
        animation-delay: 300ms; /* Updated delay */
      }

      &:nth-child(3) {
        animation-delay: 600ms; /* Updated delay */
      }
    }
  }

  @keyframes bounce {
    0%,
    80%,
    100% {
      opacity: 1;
      transform: translateY(0);
    }
    50% {
      opacity: 0.5;
      transform: translateY(-100%);
    }
  }

  @keyframes fadeInUp {
    0% {
      opacity: 0;
      transform: translateY(50%);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
}
