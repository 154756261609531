.CountdownPolicy {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  color: #000000;

  span,
  a {
    color: rgba(159, 2, 32, 1);
    font-weight: 500;
    cursor: text;
  }
}
