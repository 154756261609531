.gallery-upload {
  .gallery-images-container {
    position: relative;
    // display: grid;
    // grid-template-columns: 33% 33% 33%;
    // gap: 5px 0.5%;
    margin-top: 30px;
    margin-bottom: 50px;

    .gallery-loader {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: #00000063;
      z-index: 99;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    @media (max-width: 720px) {
      grid-template-columns: 49% 49%;
      gap: 5px 2%;
    }

    .gallery-image-container {
      position: relative;
      // width: 200px;
      // height: 200px;

      @media (max-width: 720px) {
        width: 100%;
        // height: auto;
      }

      .delete-image-container {
        display: block;
        position: absolute;
        top: 5px;
        right: 5px;
        border-radius: 100%;
        text-align: center;
        padding: 3px 8px;
        color: white;
        background-color: red;
        font-size: 16px;
        cursor: pointer;
      }

      .ant-image {
        width: 100%;
        height: 100%;

        @media (max-width: 720px) {
          // width: 100%;
          // height: 200px;
        }

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

.delete-image-modal {
  .modal-button-container {
    display: flex;
    gap: 20px;
    margin-top: 20px;
  }
}
