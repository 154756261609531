@import '../../../../../variables';
@import '../../../../../mixins';

.FAQ {
  .FAQ-Container {
    margin: 150px 0;

    @media (min-width: 725px) {
      width: 95%;
    }

    @include respond(phone) {
      width: 90%;
    }

    .Accordion-Content {
      max-width: 60%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      list-style-type: none;
      margin: 50px auto;

      @include respond(phone) {
        max-width: 100%;
        display: flex;
        justify-content: center;
        padding: 0 25px;
        margin-bottom: 80px;
      }

      @include respond(tablet) {
        width: 100%;
        max-width: 90%;
      }

      li {
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;
        justify-content: space-between;
        border-bottom: 1px solid #a7a7a7;
      }

      span {
        font-size: 35px;
        color: #9f0220;
        width: 30px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .Question-Span-Wrapper {
      display: flex;
      align-items: center;
      cursor: pointer;
      justify-content: space-between;
      width: 100%;

      @include respond(phone) {
        display: flex;
        align-items: flex-start;
      }

      span {
        @include respond(phone) {
          margin-top: 12px;
        }
      }
    }

    .Question {
      color: #9f0220;
      font-weight: 600;
      font-family: 'Playfair Display', serif;
      font-size: 20px;
      line-height: 24px;
    }

    .show.answer-expand {
      max-height: 250px;
      opacity: 1;
    }

    .answer-expand {
      padding: 0 45px 0 40px;
      color: #717171;
      overflow: hidden;
      max-height: 0;
      opacity: 0;
      display: flex;
      transition: max-height 0.6s ease-out;
    }

    .answer {
      overflow: scroll;
    }
  }
}

.ant-collapse-header {
  color: $faq-question-red !important;
  font-weight: 700;
  font-family: 'Raleway';
  font-size: 24px;
  line-height: 44px;
  border-bottom: 1px solid #a7a7a7;
}

.Question-Holder {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border: 1px solid black;
}

.FAQ-Question {
  margin: 0;
  padding: 0;
}

.Panel-Answer {
  font-family: 'Roboto';
  font-size: 16px;
  line-height: 150%;
  color: #717171;
  padding-left: 23px;
}
.ant-collapse-content {
}

.ant-collapse-content-active {
}
