@import 'src/assets/css/colors';
.my-profile {
  padding: 60px;
  min-height: calc(100vh - 329px - 80px);
  overflow: hidden;
  width: 100%;

  @media (max-width: 720px) {
    padding: 0px;
  }

  // .tab-progress-container {
  //   position: relative;
  //   display: grid;
  //   margin-bottom: 10px;
  //   grid-template-columns: auto auto auto auto auto;
  //   width: 46%;
  //   padding: 0 10px;

  //   .tab-progress-item {
  //     background-color: #d9d9d9;
  //     height: 3px;
  //     width: 100px;

  //     &.completed {
  //       background-color: #4bae4f;
  //     }
  //   }
  // }

  .my-profile-tabs {
    .gallery-tab-title {
      &::before {
        content: '';
        position: absolute;
        top: -11px;
        right: 0;
        border-bottom: 3px solid #d9d9d9;
        width: 90%;
      }

      &::after {
        content: '';
        position: absolute;
        left: 0;
        top: -19px;
        font-size: 12px;
        color: #d9d9d9;
      }

      &.tab1 {
        &::after {
          content: '1';
        }
      }
      &.tab2 {
        &::after {
          content: '2';
        }
      }
      &.tab3 {
        &::after {
          content: '3';
        }
      }
      &.tab4 {
        &::after {
          content: '4';
        }
      }
      &.tab5 {
        &::after {
          content: '5';
        }
      }

      &.completed {
        &::before {
          border-bottom: 3px solid #4bae4f;
        }
        &::after {
          color: #4bae4f;
        }
      }
    }
    .ant-tabs-nav {
      &:before {
        border-bottom: none;
      }
      .ant-tabs-nav-wrap {
        overflow: visible;

        .ant-tabs-nav-list {
          .ant-tabs-tab {
            background-color: #f3f6f8;
            border: none;
            border-radius: 9px;
            font-weight: 600;
            padding: 10px 30px;
            margin: 7px;

            // &::before {
            //   content: '';
            //   position: absolute;
            //   top: -11px;
            //   right: 0;
            //   border-bottom: 3px solid #d9d9d9;
            //   width: 80%;
            // }

            // &.ant-tabs-tab-active {
            //   &::before {
            //     border-bottom: 3px solid #4bae4f;
            //   }
            // }

            // &.completed {
            //   &::before {
            //     border-bottom: 3px solid #4bae4f;
            //   }
            // }

            &.ant-tabs-tab-active {
              background-color: $button-color;

              .ant-tabs-tab-btn {
                color: white;
              }
            }

            &:hover {
              .ant-tabs-tab-btn {
                color: $button-color;
              }
              &.ant-tabs-tab-active {
                .ant-tabs-tab-btn {
                  color: white;
                }
              }
            }
          }
        }
      }
    }
  }
}
.modal-image-cropper-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  .image-cropper-preview {
    .ant-upload {
      &.ant-upload-select {
        width: 200px;
        height: 200px;
        margin-bottom: 30px;
      }
    }
  }

  .Button {
    width: 50%;
  }
}

.modal-free-credits-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  img {
    margin: 30px;
  }
  .Button {
    width: 30%;
  }
}
