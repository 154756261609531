@import '../../../../../variables.scss';
@import '../../../../../mixins.scss';

.Terms-And-Conditions {
  .Terms-Body-Container {
    width: 60%;
    margin: auto;
    padding: 100px 0 50px 0;
    display: flex;
    flex-direction: column;

    @include respond(phone) {
      width: 85%;
    }

    @include respond(tablet) {
      width: 90%;
    }

    p,
    li {
      color: $pricing-page-text;
    }

    .Terms-Content {
      padding-top: 40px;

      .Terms-Intro {
        margin-bottom: 100px;

        @include respond(phone) {
          margin-bottom: 50px;
        }
        @include respond(tablet) {
          margin-bottom: 70px;
        }
      }

      .Authorisation-Of-Use {
        margin-bottom: 70px;
        @include respond(phone) {
          margin-bottom: 50px;
        }
      }

      .Prohibited-Uses {
        margin-bottom: 60px;
        @include respond(phone) {
          margin-bottom: 50px;
        }
      }

      ol {
        list-style-type: none;
        padding: 0;

        .Agreement-List {
          list-style-type: none;
          margin: 30px 0 0 0;
          padding: 0 0 70px 0;
          color: $pricing-page-text;

          @include respond(phone) {
            margin-top: 30px;
            padding-bottom: 20px;
          }

          p {
            margin: 0;
            padding: 0;
          }
        }
      }
      h4 {
        font-family: 'Playfair Display', serif;
        color: $terms-page-header-text;
        font-size: 25px;
      }
      span {
        font-size: 25px;
        color: $main-color;
        margin-right: 10px;
      }
    }
  }
}

.Bolded-Quotes {
  font-weight: 600;
  color: #1b1b1b;
}

#Bolded-HeaderLink {
  font-weight: 600;
  color: #1b1b1b;
}

.Push-Bot {
  margin-bottom: 15px;
}
