@import '../../../../../../variables.scss';
@import '../../../../../../mixins.scss';

.LanderContainer {
  display: flex;
  font-family: 'Raleway';
  justify-content: center;
  align-items: center;
  height: 100vh;
  position: relative;
  background-image: url('../../../../../../assets/images/Lander10/bg.png');

  @media (max-width: 1020px) and (orientation: 'landscape') {
    height: auto;
    padding: 2.5em;
  }
}

.BackgroundOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(49, 12, 14, 0.9) 60.89%, rgba(146, 29, 38, 0.9) 124.77%);
}

.LoadingOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background: linear-gradient(180deg, rgba(49, 12, 14, 0.9) 60.89%, rgba(146, 29, 38, 0.9) 124.77%);
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    animation: rotate 2s infinite linear;

    @include respond(phone) {
      height: 40px;
    }
  }
}

.LanderContent {
  z-index: 1;
  width: 613px;

  @include respond(phone) {
    width: 100%;
  }

  .ProgressBar {
    height: 5px;
    width: 613px;
    background: rgba(222, 190, 190, 0.4);
    border-radius: 22px;
    margin-bottom: 29px;
    position: relative;

    @include respond(phone) {
      width: 90%;
      margin-left: 4%;
      margin-right: 4%;
    }

    .Percentage {
      height: 100%;
      background: #9f0220;
      border-radius: 22px;

      span {
        position: absolute;
        bottom: 7px;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #ffffff;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
      }
    }
  }

  .LanderContentInner {
    box-sizing: border-box;
    background: #ffffff;
    border: 1px solid #ffeeee;
    box-shadow: 13px 13px 22px 8px rgba(53, 10, 10, 0.54);
    border-radius: 9px;
    padding: 59px 54px;

    @include respond(phone) {
      margin: auto 3%;
      padding: 40px 30px;
    }
  }
}

.InputsContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  width: 100%;
  margin-top: 30px;
}

.ButtonsContianer {
  display: flex;
  justify-content: space-between;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.ToggleElement {
  opacity: 0;
  animation: fade-in 0.1s ease-in-out forwards;
}

.Hidden {
  opacity: 0;
}
