@import '../../../../../../../../variables.scss';
@import '../../../../../../../../mixins.scss';

.LanderContent .LanderContentInner {
  background-image: none !important;
}

.LanderContainer14 {
  .GoogleContainer {
    max-width: 500px;

    .DividerText {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      text-align: center;
      color: #623333;
    }

    .RedGoogleLoginButton {
      background: #4285f4;
      color: #ffffff;
      border-radius: 58px;
      text-align: center;
      margin-bottom: 7px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: bold;
      font-size: 18px;
      letter-spacing: 0.8px;
      padding: 11px 15px;

      @include respond(phone) {
        padding-top: 11px;
        padding-bottom: 11px;
      }

      svg {
        width: 27px;
        height: 27px;
        margin-right: 20px;
      }

      &:hover {
        cursor: pointer;
        box-shadow: 0px 1px 14px rgba(255, 255, 255, 0.6);
      }

      .googleLoginHide {
        display: none;
      }
    }
  }
}
