@import '../../../../../../../../mixins.scss';

.LocationForm {
  display: flex;
  gap: 10px;

  @include respond(phone) {
    flex-direction: column;
  }

  .LocationField {
    display: flex;
    flex-direction: column;
    gap: 17px;

    @include respond(phone) {
      gap: 10px;
    }

    &:first-child {
      width: 30%;

      @include respond(phone) {
        width: 100%;
      }
    }

    &:last-child {
      width: 70%;

      @include respond(phone) {
        width: 100%;
      }
    }

    .InputContainer input {
      font-size: 16px;
      padding: 10px;
    }
  }
}
