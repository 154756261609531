@import '../../../../../variables';
@import '../../../../../mixins';

.AboutUs {
  .AboutUs-Container {
    margin-top: 100px;
    margin-bottom: 200px;

    @include respond(phone) {
      margin: 55px auto;
    }

    @include respond(tablet) {
      max-width: 90%;
      margin: 50px auto;
    }

    .AboutUs-Content {
      display: flex;
      align-items: center;
      width: 60%;
      margin: auto;

      @include respond(phone) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
      }

      @include respond(tablet) {
        width: 100%;
      }

      .ImagePlaceholder {
        width: 356px;
        height: 356px;
        overflow: hidden;
        display: flex;
        justify-content: flex-end;
        margin-right: 100px;
        border-radius: 100%;

        .ImageComponent {
          img {
            width: 100%;
          }
        }
        > div {
          width: 100%;
          height: 100%;

          @include respond(phone) {
            width: 85%;
            height: 400px;
          }
        }

        @include respond(phone) {
          padding: 0;
          display: flex;
          justify-content: center;
          margin-bottom: 30px;
          margin-right: 0;
        }

        @include respond(tablet) {
          padding: 0;
          margin-right: 50px;
        }
      }

      .Text-Content {
        width: 60%;
        font-family: 'Roboto';
        font-size: 16px;
        color: $pricing-page-text;
        line-height: 24px;

        @include respond(phone) {
          display: flex;
          flex-direction: column;
          width: 85%;
        }

        p {
          @include respond(phone) {
            padding: 0;
            width: 100%;
            margin: 10px auto;
          }
        }

        .br {
          @include respond(phone) {
            display: none;
          }
          @include respond(tablet) {
            display: none;
          }
        }

        .Support-Text {
          color: $main-color;
          cursor: pointer;
          text-decoration: none;
          font-weight: 600;

          @include respond(phone) {
            display: inline-block;
          }
        }

        .ImageComponent {
          align-items: baseline;
          margin-top: 80px;

          @include respond(phone) {
            align-items: center;
            margin-top: 40px;
          }
          img {
            width: 165px;
            @include respond(phone) {
              width: 110px;
            }
          }
        }
      }
    }
  }
}
