.SentMessageContainer {
  position: relative;

  .NotEnoughCredits {
    background: #ff7272;
    color: #fff;
    position: absolute;
    top: -25px;
    right: 31px;
    padding: 5px 10px;
    font-size: 13px;
    text-decoration: underline;
    border-radius: 5px;

    &:hover {
      cursor: pointer;
    }
  }

  .SentMessage {
    text-align: right;
    background: #d41411;
    margin: 10px 30px;
    margin-left: auto;
    border: 1px solid #e9edf0;
    border-radius: 8px 8px 0px 8px;
    padding: 20px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.04);
    padding-bottom: 5px;
    max-width: 250px;
    display: flex;
    justify-content: flex-end;
    font-family: 'Open Sans';

    @media screen and (min-width: 500px) and (max-width: 900px) {
      padding: 5px;
      max-width: 50%;
    }

    &.Error {
      border: 1px solid #ff7272;
      margin-top: 30px;
      background-color: #ffe6e6;

      .Message {
        color: #ff7272;

        .SentTime {
          color: #ff7272;
        }
      }
    }

    .Message {
      font-size: 16px;
      color: #fff;
      line-height: 24px;
      height: fit-content;

      @media screen and (min-width: 901px) and (max-width: 1024px) {
        font-size: 13px;
        line-height: 16px;
      }

      @media screen and (min-width: 500px) and (max-width: 900px) {
        font-size: 12px;
        line-height: 16px;
      }
    }
  }

  .SentTime {
    display: flex;
    justify-content: flex-end;
    color: #fff;
    font-size: 11px;

    @media screen and (min-width: 500px) and (max-width: 900px) {
      font-size: 8px;
    }
  }
}
