@import '../../../../../../../variables.scss';
@import '../../../../../../../mixins.scss';

.settings-wrapper {
  background: #ffffff;
  display: flex;
  flex-direction: column;
  margin: 30px;
  gap: 34px;
  @include respond(phone) {
    margin: 0;
  }
  @include respond(tablet) {
    margin: 0;
  }
}
.confirm-credit-card-ok {
  color: $white;
  background: $button-color;
  text-transform: uppercase;
  border-radius: 8px;
  cursor: pointer;
  font-style: normal;
  text-align: center;
  &:hover {
    background: $white !important;
    color: $button-color !important;
    border-color: $button-color !important;
  }
}
.form {
}

.form-input {
  display: flex;
  justify-content: space-evenly;
  padding: 0 120px;
  gap: 30px;
  flex-wrap: wrap;
  margin-bottom: 103px;
  @include respond(phone) {
    padding: 0;
    margin: 30px;
  }
  @include respond(tablet) {
    padding: 0;
    margin: 30px;
  }

  .column {
    flex: 1;
    display: flex;
    flex-direction: column;
    // min-width: 360px;
    flex-shrink: 0;
    gap: 25px;
    font-family: 'Open Sans', serif;

    .title {
      color: #253858;
      font-size: 20px;
      line-height: 24px;
      font-weight: 700;
      margin-bottom: 33px;
    }

    .input-wrapper {
      display: flex;
      flex-direction: column;
      gap: 7px;
      label {
        color: #253858;
        font-size: 16px;
        line-height: 24px;
        font-weight: 500;
      }
      .InputField {
        height: inherit;
      }
      input {
        height: 48px;
      }
    }
  }
  .image-box {
    width: 360px;
    height: auto;
    border-radius: 8px 8px 0 0;
    overflow: hidden;

    img {
      width: 100%;
    }
  }
  .text-box {
    width: 360px;
    padding: 25px;
    border: 2px dashed #d6d6d6;
    border-top: none;
    border-radius: 0 0 8px 8px;
    & p {
      color: #253858;
      font-size: 16px;
      line-height: 24px;
      font-family: 'Open Sans', serif;
    }
  }
}

.btn-box {
  text-align: center;
  @include respond(phone) {
    margin-bottom: 30px;
  }

  @include respond(tablet) {
    margin-bottom: 30px;
  }
}
.btn {
  background-color: $button-color;
  text-transform: uppercase;
  width: 360px;
  height: 56px;
  outline: none;
  border: none;
  border-radius: 8px;
  color: #ffffff;
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
  font-family: 'Gothic A1', sans-serif;
  cursor: pointer;
  &:hover {
    opacity: 0.9;
  }
  &:active {
    transform: scale(0.99);
  }
}
