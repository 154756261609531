@import '../../../../../../../../variables.scss';
@import '../../../../../../../../mixins.scss';

.LanderContainer17 {
  &.version17-1 {
    .UsernameImage {
      background-image: url('../../../../../../../../assets/images/Lander17/red/username.gif');
    }
  }

  &.version17-2 {
    .UsernameImage {
      background-image: url('../../../../../../../../assets/images/Lander17/purple/username.gif');
    }
  }

  &.version17-3 {
    .UsernameImage {
      height: 263px;
      width: 187px;
      background-image: url('../../../../../../../../assets/images/Lander17/blue/username.gif');
    }
  }

  &.version17-4 {
    .UsernameImage {
      background-image: url('../../../../../../../../assets/images/Lander17/orange/username.gif');
    }
  }

  .UsernameImage {
    height: 250px;
    width: 300px;
    background-size: cover;
    background-position: center;
    margin-bottom: 20px;
  }

  .UsernameContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5px;

    .UsernameText {
      color: #fff;
      text-align: center;
      font-size: 24px;
      margin-bottom: 40px;

      @include respond(phone) {
        font-size: 19px;
      }

      span {
        color: #fe1414;
      }
    }
  }
}
